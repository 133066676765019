
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { googleMapsApiKey } from "../common/constants"

const geocodeApi = createApi({
    reducerPath: 'geocodeApi',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://maps.googleapis.com/maps/api/geocode/json',
    }),
    endpoints: (builder) => ({
        getGeocode: builder.query<GetGeocodeApiResponse, GetGeocodeApiArg>({
            query: ({address}) => ({
                url: '',
                params: {
                    key: googleMapsApiKey,
                    address: address,
                }
            }),
        }),
        // https://maps.googleapis.com/maps/api/geocode/json?latlng=40.714224,-73.961452&key=YOUR_API_KEY
        reverseGeocode: builder.query<ReverseGetGeocodeApiResponse, ReverseGetGeocodeApiArg>({
            query: ({ latlng }) => ({
                url: '',
                params: {
                    key: googleMapsApiKey,
                    latlng: latlng,
                }
            }),
        }),
    }),
});

export type GetGeocodeApiResponse = {
    results: {
        address_components: {
            long_name: string;
            short_name: string;
            types: string[];
        }[];
        formatted_address: string;
        geometry: {
            bounds: {
                northeast: {
                    lat: number;
                    lng: number;
                };
                southwest: {
                    lat: number;
                    lng: number;
                };
            };
            location: {
                lat: number;
                lng: number;
            };
            location_type: string;
            viewport: {
                northeast: {
                    lat: number;
                    lng: number;
                };
                southwest: {
                    lat: number;
                    lng: number;
                };
            };
        };
        partial_match: boolean;
        place_id: string;
        types: string[];
    }[];
    status: string;
};

export type GetGeocodeApiArg = {
    /** Address */
    address: string;
};

export type ReverseGetGeocodeApiResponse = {
    results: {
        address_components: {
            long_name: string;
            short_name: string;
            types: string[];
        }[];
        formatted_address: string;
        geometry: {
            bounds: {
                northeast: {
                    lat: number;
                    lng: number;
                };
                southwest: {
                    lat: number;
                    lng: number;
                };
            };
            location: {
                lat: number;
                lng: number;
            };
            location_type: string;
            viewport: {
                northeast: {
                    lat: number;
                    lng: number;
                };
                southwest: {
                    lat: number;
                    lng: number;
                };
            };
        };
        partial_match: boolean;
        place_id: string;
        types: string[];
    }[];
    status: string;
};

export type ReverseGetGeocodeApiArg = {
    /** Latitude and longitude */
    latlng: string;
}

export const {
    useGetGeocodeQuery,
    useReverseGeocodeQuery,
} = geocodeApi;

export default geocodeApi;
