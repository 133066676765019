import React, { CSSProperties, useEffect } from "react";
import {Alert, Container, Snackbar} from "@mui/material";
import MainRouter from "./MainRouter";
import {useSelector} from "react-redux";
import store, {RootState} from "../store/store";
import {BackgroundState} from "../store/backgroundSlice";
import {defaultBackgroundColor} from "../common/constants"
import {setSnackbar, SnackbarState} from "../store/snackbarSlice";
import { useAuth } from "react-oidc-context"


const getBackgroundStyle = (background: BackgroundState): CSSProperties => {
    switch (background.value.type) {
        case "fill-image":
            return {
                position: "absolute",
                minWidth: "100vw",
                minHeight: background.value.height ?? "100vh",
                maxHeight: background.value.height ?? "100vh",
                backgroundImage: `url(${background.value.image})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
            }
        case "start-end-image":
            return {
                position: "absolute",
                inset: 0,
                minWidth: "100vw",
                minHeight: background.value.height,
                maxHeight: background.value.height,
                backgroundImage: `linear-gradient(rgba(5,24,40,0.77), rgba(5,24,40,0.77)), url(${background.value.image})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
            }
        case "fill-color":
            return {
                position: "absolute",
                minWidth: "100vw",
                minHeight: background.value.height ?? "100vh",
                maxHeight: background.value.height ?? "100vh",
                backgroundColor: background.value.color,
            }
    }
}

const getOverlayStyle = (background: BackgroundState): CSSProperties => {
    switch (background.overlay.type) {
        case "none":
            return {
                zIndex: 1,
                position: 'absolute',
                inset: 0,
                background: 'none'
            };
        case "start-end-linear-gradient":
            return {
                zIndex: 1,
                position: 'absolute',
                inset: 0,
                top: background.overlay.top,
                minHeight: background.overlay.height,
                maxHeight: background.overlay.height,
                background: `linear-gradient(${background.overlay.direction}, ${background.overlay.startColor}, ${background.overlay.endColor})`
            };
        case "fill-linear-gradient":
            return {
                zIndex: 1,
                position: 'absolute',
                inset: 0,
                background: `linear-gradient(${background.overlay.direction}, ${background.overlay.startColor}, ${background.overlay.endColor})`
            };
        case "fill-color":
            return {
                zIndex: 1,
                position: 'absolute',
                inset: 0,
                backgroundColor: background.overlay.color
            };
    }
}

const getBodyStyle = (background: BackgroundState): CSSProperties => {
    if (background.body) {
        return {
            minHeight: "100vh",
            backgroundColor: background.body.backgroundColor
        }
    }

    return {
        minHeight: "100vh",
        backgroundColor: defaultBackgroundColor
    }
}

const MainPage: React.FC = () => {
    const background: BackgroundState = useSelector((state: RootState) => state.background);
    const snackbar: SnackbarState = useSelector((state: RootState) => state.snackbar);
    const auth = useAuth();

    useEffect(() => {
        if(auth.isLoading || auth.isAuthenticated) {
            return
        }

        localStorage.removeItem('token')
    }, [auth])

    useEffect(() => {
        if(auth.isLoading || !auth.isAuthenticated || !auth.user?.access_token) {
            return
        }

        localStorage.setItem('token', auth.user.access_token)
    }, [auth])

    const backgroundStyle = getBackgroundStyle(background);
    const overlayStyle = getOverlayStyle(background);
    const bodyStyle = getBodyStyle(background);

    const containerStyle: CSSProperties = {
        position: "relative",
        zIndex: 2,
        minHeight: "100vh",
        paddingBottom: "1em"
    }

    const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        store.dispatch(setSnackbar({isOpen: false}));
    };

    return (
        <div style={bodyStyle}>
            <div style={backgroundStyle} />
            <div style={overlayStyle} />
            <Container style={containerStyle}>
                <MainRouter />
            </Container>
            <Snackbar
                open={snackbar.isOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{vertical: "bottom", horizontal: "center" }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default MainPage;
