import React, { useState } from "react"
import { HeaderBreadcrumbChoice, HeaderBreadcrumbRouteProps, LogoStyle } from "./headerTypes"
import { HeaderBreadcrumb } from "./headerStyles"
import { ExpandMoreOutlined } from "@mui/icons-material"
import { useNavigate } from "react-router-dom"
import { Menu, MenuItem } from "@mui/material"

export const HeaderBreadcrumbRoute: React.FC<HeaderBreadcrumbRouteProps> = ({
    path,
    label,
    icon,
    choices,
    navigatable = false
}) => {
    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = useState(null);

    return (
        <>
            <HeaderBreadcrumb
                key={path}
                label={label}
                icon={icon}
                deleteIcon={
                    choices && choices.length > 1
                        ? <ExpandMoreOutlined />
                        : undefined
                }
                onDelete={
                    choices && choices.length > 1
                        ? (e) => setAnchorEl(e.currentTarget)
                        : undefined
                }
                onClick={
                    navigatable
                        ? () => navigate(path)
                        : undefined
                }
            />
            {
                choices &&
                <Menu
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                    anchorEl={anchorEl}
                >
                    {
                        choices
                            ?.map(choice => (
                                <MenuItem
                                    key={choice.name}
                                    onClick={() => {
                                        navigate(choice.path)
                                        setAnchorEl(null)
                                    }}
                                >
                                    {choice.name}
                                </MenuItem>
                            ))
                    }
                </Menu>
            }
        </>
    )
}
