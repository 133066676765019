import React from "react"
import { EventAddress } from "../../../store/api"
import { useGetGeocodeQuery } from "../../../store/geocodeApi"
import { getAddressString } from "./eventUtils"
import { AdvancedMarker, Map, Pin } from "@vis.gl/react-google-maps"
import { googleMapsMapId } from "../../constants"

export const EventAddressMap: React.FC<{ address: EventAddress }> = ({ address }) => {
    const getGeocode = useGetGeocodeQuery({
        address: getAddressString(address)
    });

    const location = {
        address: getAddressString(address),
        lat: address.latitude ?? getGeocode.data?.results[0].geometry.location.lat ?? 0.0,
        lng: address.longitude ?? getGeocode.data?.results[0].geometry.location.lng ?? 0.0
    }

    return (
        <Map
            zoom={16}
            center={{lat: location.lat, lng: location.lng}}
            gestureHandling={'greedy'}
            disableDefaultUI={false}
            mapTypeControl={false}
            mapId={googleMapsMapId}
        >
            <AdvancedMarker position={{lat: location.lat, lng: location.lng}}>
                <Pin />
            </AdvancedMarker>
        </Map>
    )
}
