import { emptySplitApi as api } from "./emptyApi";
export const addTagTypes = [
  "User Module",
  "Event Module",
  "Event Host Module",
  "Attribute Module",
  "Storage Module",
  "Review Module",
  "Event Ticket Module",
  "Event By User Module",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getUser: build.query<GetUserApiResponse, GetUserApiArg>({
        query: (queryArg) => ({ url: `/user/${queryArg.id}` }),
        providesTags: ["User Module"],
      }),
      mutateUser: build.mutation<MutateUserApiResponse, MutateUserApiArg>({
        query: (queryArg) => ({
          url: `/user/${queryArg.id}`,
          method: "PUT",
          body: queryArg.mutateUserRequest,
        }),
        invalidatesTags: ["User Module"],
      }),
      getUserSelf: build.query<GetUserSelfApiResponse, GetUserSelfApiArg>({
        query: () => ({ url: `/user/self` }),
        providesTags: ["User Module"],
      }),
      mutateUserSelf: build.mutation<
        MutateUserSelfApiResponse,
        MutateUserSelfApiArg
      >({
        query: (queryArg) => ({
          url: `/user/self`,
          method: "PUT",
          body: queryArg.mutateUserSelfRequest,
        }),
        invalidatesTags: ["User Module"],
      }),
      getEvent: build.query<GetEventApiResponse, GetEventApiArg>({
        query: (queryArg) => ({ url: `/event/${queryArg.id}` }),
        providesTags: ["Event Module"],
      }),
      mutateEvent: build.mutation<MutateEventApiResponse, MutateEventApiArg>({
        query: (queryArg) => ({
          url: `/event/${queryArg.id}`,
          method: "PUT",
          body: queryArg.mutateEventRequest,
        }),
        invalidatesTags: ["Event Module"],
      }),
      getEventHost: build.query<GetEventHostApiResponse, GetEventHostApiArg>({
        query: (queryArg) => ({ url: `/event-host/${queryArg.id}` }),
        providesTags: ["Event Host Module"],
      }),
      mutateEventHost: build.mutation<
        MutateEventHostApiResponse,
        MutateEventHostApiArg
      >({
        query: (queryArg) => ({
          url: `/event-host/${queryArg.id}`,
          method: "PUT",
          body: queryArg.updateEventHostRequest,
        }),
        invalidatesTags: ["Event Host Module"],
      }),
      getAttributeGroups: build.query<
        GetAttributeGroupsApiResponse,
        GetAttributeGroupsApiArg
      >({
        query: () => ({ url: `/attribute/` }),
        providesTags: ["Attribute Module"],
      }),
      mutateAttributeGroup: build.mutation<
        MutateAttributeGroupApiResponse,
        MutateAttributeGroupApiArg
      >({
        query: (queryArg) => ({
          url: `/attribute/`,
          method: "PUT",
          body: queryArg.mutateAttributeGroupRequest,
        }),
        invalidatesTags: ["Attribute Module"],
      }),
      confirmPhone: build.mutation<ConfirmPhoneApiResponse, ConfirmPhoneApiArg>(
        {
          query: (queryArg) => ({
            url: `/user/self/phone/confirm`,
            method: "POST",
            body: queryArg.confirmPhoneRequest,
          }),
          invalidatesTags: ["User Module"],
        }
      ),
      saveImage: build.mutation<SaveImageApiResponse, SaveImageApiArg>({
        query: (queryArg) => ({
          url: `/storage/image`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["Storage Module"],
      }),
      getImage: build.query<GetImageApiResponse, GetImageApiArg>({
        query: (queryArg) => ({
          url: `/storage/image/${queryArg.id}`,
          params: {
            scale: queryArg.scale,
            maxWidth: queryArg.maxWidth,
            maxHeight: queryArg.maxHeight,
            quality: queryArg.quality,
          },
        }),
        providesTags: ["Storage Module"],
      }),
      saveImage1: build.mutation<SaveImage1ApiResponse, SaveImage1ApiArg>({
        query: (queryArg) => ({
          url: `/storage/image/${queryArg.id}`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["Storage Module"],
      }),
      getFile: build.query<GetFileApiResponse, GetFileApiArg>({
        query: (queryArg) => ({ url: `/storage/${queryArg.id}` }),
        providesTags: ["Storage Module"],
      }),
      uploadFile: build.mutation<UploadFileApiResponse, UploadFileApiArg>({
        query: (queryArg) => ({
          url: `/storage/${queryArg.id}`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["Storage Module"],
      }),
      uploadFile1: build.mutation<UploadFile1ApiResponse, UploadFile1ApiArg>({
        query: (queryArg) => ({
          url: `/storage/`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["Storage Module"],
      }),
      listReviews: build.query<ListReviewsApiResponse, ListReviewsApiArg>({
        query: (queryArg) => ({
          url: `/review/`,
          params: {
            page: queryArg.page,
            size: queryArg.size,
            userId: queryArg.userId,
            eventId: queryArg.eventId,
            eventHostId: queryArg.eventHostId,
            eventTicketPurchaseId: queryArg.eventTicketPurchaseId,
            rating: queryArg.rating,
            order: queryArg.order,
          },
        }),
        providesTags: ["Review Module"],
      }),
      createReview: build.mutation<CreateReviewApiResponse, CreateReviewApiArg>(
        {
          query: (queryArg) => ({
            url: `/review/`,
            method: "POST",
            body: queryArg.createReviewRequest,
          }),
          invalidatesTags: ["Review Module"],
        }
      ),
      cancelEvent: build.mutation<CancelEventApiResponse, CancelEventApiArg>({
        query: (queryArg) => ({
          url: `/event/${queryArg.id}/cancel`,
          method: "POST",
          body: queryArg.cancelEventRequest,
        }),
        invalidatesTags: ["Event Module"],
      }),
      listEvents: build.query<ListEventsApiResponse, ListEventsApiArg>({
        query: (queryArg) => ({
          url: `/event/`,
          params: {
            page: queryArg.page,
            size: queryArg.size,
            eventHostId: queryArg.eventHostId,
            name: queryArg.name,
            attributeId: queryArg.attributeId,
            status: queryArg.status,
            startDate: queryArg.startDate,
            endDate: queryArg.endDate,
            order: queryArg.order,
          },
        }),
        providesTags: ["Event Module"],
      }),
      createEvent: build.mutation<CreateEventApiResponse, CreateEventApiArg>({
        query: (queryArg) => ({
          url: `/event/`,
          method: "POST",
          body: queryArg.createEventRequest,
        }),
        invalidatesTags: ["Event Module"],
      }),
      validateEventTicketPurchase: build.mutation<
        ValidateEventTicketPurchaseApiResponse,
        ValidateEventTicketPurchaseApiArg
      >({
        query: (queryArg) => ({
          url: `/event-ticket/${queryArg.id}/validate`,
          method: "POST",
          body: queryArg.validateEventTicketPurchaseRequest,
        }),
        invalidatesTags: ["Event Ticket Module"],
      }),
      initiatePaymentSuccessEventTicketPurchaseRefund: build.mutation<
        InitiatePaymentSuccessEventTicketPurchaseRefundApiResponse,
        InitiatePaymentSuccessEventTicketPurchaseRefundApiArg
      >({
        query: (queryArg) => ({
          url: `/event-ticket/${queryArg.id}/refund`,
          method: "POST",
        }),
        invalidatesTags: ["Event Ticket Module"],
      }),
      listEventTicketPurchases: build.query<
        ListEventTicketPurchasesApiResponse,
        ListEventTicketPurchasesApiArg
      >({
        query: (queryArg) => ({
          url: `/event-ticket/`,
          params: {
            userId: queryArg.userId,
            eventId: queryArg.eventId,
            statuses: queryArg.statuses,
          },
        }),
        providesTags: ["Event Ticket Module"],
      }),
      initiateEventTicketPurchase: build.mutation<
        InitiateEventTicketPurchaseApiResponse,
        InitiateEventTicketPurchaseApiArg
      >({
        query: (queryArg) => ({
          url: `/event-ticket/`,
          method: "POST",
          body: queryArg.initiateEventTicketPurchaseRequest,
        }),
        invalidatesTags: ["Event Ticket Module"],
      }),
      rejectEventHost: build.mutation<
        RejectEventHostApiResponse,
        RejectEventHostApiArg
      >({
        query: (queryArg) => ({
          url: `/event-host/${queryArg.id}/reject`,
          method: "POST",
        }),
        invalidatesTags: ["Event Host Module"],
      }),
      confirmEventHost: build.mutation<
        ConfirmEventHostApiResponse,
        ConfirmEventHostApiArg
      >({
        query: (queryArg) => ({
          url: `/event-host/${queryArg.id}/confirm`,
          method: "POST",
          body: queryArg.confirmEventHostRequest,
        }),
        invalidatesTags: ["Event Host Module"],
      }),
      createEventHostRequest: build.mutation<
        CreateEventHostRequestApiResponse,
        CreateEventHostRequestApiArg
      >({
        query: (queryArg) => ({
          url: `/event-host/request`,
          method: "POST",
          body: queryArg.createEventHostRequest,
        }),
        invalidatesTags: ["Event Host Module"],
      }),
      listUpcomingEventsByUser: build.query<
        ListUpcomingEventsByUserApiResponse,
        ListUpcomingEventsByUserApiArg
      >({
        query: () => ({ url: `/user/self/events/upcoming` }),
        providesTags: ["Event By User Module"],
      }),
      listPastEventsByUser: build.query<
        ListPastEventsByUserApiResponse,
        ListPastEventsByUserApiArg
      >({
        query: () => ({ url: `/user/self/events/past` }),
        providesTags: ["Event By User Module"],
      }),
      listUsers: build.query<ListUsersApiResponse, ListUsersApiArg>({
        query: (queryArg) => ({
          url: `/user/`,
          params: { page: queryArg.page, size: queryArg.size },
        }),
        providesTags: ["User Module"],
      }),
      getFileMeta: build.query<GetFileMetaApiResponse, GetFileMetaApiArg>({
        query: (queryArg) => ({ url: `/storage/${queryArg.id}/meta` }),
        providesTags: ["Storage Module"],
      }),
      getRating: build.query<GetRatingApiResponse, GetRatingApiArg>({
        query: (queryArg) => ({ url: `/review/${queryArg.eventHostId}` }),
        providesTags: ["Review Module"],
      }),
      getEventTicketPurchase: build.query<
        GetEventTicketPurchaseApiResponse,
        GetEventTicketPurchaseApiArg
      >({
        query: (queryArg) => ({ url: `/event-ticket/${queryArg.id}` }),
        providesTags: ["Event Ticket Module"],
      }),
      getEventHostSelf: build.query<
        GetEventHostSelfApiResponse,
        GetEventHostSelfApiArg
      >({
        query: () => ({ url: `/event-host/self` }),
        providesTags: ["Event Host Module"],
      }),
      listEventHosts: build.query<
        ListEventHostsApiResponse,
        ListEventHostsApiArg
      >({
        query: (queryArg) => ({
          url: `/event-host/`,
          params: {
            page: queryArg.page,
            size: queryArg.size,
            name: queryArg.name,
            status: queryArg.status,
            placeAttributeId: queryArg.placeAttributeId,
          },
        }),
        providesTags: ["Event Host Module"],
      }),
      getAttributeGroupById: build.query<
        GetAttributeGroupByIdApiResponse,
        GetAttributeGroupByIdApiArg
      >({
        query: (queryArg) => ({ url: `/attribute/${queryArg.id}` }),
        providesTags: ["Attribute Module"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as api };
export type GetUserApiResponse =
  /** status 200 Success */ GetUserResponseSuccess;
export type GetUserApiArg = {
  /** User ID */
  id: string;
};
export type MutateUserApiResponse =
  /** status 200 Success */ MutateUserResponseSuccess;
export type MutateUserApiArg = {
  /** User ID */
  id: string;
  /** Mutate user request */
  mutateUserRequest: MutateUserRequest;
};
export type GetUserSelfApiResponse =
  /** status 200 Success */ GetUserResponseSuccess;
export type GetUserSelfApiArg = void;
export type MutateUserSelfApiResponse =
  /** status 200 Success */ MutateUserResponseSuccess;
export type MutateUserSelfApiArg = {
  /** Mutate user self request */
  mutateUserSelfRequest: MutateUserSelfRequest;
};
export type GetEventApiResponse =
  /** status 200 Success */ GetEventResponseSuccess;
export type GetEventApiArg = {
  /** Event id */
  id: string;
};
export type MutateEventApiResponse =
  /** status 200 Success */ MutateEventResponseSuccess;
export type MutateEventApiArg = {
  /** Event id */
  id: string;
  /** Mutate event request */
  mutateEventRequest: MutateEventRequest;
};
export type GetEventHostApiResponse =
  /** status 200 Success */ GetEventHostResponseSuccess;
export type GetEventHostApiArg = {
  /** Event host ID */
  id: string;
};
export type MutateEventHostApiResponse =
  /** status 200 Success */ UpdateEventHostResponseSuccess;
export type MutateEventHostApiArg = {
  /** Event host ID */
  id: string;
  /** Update event host request */
  updateEventHostRequest: UpdateEventHostRequest;
};
export type GetAttributeGroupsApiResponse =
  /** status 200 Success */ GetAttributeGroupResponseSuccess;
export type GetAttributeGroupsApiArg = void;
export type MutateAttributeGroupApiResponse =
  /** status 200 Success */ MutateAttributeGroupResponseSuccess;
export type MutateAttributeGroupApiArg = {
  /** Attribute group */
  mutateAttributeGroupRequest: MutateAttributeGroupRequest;
};
export type ConfirmPhoneApiResponse =
  /** status 200 Success */ ConfirmPhoneResponseSuccess;
export type ConfirmPhoneApiArg = {
  /** Confirm phone request */
  confirmPhoneRequest: ConfirmPhoneRequest;
};
export type SaveImageApiResponse =
  /** status 200 Success */ GetImageMetaResponseSuccess;
export type SaveImageApiArg = {
  body: {
    file: Blob;
  };
};
export type GetImageApiResponse = unknown;
export type GetImageApiArg = {
  id: string;
  scale?: number;
  maxWidth?: number;
  maxHeight?: number;
  quality?: number;
};
export type SaveImage1ApiResponse =
  /** status 200 Success */ GetImageMetaResponseSuccess;
export type SaveImage1ApiArg = {
  id: string;
  body: {
    file: Blob;
  };
};
export type GetFileApiResponse = unknown;
export type GetFileApiArg = {
  id: string;
};
export type UploadFileApiResponse =
  /** status 200 Success */ UploadFileResponseSuccess;
export type UploadFileApiArg = {
  /** Id */
  id: string;
  body: {
    file: Blob;
  };
};
export type UploadFile1ApiResponse =
  /** status 200 Success */ UploadFileResponseSuccess;
export type UploadFile1ApiArg = {
  body: {
    file: Blob;
  };
};
export type ListReviewsApiResponse =
  /** status 200 List reviews */ ListReviewsResponseSuccess;
export type ListReviewsApiArg = {
  /** Page */
  page?: number;
  /** Size */
  size?: number;
  /** User ID */
  userId?: string;
  /** Event ID */
  eventId?: string;
  /** Event host ID */
  eventHostId?: string;
  /** Event ticket purchase ID */
  eventTicketPurchaseId?: string;
  /** Rating. 1-5. Querying one will return all reviews above that rating. */
  rating?: number;
  /** Order, comma separated list. Format: FIELD:DIRECTION, e.g. CREATED_DATE:ASC. Available fields: CREATED_DATE, RATING. Available directions: ASC, DESC */
  order?: string[];
};
export type CreateReviewApiResponse =
  /** status 200 Create review */ CreateReviewResponseSuccess;
export type CreateReviewApiArg = {
  /** Create review request */
  createReviewRequest: CreateReviewRequest;
};
export type CancelEventApiResponse =
  /** status 200 Event canceled */ CancelEventResponseSuccess;
export type CancelEventApiArg = {
  /** Event id */
  id: string;
  /** Cancel event request */
  cancelEventRequest: CancelEventRequest;
};
export type ListEventsApiResponse =
  /** status 200 Success */ ListEventsResponseSuccess;
export type ListEventsApiArg = {
  /** Page number */
  page?: number;
  /** Page size */
  size?: number;
  /** Event host id */
  eventHostId?: string;
  /** Event name */
  name?: string;
  /** Attribute ids, comma separated list */
  attributeId?: string[];
  /** Status */
  status?: "CREATED" | "CANCELED" | "IN_PROGRESS" | "FINISHED";
  /** Start date */
  startDate?: string;
  /** End date */
  endDate?: string;
  /** Order, comma separated list. Format: FIELD:DIRECTION, e.g. NAME:ASC. Available fields: NAME, CREATED_DATE, START_DATE, END_DATE. Available directions: ASC, DESC */
  order?: string[];
};
export type CreateEventApiResponse =
  /** status 200 Event created */ CreateEventResponseSuccess;
export type CreateEventApiArg = {
  /** Create event request */
  createEventRequest: CreateEventRequest;
};
export type ValidateEventTicketPurchaseApiResponse =
  /** status 200 Success */ ValidateEventTicketPurchaseResponseSuccess;
export type ValidateEventTicketPurchaseApiArg = {
  id: string;
  /** Validate event ticket purchase request */
  validateEventTicketPurchaseRequest: ValidateEventTicketPurchaseRequest;
};
export type InitiatePaymentSuccessEventTicketPurchaseRefundApiResponse =
  /** status 200 Success */ InitiatePaymentSuccessEventTicketPurchaseRefundResponseSuccess;
export type InitiatePaymentSuccessEventTicketPurchaseRefundApiArg = {
  id: string;
};
export type ListEventTicketPurchasesApiResponse =
  /** status 200 Success */ ListEventTicketPurchasesResponseSuccess;
export type ListEventTicketPurchasesApiArg = {
  /** User ID */
  userId?: string;
  /** Event ID */
  eventId?: string;
  /** Statuses */
  statuses?: (
    | "PAYMENT_PENDING"
    | "PAYMENT_FAILED"
    | "PAYMENT_SUCCESS"
    | "REFUND_PENDING"
    | "REFUND_FAILED"
    | "REFUND_SUCCESS"
    | "VALIDATED"
  )[];
};
export type InitiateEventTicketPurchaseApiResponse =
  /** status 200 Success */ InitiateEventTicketPurchaseResponseSuccess;
export type InitiateEventTicketPurchaseApiArg = {
  /** Initiate event ticket purchase request */
  initiateEventTicketPurchaseRequest: InitiateEventTicketPurchaseRequest;
};
export type RejectEventHostApiResponse =
  /** status 200 Success */ RejectEventHostResponseSuccess;
export type RejectEventHostApiArg = {
  /** Event host ID */
  id: string;
};
export type ConfirmEventHostApiResponse =
  /** status 200 Success */ ConfirmEventHostResponseSuccess;
export type ConfirmEventHostApiArg = {
  /** Event host ID */
  id: string;
  /** Confirm event host request */
  confirmEventHostRequest: ConfirmEventHostRequest;
};
export type CreateEventHostRequestApiResponse =
  /** status 200 Success */ CreateEventHostRequestSuccess;
export type CreateEventHostRequestApiArg = {
  /** Create event host request */
  createEventHostRequest: CreateEventHostRequest;
};
export type ListUpcomingEventsByUserApiResponse =
  /** status 200 Success */ ListUpcomingEventsByUserResponseSuccess;
export type ListUpcomingEventsByUserApiArg = void;
export type ListPastEventsByUserApiResponse =
  /** status 200 Success */ ListPastEventsByUserResponseSuccess;
export type ListPastEventsByUserApiArg = void;
export type ListUsersApiResponse =
  /** status 200 Success */ ListUsersResponseSuccess;
export type ListUsersApiArg = {
  /** Page */
  page?: number;
  /** Size */
  size?: number;
};
export type GetFileMetaApiResponse =
  /** status 200 Success */ GetFileMetaResponseSuccess;
export type GetFileMetaApiArg = {
  /** Id */
  id: string;
};
export type GetRatingApiResponse =
  /** status 200 Get rating */ GetRatingResponseSuccess;
export type GetRatingApiArg = {
  /** Event host ID */
  eventHostId: string;
};
export type GetEventTicketPurchaseApiResponse =
  /** status 200 Success */ GetEventTicketPurchaseResponseSuccess;
export type GetEventTicketPurchaseApiArg = {
  /** Event ticket purchase ID */
  id: string;
};
export type GetEventHostSelfApiResponse =
  /** status 200 Success */ GetEventSelfHostResponseSuccess;
export type GetEventHostSelfApiArg = void;
export type ListEventHostsApiResponse =
  /** status 200 Success */ ListEventHostsResponseSuccess;
export type ListEventHostsApiArg = {
  /** Page */
  page?: number;
  /** Size */
  size?: number;
  /** Name */
  name?: string;
  /** Status */
  status?: "PENDING_APPROVAL" | "APPROVED" | "REJECTED";
  /** Place attribute ID */
  placeAttributeId?: string[];
};
export type GetAttributeGroupByIdApiResponse =
  /** status 200 Success */ GetAttributeGroupByIdResponseSuccess;
export type GetAttributeGroupByIdApiArg = {
  /** Attribute group id */
  id: string;
};
export type UserPhone = {
  countryCode: string;
  number: string;
  verified: boolean;
  verificationCode?: string;
};
export type StorageObjectMeta = {
  bucket: string;
  id: string;
  size: number;
  contentType?: string;
  createdBy?: string;
};
export type User = {
  id?: string;
  version?: number;
  createdDate?: string;
  firstName: string;
  lastName: string;
  email: string;
  phone?: UserPhone;
  avatar?: StorageObjectMeta;
  tags?: {
    [key: string]: object;
  };
};
export type GetUserResponseSuccess = {
  data: User;
  count?: number;
};
export type GetUserResponseFailureUserNotFound = {
  message: string;
  code: string;
};
export type MutateUserResponseSuccess = {
  data: User;
  count?: number;
};
export type MutateUserResponseFailureEmailAlreadyExists = {
  message: string;
  code: string;
};
export type MutateUserResponseFailurePhoneAlreadyExists = {
  message: string;
  code: string;
};
export type MutateUserResponseFailureUserNotFound = {
  message: string;
  code: string;
};
export type MutateUserPhoneRequest = {
  countryCode: string;
  number: string;
};
export type MutateUserRequest = {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: MutateUserPhoneRequest;
  avatar?: StorageObjectMeta;
};
export type MutateUserSelfRequest = {
  phone?: MutateUserPhoneRequest;
  avatar?: StorageObjectMeta;
};
export type EventAddress = {
  country: string;
  city: string;
  street: string;
  house: string;
  postalCode: string;
  apartment?: string;
  latitude?: number;
  longitude?: number;
};
export type EventTicket = {
  type: string;
  additionalInformation?: string;
  price: number;
  currencyCode: string;
};
export type Attribute = {
  id?: string;
  tags: {
    [key: string]: object;
  };
  name: string;
  order: number;
};
export type Event = {
  id?: string;
  version?: number;
  createdDate?: string;
  eventHostId: string;
  status: "CREATED" | "CANCELED" | "IN_PROGRESS" | "FINISHED";
  name: string;
  startDate: string;
  endDate: string;
  description: string;
  address: EventAddress;
  totalTicketCount: number;
  tickets: EventTicket[];
  coverPicture?: StorageObjectMeta;
  attributes: Attribute[];
  cancellationReason?: string;
  tags?: {
    [key: string]: object;
  };
};
export type GetEventResponseSuccess = {
  data: Event;
  count?: number;
};
export type GetEventResponseFailureEventNotFound = {
  message: string;
  code: string;
};
export type MutateEventResponseSuccess = {
  data: Event;
  count?: number;
};
export type MutateEventResponseFailureAttributeNotFound = {
  attributeIds: string[];
  code: string;
  message: string;
};
export type MutateEventResponseFailureEventAlreadyCanceled = {
  message: string;
  code: string;
};
export type MutateEventResponseFailureEventAlreadyFinished = {
  message: string;
  code: string;
};
export type MutateEventResponseFailureEventAlreadyInProgress = {
  message: string;
  code: string;
};
export type MutateEventResponseFailureEventHostHasEventAtSameTime = {
  message: string;
  code: string;
};
export type MutateEventResponseFailureEventNotFound = {
  message: string;
  code: string;
};
export type MutateEventResponseFailureInvalidTickets = {
  message: string;
  code: string;
};
export type MutateEventResponseFailureInvalidTotalTicketCount = {
  message: string;
  code: string;
};
export type MutateEventResponseFailureInvalidDate = {
  message: string;
  code: string;
};
export type MutateEventRequest = {
  name?: string;
  description?: string;
  startDate?: string;
  endDate?: string;
  totalTicketCount?: number;
  tickets?: EventTicket[];
  address?: EventAddress;
  coverPicture?: StorageObjectMeta;
  attributeIds?: string[];
};
export type EventHostAddress = {
  country: string;
  city: string;
  street: string;
  house: string;
  postalCode: string;
  apartment?: string;
  latitude?: number;
  longitude?: number;
};
export type EventHostPhone = {
  countryCode: string;
  number: string;
};
export type EventHostLink = {
  title: string;
  value: string;
};
export type EventHostContact = {
  phone?: EventHostPhone;
  email?: string;
  contactPersonFirstName?: string;
  contactPersonLastName?: string;
  links: EventHostLink[];
};
export type EventHostCredentials = {
  password?: string;
};
export type EventHost = {
  id?: string;
  version?: number;
  createdDate?: string;
  userId?: string;
  status: "PENDING_APPROVAL" | "APPROVED" | "REJECTED";
  name: string;
  description: string;
  coverPicture: StorageObjectMeta;
  address: EventHostAddress;
  contact: EventHostContact;
  credentials: EventHostCredentials;
  placeAttributes: Attribute[];
  tags?: {
    [key: string]: object;
  };
};
export type GetEventHostResponseSuccess = {
  data: EventHost;
  count?: number;
};
export type GetEventHostResponseFailureEventHostNotFound = {
  message: string;
  code: string;
};
export type UpdateEventHostResponseSuccess = {
  data: EventHost;
  count?: number;
};
export type UpdateEventHostResponseFailureAttributeNotFound = {
  attributeIds: string[];
  code: string;
  message: string;
};
export type UpdateEventHostResponseFailureEventHostNotFound = {
  message: string;
  code: string;
};
export type UpdateEventHostRequest = {
  name?: string;
  description?: string;
  coverPicture?: StorageObjectMeta;
  address?: EventHostAddress;
  links?: EventHostLink[];
  placeAttributeIds?: string[];
};
export type AttributeGroup = {
  id?: string;
  version?: number;
  createdDate?: string;
  tags: {
    [key: string]: object;
  };
  name: string;
  attributes: Attribute[];
};
export type GetAttributeGroupResponseSuccess = {
  data: AttributeGroup[];
  count?: number;
};
export type MutateAttributeGroupResponseSuccess = {
  data: AttributeGroup;
  count?: number;
};
export type MutateAttributeGroupResponseFailureUnableToCreateAttributeGroupWithPartialData =
  {
    message: string;
    code: string;
  };
export type MutateAttributeGroupRequest = {
  id?: string;
  name?: string;
  description?: string;
  attributes?: Attribute[];
  tags?: {
    [key: string]: object;
  };
};
export type ConfirmPhoneResponseSuccess = {
  data: User;
  count?: number;
};
export type ConfirmPhoneResponseFailureWrongVerificationCode = {
  message: string;
  code: string;
};
export type ConfirmPhoneResponseFailurePhoneAlreadyConfirmed = {
  message: string;
  code: string;
};
export type ConfirmPhoneResponseFailureUserPhoneNotSet = {
  message: string;
  code: string;
};
export type ConfirmPhoneResponseFailureUserNotFound = {
  message: string;
  code: string;
};
export type ConfirmPhoneRequest = {
  code: string;
};
export type GetImageMetaResponseSuccess = {
  data: StorageObjectMeta;
  count?: number;
};
export type GetImageMetaResponseFailureFileNotFound = {
  message: string;
  code: string;
};
export type GetImageMetaResponseFailureObjectIsNotImage = {
  message: string;
  code: string;
};
export type GetImageMetaResponseFailure = {
  code: string;
  message: string;
};
export type UploadFileResponseSuccess = {
  data: StorageObjectMeta;
  count?: number;
};
export type UploadFileResponseFailure = {
  code: string;
  message: string;
  exception?: {
    cause?: {
      stackTrace?: {
        classLoaderName?: string;
        moduleName?: string;
        moduleVersion?: string;
        methodName?: string;
        fileName?: string;
        lineNumber?: number;
        className?: string;
        nativeMethod?: boolean;
      }[];
      message?: string;
      localizedMessage?: string;
    };
    stackTrace?: {
      classLoaderName?: string;
      moduleName?: string;
      moduleVersion?: string;
      methodName?: string;
      fileName?: string;
      lineNumber?: number;
      className?: string;
      nativeMethod?: boolean;
    }[];
    message?: string;
    suppressed?: {
      stackTrace?: {
        classLoaderName?: string;
        moduleName?: string;
        moduleVersion?: string;
        methodName?: string;
        fileName?: string;
        lineNumber?: number;
        className?: string;
        nativeMethod?: boolean;
      }[];
      message?: string;
      localizedMessage?: string;
    }[];
    localizedMessage?: string;
  };
};
export type Review = {
  id?: string;
  version?: number;
  createdDate?: string;
  userId: string;
  eventId: string;
  eventHostId: string;
  rating: number;
  comment: string;
  tags?: {
    [key: string]: object;
  };
};
export type ListReviewsResponseSuccess = {
  data: Review[];
  count: number;
};
export type CreateReviewResponseSuccess = {
  data: Review;
  count?: number;
};
export type CreateReviewResponseFailureEventNotFound = {
  message: string;
  code: string;
};
export type CreateReviewResponseFailureEventTicketPurchaseNotFoundOrNotValidated =
  {
    message: string;
    code: string;
  };
export type CreateReviewResponseFailureReviewForEventAlreadyExists = {
  message: string;
  code: string;
};
export type CreateReviewResponseFailureUserNotFound = {
  message: string;
  code: string;
};
export type CreateReviewRequest = {
  eventId: string;
  rating: number;
  comment: string;
};
export type CancelEventResponseSuccess = {
  data: Event;
  count?: number;
};
export type CancelEventResponseFailureEventAlreadyCanceled = {
  message: string;
  code: string;
};
export type CancelEventResponseFailureEventAlreadyFinished = {
  message: string;
  code: string;
};
export type CancelEventResponseFailureEventAlreadyInProgress = {
  message: string;
  code: string;
};
export type CancelEventResponseFailureEventNotFound = {
  message: string;
  code: string;
};
export type CancelEventRequest = {
  reason: string;
};
export type ListEventsResponseSuccess = {
  data: Event[];
  count: number;
};
export type CreateEventResponseSuccess = {
  data: Event;
  count?: number;
};
export type CreateEventResponseFailureAttributeNotFound = {
  attributeIds: string[];
  code: string;
  message: string;
};
export type CreateEventResponseFailureEventHostHasEventAtSameTime = {
  message: string;
  code: string;
};
export type CreateEventResponseFailureEventHostNotFound = {
  message: string;
  code: string;
};
export type CreateEventResponseFailureInvalidDate = {
  message: string;
  code: string;
};
export type CreateEventResponseFailureInvalidTickets = {
  message: string;
  code: string;
};
export type CreateEventResponseFailureInvalidTotalTicketCount = {
  message: string;
  code: string;
};
export type CreateEventResponseFailureUserNotFound = {
  message: string;
  code: string;
};
export type CreateEventResponseFailure = {
  code: string;
  message: string;
};
export type CreateEventRequest = {
  name: string;
  description: string;
  startDate: string;
  endDate: string;
  totalTicketCount: number;
  tickets: EventTicket[];
  address: EventAddress;
  coverPicture?: StorageObjectMeta;
  attributeIds: string[];
};
export type EventTicketPurchaseEntry = {
  type: string;
  count: number;
};
export type EventTicketPurchaseBillingAddress = {
  country: string;
  city: string;
  street: string;
  house: string;
  postalCode: string;
  apartment?: string;
  latitude?: number;
  longitude?: number;
};
export type EventTicketPurchase = {
  id?: string;
  version?: number;
  createdDate?: string;
  userId: string;
  eventId: string;
  status:
    | "PAYMENT_PENDING"
    | "PAYMENT_FAILED"
    | "PAYMENT_SUCCESS"
    | "REFUND_PENDING"
    | "REFUND_FAILED"
    | "REFUND_SUCCESS"
    | "VALIDATED";
  tickets: EventTicketPurchaseEntry[];
  billingAddress?: EventTicketPurchaseBillingAddress;
  tags?: {
    [key: string]: object;
  };
};
export type ValidateEventTicketPurchaseResponseSuccess = {
  data: EventTicketPurchase;
  count?: number;
};
export type ValidateEventTicketPurchaseResponseFailureEventNotFound = {
  message: string;
  code: string;
};
export type ValidateEventTicketPurchaseResponseFailureTicketNotFound = {
  message: string;
  code: string;
};
export type ValidateEventTicketPurchaseResponseFailureTicketNotInPaymentSuccessOrValidatedStatus =
  {
    message: string;
    code: string;
  };
export type ValidateEventTicketPurchaseResponseFailureUserNotFound = {
  message: string;
  code: string;
};
export type ValidateEventTicketPurchaseRequest = {
  userId: string;
  eventId: string;
  id: string;
};
export type InitiatePaymentSuccessEventTicketPurchaseRefundResponseSuccess = {
  data: EventTicketPurchase;
  count?: number;
};
export type InitiatePaymentSuccessEventTicketPurchaseRefundResponseFailureTicketNotFound =
  {
    message: string;
    code: string;
  };
export type InitiatePaymentSuccessEventTicketPurchaseRefundResponseFailureTicketNotInPaymentSuccessStatus =
  {
    message: string;
    code: string;
  };
export type ListEventTicketPurchasesResponseSuccess = {
  data: EventTicketPurchase[];
  count?: number;
};
export type InitiateEventTicketPurchaseResponseSuccess = {
  data: EventTicketPurchase;
  count?: number;
};
export type InitiateEventTicketPurchaseResponseFailureEventNotFound = {
  message: string;
  code: string;
};
export type InitiateEventTicketPurchaseResponseFailureEventTicketNotFound = {
  message: string;
  code: string;
};
export type InitiateEventTicketPurchaseResponseFailureUserNotFound = {
  message: string;
  code: string;
};
export type InitiateEventTicketPurchaseResponseFailureEventTicketPurchaseAlreadyExists =
  {
    message: string;
    code: string;
  };
export type InitiateEventTicketPurchaseRequest = {
  eventId: string;
  tickets: EventTicketPurchaseEntry[];
  billingAddress?: EventTicketPurchaseBillingAddress;
};
export type RejectEventHostResponseSuccess = {
  data: EventHost;
  count?: number;
};
export type RejectEventHostResponseFailureEventHostNotPending = {
  message: string;
  code: string;
};
export type RejectEventHostResponseFailureEventHostNotFound = {
  message: string;
  code: string;
};
export type ConfirmEventHostResponseSuccess = {
  data: EventHost;
  count?: number;
};
export type ConfirmEventHostResponseFailureEventHostNotPending = {
  message: string;
  code: string;
};
export type ConfirmEventHostResponseFailureEventHostNotFound = {
  message: string;
  code: string;
};
export type ConfirmEventHostResponseFailureImpossibleToCreateOrFindUser = {
  message: string;
  code: string;
};
export type ConfirmEventHostRequest = {
  userId: string;
};
export type CreateEventHostRequestSuccess = {
  data: EventHost;
  count?: number;
};
export type CreateEventHostRequestFailureEmailAlreadyExists = {
  message: string;
  code: string;
};
export type CreateEventHostRequestFailurePhoneAlreadyExists = {
  message: string;
  code: string;
};
export type CreateEventHostRequestFailurePlaceAttributeNotFound = {
  attributeIds: string[];
  code: string;
  message: string;
};
export type CreateEventHostRequestFailureRequiredFieldMissing = {
  missingFields: string[];
  code: string;
  message: string;
};
export type CreateEventHostRequestFailure = {
  code: string;
  message: string;
};
export type CreateEventHostRequest = {
  name: string;
  description: string;
  coverPicture: StorageObjectMeta;
  address: EventHostAddress;
  contact: EventHostContact;
  credentials: EventHostCredentials;
  placeAttributeIds: string[];
};
export type EventWithEventTicket = {
  event: Event;
  ticket: EventTicketPurchase;
};
export type ListUpcomingEventsByUserResponseSuccess = {
  data: EventWithEventTicket[];
  count?: number;
};
export type ListPastEventsByUserResponseSuccess = {
  data: EventWithEventTicket[];
  count?: number;
};
export type ListUsersResponseSuccess = {
  data: User[];
  count?: number;
};
export type GetFileMetaResponseSuccess = {
  data: StorageObjectMeta;
  count?: number;
};
export type GetFileMetaResponseFailureFileNotFound = {
  message: string;
  code: string;
};
export type ReviewCalculation = {
  id?: string;
  version?: number;
  createdDate?: string;
  lastUpdatedDate: string;
  eventHostId: string;
  rating: number;
  tags?: {
    [key: string]: object;
  };
};
export type GetRatingResponseSuccess = {
  data: ReviewCalculation;
  count?: number;
};
export type GetEventTicketPurchaseResponseSuccess = {
  data: EventTicketPurchase;
  count?: number;
};
export type GetEventTicketPurchaseResponseFailureNotFound = {
  message: string;
  code: string;
};
export type GetEventSelfHostResponseSuccess = {
  data: EventHost;
  count?: number;
};
export type GetEventSelfHostResponseFailureEventHostNotFound = {
  message: string;
  code: string;
};
export type ListEventHostsResponseSuccess = {
  data: EventHost[];
  count: number;
};
export type GetAttributeGroupByIdResponseSuccess = {
  data: AttributeGroup;
  count?: number;
};
export type GetAttributeGroupByIdResponseFailureAttributeGroupNotFound = {
  message: string;
  code: string;
};
export const {
  useGetUserQuery,
  useMutateUserMutation,
  useGetUserSelfQuery,
  useMutateUserSelfMutation,
  useGetEventQuery,
  useMutateEventMutation,
  useGetEventHostQuery,
  useMutateEventHostMutation,
  useGetAttributeGroupsQuery,
  useMutateAttributeGroupMutation,
  useConfirmPhoneMutation,
  useSaveImageMutation,
  useGetImageQuery,
  useSaveImage1Mutation,
  useGetFileQuery,
  useUploadFileMutation,
  useUploadFile1Mutation,
  useListReviewsQuery,
  useCreateReviewMutation,
  useCancelEventMutation,
  useListEventsQuery,
  useCreateEventMutation,
  useValidateEventTicketPurchaseMutation,
  useInitiatePaymentSuccessEventTicketPurchaseRefundMutation,
  useListEventTicketPurchasesQuery,
  useInitiateEventTicketPurchaseMutation,
  useRejectEventHostMutation,
  useConfirmEventHostMutation,
  useCreateEventHostRequestMutation,
  useListUpcomingEventsByUserQuery,
  useListPastEventsByUserQuery,
  useListUsersQuery,
  useGetFileMetaQuery,
  useGetRatingQuery,
  useGetEventTicketPurchaseQuery,
  useGetEventHostSelfQuery,
  useListEventHostsQuery,
  useGetAttributeGroupByIdQuery,
} = injectedRtkApi;
