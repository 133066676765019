import React from "react"
import { EventHost } from "../../../store/api"
import { Card, Stack, Typography } from "@mui/material"
import { LocationOnOutlined } from "@mui/icons-material"
import { getAddressString } from "../event/eventUtils"
import { EventAddressMap } from "../event/EventAddressMap"

export const HostAddressCard: React.FC<{ eventHost: EventHost }> = ({ eventHost }) => {
    return (
        <Card variant="outlined">
            <Stack padding={4}>
                <Typography variant="h5" fontWeight={700}>Address</Typography>
                <Stack direction={"row"} spacing={1} marginTop={1}>
                    <LocationOnOutlined fontSize="small" />
                    <Typography variant="body2" component="span">{getAddressString(eventHost.address)}</Typography>
                </Stack>
                <Stack marginTop={1}>
                    <div style={{display: "block", height: "300px", width: "100%"}}>
                        <EventAddressMap address={eventHost.address} />
                    </div>
                </Stack>
            </Stack>
        </Card>
    )
}
