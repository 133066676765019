import { RequestField } from "./formTypes"
import React from "react"
import { FormDateTimePicker } from "./formStyles"
import { FormFieldError } from "./FormFieldError"
import dayjs from "dayjs"

export const FormDateTimePickerWithValidation = (
    props: {
        field: RequestField<Date | null>,
        onChange: (value: Date) => void,

    }
) => {
    return (
        <>
            <FormDateTimePicker
                value={dayjs(props.field.value)}
                onChange={(value) => props.onChange(value as Date)}
            />
            {
                props.field.validation.status === 'invalid'
                    ? FormFieldError({ message: props.field.validation.message })
                    : FormFieldError({})
            }
        </>
    )
}
