import { useDefaultBackground } from "../../../common/hooks/backgroundHooks"
import { useParams } from "react-router-dom"
import React from "react"
import {
    useGetEventQuery,
    useGetEventTicketPurchaseQuery,
    useGetUserSelfQuery,
    useInitiatePaymentSuccessEventTicketPurchaseRefundMutation
} from "../../../store/api"
import { skipToken } from "@reduxjs/toolkit/query"
import { LoadingBackdrop } from "../../../common/components/LoadingBackdrop"
import { ErrorBackdrop } from "../../../common/components/ErrorBackdrop"
import Header from "../../../common/components/header/Header"
import { Checkbox, CircularProgress, FormControlLabel, Grid, Typography } from "@mui/material"
import LabeledFormSection from "../../../common/components/form/LabeledFormSection"
import { EventWithTicket } from "../../../common/components/event/EventWithTicket"
import { ConfirmButton } from "../../../host/hostStyles"
import { defaultBackgroundColor } from "../../../common/constants"

export const EventCancel: React.FC  = () => {
    useDefaultBackground()
    const { eventTicketId } = useParams()

    const getEventTicket = useGetEventTicketPurchaseQuery(
        eventTicketId
            ? { id: eventTicketId }
            : skipToken
    )

    const getEvent = useGetEventQuery(
        getEventTicket.data?.data?.eventId
            ? { id: getEventTicket.data?.data?.eventId }
            : skipToken
    )

    const getUserSelf = useGetUserSelfQuery()

    const [refundTicketMutation, refundTicketMutationStatus] = useInitiatePaymentSuccessEventTicketPurchaseRefundMutation()

    const [termsAndConditions, setTermsAndConditions] = React.useState(false);

    const ticket = getEventTicket.data?.data
    const event = getEvent.data?.data
    const user = getUserSelf.data?.data

    return (
        <>
            <LoadingBackdrop
                open={
                    getEventTicket.isLoading
                    || getEvent.isLoading
                    || getUserSelf.isLoading
                    || refundTicketMutationStatus.isLoading
                }
            />

            <ErrorBackdrop
                errors={[
                    getEventTicket.error,
                    getEvent.error,
                    getUserSelf.error
                ]}
            />

            <Header logoStyle={"dark"} hasSearch={false} hasAvatar={false} />

            {
                ticket && event && user &&
                <Grid container spacing={3} marginTop={4}>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={8}>
                        <LabeledFormSection
                            title={"Cancel Event Ticket"}
                            child={
                                <EventWithTicket
                                    blobBackgroundColor={defaultBackgroundColor}
                                    user={user}
                                    eventWithTicket={{
                                        event,
                                        ticket
                                    }}
                                />
                            }
                        />

                        <LabeledFormSection
                            title={"Cancellation Policy"}
                            child={
                                <FormControlLabel
                                    required
                                    label={"I do want to cancel this ticket"}
                                    control={<Checkbox
                                        onChange={(e) => setTermsAndConditions(e.target.checked)}
                                        disabled={refundTicketMutationStatus.isLoading}
                                    />}
                                />
                            }
                        />

                        <ConfirmButton
                            variant="contained"
                            fullWidth
                            disabled={
                                refundTicketMutationStatus.isLoading
                                || refundTicketMutationStatus.isError
                                || refundTicketMutationStatus.isSuccess
                                || !termsAndConditions
                            }
                            onClick={() => {
                                refundTicketMutation({
                                    id: ticket.id!
                                })
                                    .unwrap()
                                    .then(() => {
                                        getEventTicket.refetch()
                                        console.log('success')
                                    })
                                    .catch(error => {
                                        console.log('error')
                                    })
                            }}
                        >
                            {
                                !(
                                    refundTicketMutationStatus.isLoading
                                    || refundTicketMutationStatus.isError
                                    || refundTicketMutationStatus.isSuccess
                                )
                                    ? 'Cancel and refund event ticket'
                                    : <></>
                            }
                            {
                                refundTicketMutationStatus.isLoading
                                    ? <CircularProgress color="inherit" size={24} />
                                    : null
                            }
                            {
                                refundTicketMutationStatus.isSuccess
                                    ? 'Event ticket cancelled and refunded'
                                    : null
                            }
                            {
                                refundTicketMutationStatus.isError
                                    ? 'Error cancelling and refunding event ticket'
                                    : null
                            }
                        </ConfirmButton>
                        {
                            refundTicketMutationStatus.isError
                                ? <LabeledFormSection
                                    title={"Error Details"}
                                    child={
                                        <Typography variant={"subtitle1"} color={"error"}>
                                            {JSON.stringify(refundTicketMutationStatus.error)}
                                        </Typography>
                                    }
                                />
                                : null
                        }
                    </Grid>
                    <Grid item xs={2}></Grid>
                </Grid>
            }
        </>
    )
}
