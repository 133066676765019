import React, {useEffect} from "react";
import {Grid, Stack, Typography} from "@mui/material";
import store from "../store/store"
import {setBackground} from "../store/backgroundSlice"
import {useGetEventHostSelfQuery, useListEventsQuery} from "../store/api"
import {LoadingBackdrop} from "../common/components/LoadingBackdrop"
import {ErrorBackdrop} from "../common/components/ErrorBackdrop"
import Header from "../common/components/header/Header"
import {skipToken} from "@reduxjs/toolkit/query"
import {HostEventsCard} from "../common/components/host/HostEventsCard"
import {HostDescriptionCard} from "../common/components/host/HostDescriptionCard"
import {HostAddressCard} from "../common/components/host/HostAddressCard"
import {AttributesCard} from "../common/components/attribute/AttributesCard"
import {HostContact} from "../common/components/host/HostContact"
import { useGetImageQuery } from "../store/fileApi"
import { HostEventReviewListCard } from "../common/components/host/HostEventReviewListCard"

const HostHome: React.FC = () => {
    const getEventHostSelf = useGetEventHostSelfQuery();

    const coverImage = useGetImageQuery(
        getEventHostSelf.data?.data?.coverPicture
            ? {
                id: getEventHostSelf.data.data.coverPicture.id,
                quality: 0.8,
                maxWidth: 1500
            }
            : skipToken
    )

    useEffect(() => {
        store.dispatch(
            setBackground({
                value: coverImage.data
                    ? {
                        type: "start-end-image",
                        image: coverImage.data,
                        height: "75vh"
                    }
                    : {
                        type: "fill-color",
                        color: "rgba(5,24,40,0.77)",
                        height: "75vh"
                    },
                overlay: {
                    type: "start-end-linear-gradient",
                    direction: "to bottom",
                    startColor: "rgba(245, 245, 245, 0)",
                    endColor: "rgba(245, 245, 245, 1)",
                    top: "30vh",
                    height: "45.5vh"
                }
            })
        );
    }, [coverImage]);

    const titleStyle = {
        color: "white",
        fontFamily: "Gilroy",
        fontSize: "7em",
        fontWeight: 800,
        lineHeight: "0.90em",
        padding: "0.2em 0"
    }

    const eventHost = getEventHostSelf.data?.data;
    return (
        <>
            <LoadingBackdrop open={getEventHostSelf.isLoading} />
            <ErrorBackdrop errors={[getEventHostSelf.error]}/>

            <Stack>
                <Header logoStyle={"light"} hasSearch={false} hasAvatar={true} />
                {
                    eventHost && (
                        <>
                            <Typography marginTop={8} style={titleStyle}>{eventHost.name}</Typography>
                            <HostDescriptionCard eventHost={eventHost} />
                            <div style={{height: "2em"}} />
                            <HostEventsCard
                                title={"Events in progress"}
                                showCreateButton={false}
                                eventCardMode={"hostPromote"}
                                status={"IN_PROGRESS"}
                                eventHost={eventHost}
                                pagination={true}
                            />
                            <div style={{height: "2em"}} />
                            <HostEventsCard
                                title={"Upcoming events"}
                                showCreateButton={true}
                                eventCardMode={"hostEdit"}
                                status={"CREATED"}
                                eventHost={eventHost}
                                pagination={true}
                            />
                            <div style={{height: "2em"}} />
                            <HostEventsCard
                                title={"Past events"}
                                showCreateButton={false}
                                eventCardMode={"hostView"}
                                status={"FINISHED"}
                                eventHost={eventHost}
                                pagination={true}
                            />
                            <div style={{height: "2em"}} />
                            <HostEventsCard
                                title={"Canceled events"}
                                showCreateButton={false}
                                eventCardMode={"hostView"}
                                status={"CANCELED"}
                                eventHost={eventHost}
                                pagination={true}
                            />
                            <div style={{height: "2em"}} />
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <HostAddressCard
                                        eventHost={eventHost}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <AttributesCard
                                        attributes={eventHost.placeAttributes ?? []}
                                    />
                                    <div style={{height: "2em"}} />
                                    <HostContact
                                        eventHost={eventHost}
                                    />
                                </Grid>
                            </Grid>
                            <div style={{height: "2em"}} />
                            <HostEventReviewListCard
                                eventHost={eventHost}
                                pagination
                            />
                        </>
                    )
                }
            </Stack>
        </>
    );
};

export default HostHome;
