import { CardContent, Grid, Skeleton, Typography } from "@mui/material"
import { ErrorOutline, ImageSearchOutlined } from "@mui/icons-material"
import React from "react"
import { CoverPictureActionArea, CoverPictureArea } from "./formStyles"
import { RequestFieldCoverPicture } from "./formTypes"
import { useGetImageQuery } from "../../../store/fileApi"
import { skipToken } from "@reduxjs/toolkit/query"

export const CoverPicture: React.FC<{
    uploadFile: (file: { id: string, file: File }) => void,
    coverPictureState: RequestFieldCoverPicture
}> = ({
    uploadFile,
    coverPictureState
}) => {
    const coverPicture = useGetImageQuery(
        coverPictureState.status === 'valid'
            ? {
                id: coverPictureState.value.id,
                quality: 0.8,
                maxHeight: 500
            }
            : skipToken
    )

    return (
        <CoverPictureArea variant="outlined">
            <CoverPictureActionArea
                onClick={() => {
                    const fileInput = document.createElement('input');
                    fileInput.type = 'file';
                    fileInput.accept = 'image/*';
                    fileInput.onchange = (event) => {
                        const file = (event.target as HTMLInputElement).files?.[0];
                        const nowMillis = Number(new Date());

                        if (!file) {
                            return;
                        }

                        uploadFile({
                            id: `cover-picture-${nowMillis}`,
                            file: file
                        });
                    }

                    fileInput.click();
                }}
            >
                <CardContent style={{ padding: 0 }}>
                    {
                        coverPictureState.status === 'initial' &&
                        <Grid container justifyContent="center" alignItems="center" style={{height: "100%"}}>
                            <ImageSearchOutlined fontSize={"large"} />
                        </Grid>
                    }
                    {
                        coverPictureState.status === 'valid' &&
                        coverPicture.data &&
                        <img src={coverPicture.data} alt={coverPictureState.value.id} style={{ width: '100%', height: '100%' }} />
                    }
                    {
                        !coverPicture.data &&
                        <Skeleton variant="rectangular" width={"100%"} height={"100%"} />
                    }
                    {
                        coverPictureState.status === 'invalid' &&
                        <Grid container spacing={1} alignItems="center" justifyContent="center">
                            <Grid item>
                                <ErrorOutline />
                            </Grid>
                            <Grid item>
                                <Typography variant={"subtitle2"} fontWeight={500}>Error uploading cover picture</Typography>
                            </Grid>
                        </Grid>
                    }
                </CardContent>
            </CoverPictureActionArea>
        </CoverPictureArea>
    )
}
