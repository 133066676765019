import { Grid, Typography } from "@mui/material"
import { ErrorOutline } from "@mui/icons-material"
import React from "react"


export const FormFieldError = (props: { message?: string }) => {
    return (
        props.message
            ? <Grid item container xs={12} alignSelf={"center"} marginTop={1}>
                <ErrorOutline fontSize={"small"} color={"error"} />
                <Typography variant={"subtitle2"} fontWeight={500} color={"error"} marginLeft={1}>{props.message}</Typography>
            </Grid>
            : <Grid item xs={12} marginTop={1}>
                <Typography variant={"subtitle2"} fontWeight={500} >&nbsp;</Typography>
            </Grid>
    )
}
