import {Button, Paper, styled, Tooltip, Typography} from "@mui/material";

// color: "white",
//         fontFamily: "Gilroy",
//         fontSize: "7em",
//         fontWeight: 800,
//         padding: "0.2em 0"
export const EventDetailTitle = styled(Typography)`
    color: white;
    font-size: 7em;
    font-weight: 800;
    padding: 0.2em 0;
    line-height: 0.90em;
    font-family: Gilroy;
`

export const EventDetailPaper = styled(Paper)`
    padding: 1rem;
`;

export const EventDetailPaperTitle = styled(Typography)`
    color: black;
    font-weight: 600;
    font-size: 1.2em;
`;

export const CheckoutButton = styled(Button)`
    border-radius: 8px;
    border: 2px solid #A0B7CD;
    background: linear-gradient(92deg, #C9D6FF 0%, #E2E2E2 100.4%);
    width: 50%;
    align-self: flex-end;
    color: black;
    font-size: 1.2em;
    font-weight: 600;
    text-transform: none;
    padding: 1em 0;
`

export const EventCheckoutSectionTitle = styled(Typography)`
    font-size: 1.2em;
    font-weight: 700;
    margin-top: 2em;
    margin-bottom: 30px;
`

export const EventCheckoutSectionError = styled(Typography)`
  color: red;
  margin-top: -30px;
  min-height: 30px;
  max-height: 30px;
  font-size: 15px;
`

export const EventCheckoutTicketPaper = styled(Paper)`
    padding: 1em;
`

export const EventCheckoutTicketTooltip = styled(Tooltip)`
    font-size: 0.8em;
`

export const EventCheckoutTicketText = styled(Typography)`
    font-size: 1em;
    font-weight: 600;
`

export const EventCheckoutTicketButton = styled(Button)`
    min-width: 30px;
    max-width: 30px;
    min-height: 30px;
    max-height: 30px;
    border-radius: var(--Medium, 4px);
    background: linear-gradient(180deg, #C9D6FF 0%, #E2E2E2 100%);
`

export const EventCheckoutBillingInputLabel = styled(Typography)`
    font-size: 1em;
    font-weight: 600;
`

export const PurchaseButton = styled(Button)`
  border-radius: 8px;
  border: 2px solid #A0B7CD;
  margin-top: 2em;
  margin-bottom: 2em;
  background: linear-gradient(92deg, #C9D6FF 0%, #E2E2E2 100.4%);
  width: 100%;
  color: black;
  font-size: 1.2em;
  font-weight: 600;
  text-transform: none;
  padding: 1em 0;
`
