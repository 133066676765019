import { useDefaultBackground } from "../../../common/hooks/backgroundHooks"
import { useParams } from "react-router-dom"
import {
    useCreateReviewMutation,
    useGetEventQuery,
    useGetEventTicketPurchaseQuery,
    useGetUserSelfQuery
} from "../../../store/api"
import { skipToken } from "@reduxjs/toolkit/query"
import { LoadingBackdrop } from "../../../common/components/LoadingBackdrop"
import { ErrorBackdrop } from "../../../common/components/ErrorBackdrop"
import React from "react"
import Header from "../../../common/components/header/Header"
import { CircularProgress, Grid, Rating, Typography } from "@mui/material"
import { EventWithTicket } from "../../../common/components/event/EventWithTicket"
import LabeledFormSection from "../../../common/components/form/LabeledFormSection"
import { defaultBackgroundColor } from "../../../common/constants"
import { FormTextField } from "../../../common/components/form/formStyles"
import { ConfirmButton } from "../../../host/hostStyles"
import { FormTextFieldWithValidation } from "../../../common/components/form/FormTextFieldWithValidation"
import { RequestField } from "../../../common/components/form/formTypes"

export const EventReview: React.FC = () => {
    useDefaultBackground()
    const { eventTicketId } = useParams()

    const getEventTicket = useGetEventTicketPurchaseQuery(
        eventTicketId
            ? { id: eventTicketId }
            : skipToken
    )

    const getEvent = useGetEventQuery(
        getEventTicket.data?.data?.eventId
            ? { id: getEventTicket.data?.data?.eventId }
            : skipToken
    )

    const getUserSelf = useGetUserSelfQuery()

    const [createReviewMutation, createReviewMutationStatus] = useCreateReviewMutation()

    const [rating, setRating] = React.useState(0)
    const [review, setReview] = React.useState<RequestField<string>>({
        value: '',
        validation: {
            status: "initial"
        }
    })

    const isReviewValid = review.validation.status === "valid"
        && rating > 0

    const ticket = getEventTicket.data?.data
    const event = getEvent.data?.data
    const user = getUserSelf.data?.data

    return (
        <>
            <LoadingBackdrop
                open={
                    getEventTicket.isLoading
                    || getEvent.isLoading
                    || getUserSelf.isLoading
                    || createReviewMutationStatus.isLoading
                }
            />

            <ErrorBackdrop
                errors={[
                    getEventTicket.error,
                    getEvent.error,
                    getUserSelf.error
                ]}
            />

            <Header logoStyle={"dark"} hasSearch={false} hasAvatar={false} />

            {
                ticket && event && user &&
                <Grid container spacing={3} marginTop={4}>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={8}>
                        <LabeledFormSection
                            title={"Event"}
                            child={
                                <EventWithTicket
                                    blobBackgroundColor={defaultBackgroundColor}
                                    user={user}
                                    eventWithTicket={{
                                        event,
                                        ticket
                                    }}
                                />
                            }
                        />
                        <LabeledFormSection
                            title={"Rate Event"}
                            child={
                                <Grid container marginTop={1}>
                                    <Rating
                                        size="large"
                                        value={rating}
                                        onChange={(e, value) => value && setRating(value)}
                                    />
                                </Grid>
                            }
                        />
                        <LabeledFormSection
                            title={"Write Review"}
                            child={
                                <FormTextFieldWithValidation
                                    multiline
                                    rows={4}
                                    placeholder={"Write a review here..."}
                                    field={review}
                                    onChange={(e) => setReview({
                                        value: e.target.value,
                                        validation: e.target.value.length > 0
                                            ? {
                                                status: "valid"
                                            }
                                            : {
                                                status: "invalid",
                                                message: "Review cannot be empty"
                                            }
                                    })}
                                />
                            }
                        />
                        <ConfirmButton
                            variant="contained"
                            fullWidth
                            disabled={
                                !isReviewValid
                                || createReviewMutationStatus.isLoading
                                || createReviewMutationStatus.isError
                                || createReviewMutationStatus.isSuccess
                            }
                            onClick={() => {
                                createReviewMutation({
                                    createReviewRequest: {
                                        eventId: event.id!,
                                        rating: rating,
                                        comment: review.value
                                    }
                                })
                                    .unwrap()
                                    .then(() => {
                                        console.log('success')
                                    })
                                    .catch(error => {
                                        console.log('error')
                                    })
                            }}
                        >
                            {
                                !(
                                    createReviewMutationStatus.isLoading
                                    || createReviewMutationStatus.isError
                                    || createReviewMutationStatus.isSuccess
                                )
                                    ? 'Submit Review'
                                    : <></>
                            }
                            {
                                createReviewMutationStatus.isLoading
                                    ? <CircularProgress color="inherit" size={24} />
                                    : null
                            }
                            {
                                createReviewMutationStatus.isSuccess
                                    ? 'Review submitted'
                                    : null
                            }
                            {
                                createReviewMutationStatus.isError
                                    ? 'Error submitting review'
                                    : null
                            }
                        </ConfirmButton>
                        {
                            createReviewMutationStatus.isError
                                ? <LabeledFormSection
                                    title={"Error Details"}
                                    child={
                                        <Typography variant={"subtitle1"} color={"error"}>
                                            {JSON.stringify(createReviewMutationStatus.error)}
                                        </Typography>
                                    }
                                />
                                : null
                        }
                    </Grid>
                    <Grid item xs={2}></Grid>
                </Grid>
            }
        </>
    )
}
