import React from "react"
import { Chip, Typography } from "@mui/material"
import { PersonOutlined } from "@mui/icons-material"

export const PersonChip: React.FC<{ firstName: string | undefined, lastName: string | undefined }> = ({ firstName, lastName }) => {
    return (
        <Chip
            key={`person-${firstName}-${lastName}`}
            size={"medium"}
            style={{marginRight: "0.5em", marginBottom: "0.5em"}}
            icon={<PersonOutlined fontSize={"small"} />}
            label={
                <Typography
                    variant="body2"
                    textTransform={"capitalize"}
                    fontWeight={600}
                    component="span"
                >
                    {`${firstName ?? "No name provided"} ${lastName ?? ""}`}
                </Typography>
            }
        />
    )
}
