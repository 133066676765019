import React from "react"
import { EventHostPhone } from "../../../store/api"
import { Chip, Typography } from "@mui/material"
import { PhoneOutlined } from "@mui/icons-material"
import { Link } from "react-router-dom"
import { getPhoneLink, getPhoneString } from "../host/hostUtils"

export const PhoneChip: React.FC<{ phone: EventHostPhone | undefined }> = ({ phone }) => {
    return (
        <Chip
            key={`phone-${phone}`}
            size={"medium"}
            style={{marginRight: "0.5em", marginBottom: "0.5em"}}
            icon={<PhoneOutlined fontSize={"small"} />}
            label={
                <Link
                    style={{textDecoration: "none", color: "inherit"}}
                    to={
                        phone
                            ? getPhoneLink(phone)
                            : "#"
                    }
                >
                    <Typography
                        variant="body2"
                        textTransform={"capitalize"}
                        fontWeight={600}
                        component="span"
                    >
                        {`${
                            phone
                                ? getPhoneString(phone)
                                : "No phone number provided"
                        }`}
                    </Typography>
                </Link>
            }
        />
    )
}
