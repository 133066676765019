import React from "react";
import { Attribute, Event, EventHost, useGetEventHostQuery } from "../../../store/api";
import { useGetImageQuery } from "../../../store/fileApi"

type EventDataFetcherProps = {
    event: Event,
    loadingChild?: React.JSX.Element,
    errorChild?: React.JSX.Element,
    child: React.FC<{event: Event, eventHost: EventHost, image: string, attributes?: Attribute[]}>
    waitForImage?: boolean
}

const EventDataFetcher: React.FC<EventDataFetcherProps> = ({
    event,
    loadingChild,
    errorChild,
    child,
    waitForImage
}) => {
    const eventHostFetcher = useGetEventHostQuery({id: event.eventHostId});

    const imageId = event.coverPicture ? event.coverPicture.id : "undefined"
    const imageFetcher = useGetImageQuery({
        id: imageId,
        quality: 0.5,
        maxWidth: 500
    })

    if (eventHostFetcher.isLoading || (waitForImage && imageFetcher.isLoading)) {
        return loadingChild ?? <div>Loading placeholder (EventDataFetcher)...</div>
    }

    if (eventHostFetcher.error || !eventHostFetcher.data) {
        console.error(eventHostFetcher.error)
        return errorChild ?? <div>Error placeholder (EventDataFetcher)</div>
    }

    if (imageFetcher.error) {
        console.error(imageFetcher.error)
        return errorChild ?? <div>Error placeholder (EventDataFetcher)</div>
    }

    const host: EventHost = eventHostFetcher.data.data
    const image = imageFetcher.data ? imageFetcher.data : ""

    return child({event, eventHost: host, image: image})
}

export default EventDataFetcher;
