
export const makeUrl = (baseUrl: string, ...url: string[]) => {
    if (baseUrl.endsWith('/')) {
        baseUrl = baseUrl.slice(0, -1);
    }

    url = url
        .map((item) => {
            if (item.startsWith('/')) {
                item = item.slice(1);
            }
            if (item.endsWith('/')) {
                item = item.slice(0, -1);
            }
            return item;
        });

    return `${baseUrl}/${url.join('/')}`;
}
