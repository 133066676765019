import {EventTicket, EventTicketPurchaseEntry} from "../../../store/api";
import React, {SetStateAction, useEffect, useState} from "react";
import {Stack} from "@mui/material";
import {
    EventCheckoutTicketButton,
    EventCheckoutTicketPaper,
    EventCheckoutTicketText,
    EventCheckoutTicketTooltip
} from "../eventStyles";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {round} from "lodash";

export interface TicketsCheckoutState {
    tickets: EventTicketPurchaseEntry[],
    price?: string,
    error?: string
}

interface TicketCountsState {
    [key: string]: number
}

const createInitialTicketCounts = (tickets: EventTicket[]) => {
    const initialCounts: TicketCountsState = {};
    tickets.forEach(ticket => {
        initialCounts[ticket.type] = 0;
    });
    return initialCounts;
};

const getTicketPrice = (price: number, currencyCode: string) => {
    return `${price.toFixed(2)} ${currencyCode}`
}

const calculateTotalCount = (ticketCounts: TicketCountsState) => {
    return Object.values(ticketCounts).reduce((total, count) => total + count, 0);
}

const calculateTotalPrice = (ticketCounts: TicketCountsState, tickets: EventTicket[]) => {
    const price = Object.keys(ticketCounts).reduce((total, ticketType) => {
        const ticket = tickets.find(ticket => ticket.type === ticketType)
        if (!ticket)
            return 0
        return total + ticket.price * ticketCounts[ticketType]
    }, 0);
    return round(price, 2)
}

const CheckoutTicketsSection: React.FC<{
    tickets: EventTicket[],
    totalTickets: number,
    setTicketCheckoutState: React.Dispatch<SetStateAction<TicketsCheckoutState>>
    disabled: boolean
}> = (
    {
        tickets,
        totalTickets,
        setTicketCheckoutState,
        disabled
    }
) => {

    const [ticketCounts, setTicketCounts] = useState<TicketCountsState>(createInitialTicketCounts(tickets));

    useEffect(() => {
        const ticketPurchaseEntries = Object.keys(ticketCounts).map(key => ({
            type: key,
            count: ticketCounts[key]
        })).filter(ticket => ticket.count > 0)
        const totalPrice = calculateTotalPrice(ticketCounts, tickets)
        const currencyCode = tickets[0].currencyCode
        setTicketCheckoutState({
            tickets: ticketPurchaseEntries,
            error: calculateTotalCount(ticketCounts) > 0 ? undefined : 'choose a ticket!',
            price: totalPrice > 0 ? getTicketPrice(totalPrice, currencyCode) : undefined
        })
    }, [ticketCounts, tickets, setTicketCheckoutState]);

    const handleIncrement = (ticketType: string) => {
        if (calculateTotalCount(ticketCounts) === totalTickets)
            return
        setTicketCounts(prevCounts => ({
            ...prevCounts,
            [ticketType]: prevCounts[ticketType] + 1
        }));
    };

    const handleDecrement = (ticketType: string) => {
        setTicketCounts(prevCounts => ({
            ...prevCounts,
            [ticketType]: Math.max(0, prevCounts[ticketType] - 1)
        }));
    };

    return (
        <>
            <Stack spacing={3}>
                {tickets.map((ticket) => (
                    <EventCheckoutTicketPaper key={ticket.type}>
                        <Stack direction={"row"} style={{alignItems: "center", justifyContent: "space-between"}} spacing={1}>
                            <Stack direction={"row"} style={{alignItems: "center"}} spacing={1}>
                                <EventCheckoutTicketText>
                                    {ticket.type}
                                </EventCheckoutTicketText>
                                <EventCheckoutTicketTooltip title={ticket.additionalInformation}>
                                    <InfoOutlinedIcon />
                                </EventCheckoutTicketTooltip>
                            </Stack>
                            <Stack direction={"row"} style={{alignItems: "center"}} spacing={1}>
                                <EventCheckoutTicketText>
                                    {getTicketPrice(ticket.price, ticket.currencyCode)}
                                </EventCheckoutTicketText>
                                <EventCheckoutTicketButton
                                    onClick={() => handleDecrement(ticket.type)} disabled={disabled}
                                >
                                    -
                                </EventCheckoutTicketButton>
                                <EventCheckoutTicketText>
                                    {ticketCounts[ticket.type]}
                                </EventCheckoutTicketText>
                                <EventCheckoutTicketButton
                                    onClick={() => handleIncrement(ticket.type)} disabled={disabled}
                                >
                                    +
                                </EventCheckoutTicketButton>
                            </Stack>
                        </Stack>
                    </EventCheckoutTicketPaper>
                ))}
            </Stack>
        </>
    )
}

export default CheckoutTicketsSection