import { Button, Card, CardActionArea, Fab, styled, TextField } from "@mui/material"

export const SocialMediaAddButton = styled(Fab)`
    border: 2px solid #A0B7CD;
    background: linear-gradient(92deg, rgba(201, 214, 255, 0.95) 0%, rgba(226, 226, 226, 0.95) 100.4%);
    color: #374857;
    box-shadow: none;
`;

export const TicketingAddButton = styled(Fab)`
    border: 2px solid #A0B7CD;
    background: linear-gradient(92deg, rgba(201, 214, 255, 0.95) 0%, rgba(226, 226, 226, 0.95) 100.4%);
    color: #374857;
    box-shadow: none;
`;

export const SocialMediaDeleteButton = styled(Button)`
    box-shadow: none;
    min-width: auto;
    width: auto;
    height: 4em;
`;

export const TicketingDeleteButton = styled(Button)`
    box-shadow: none;
    min-width: auto;
    width: auto;
    height: 4em;
`;

export const ConfirmButton = styled(Button)`
    border: 2px solid #A0B7CD;
    background: linear-gradient(92deg, rgba(201, 214, 255, 0.95) 0%, rgba(226, 226, 226, 0.95) 100.4%);
    color: #374857;
    width: 100%;
    padding: 1em;
    margin: 4em 0;
    font-family: Gilroy,sans-serif;
    text-transform: none;
    font-size: 1.2em;
    font-weight: 900;
    box-shadow: none;
`;
