import {EventCheckoutBillingInputLabel} from "../eventStyles";
import React, {SetStateAction, useEffect, useState} from "react";
import {Grid} from "@mui/material";
import {RequestField} from "../../../common/components/form/formTypes";
import {FormTextFieldWithValidation} from "../../../common/components/form/FormTextFieldWithValidation";
import {EventTicketPurchaseBillingAddress} from "../../../store/api";


export interface BillingAddressCheckoutState {
    billingAddress: EventTicketPurchaseBillingAddress,
    error?: string
}

interface BillingAddressCheckoutFormState {
    country: RequestField<string>;
    city: RequestField<string>;
    postalCode: RequestField<string>;
    street: RequestField<string>;
    house: RequestField<string>;
    apartment: RequestField<string>;
}

const initialState: BillingAddressCheckoutFormState = {
    country: {
        value: '',
        validation: {status: 'initial'}
    },
    city: {
        value: '',
        validation: {status: 'initial'}
    },
    postalCode: {
        value: '',
        validation: {status: 'initial'}
    },
    street: {
        value: '',
        validation: {status: 'initial'}
    },
    house: {
        value: '',
        validation: {status: 'initial'}
    },
    apartment: {
        value: '',
        validation: {status: 'initial'}
    }
}

const CheckoutBillingAddressSection: React.FC<{
    setBillingAddressCheckoutState: React.Dispatch<SetStateAction<BillingAddressCheckoutState>>,
    disabled: boolean
}> = (
    {setBillingAddressCheckoutState, disabled}
) => {
    const [formState, setFormState] = useState<BillingAddressCheckoutFormState>(initialState);

    useEffect(() => {
        setBillingAddressCheckoutState(() => {
            let error = undefined
            if (Object.values(formState).some(field => field.validation.status === 'initial'))
                error = 'fill in the billing address!'
            else if (Object.values(formState).some(field => field.validation.status === 'invalid'))
                error = 'invalid billing address!'

            const billingAddress: EventTicketPurchaseBillingAddress = {
                country: formState.country.value,
                city: formState.city.value,
                postalCode: formState.postalCode.value,
                street: formState.street.value,
                house: formState.house.value,
                apartment: formState.apartment.value
                    ? formState.apartment.value
                    : undefined
            }
            return {
                billingAddress: billingAddress,
                error: error
            }
        })
    }, [formState, setBillingAddressCheckoutState]);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <EventCheckoutBillingInputLabel>
                        Street
                    </EventCheckoutBillingInputLabel>
                    <FormTextFieldWithValidation
                        field={formState.street}
                        onChange={(e) => {
                            setFormState({
                                ...formState,
                                street: {
                                    value: e.target.value,
                                    validation: e.target.value.length > 0 ?
                                        {status: 'valid'} :
                                        {status: 'invalid', message: 'street is required'}
                                }
                            });
                        }}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={6}>
                    <EventCheckoutBillingInputLabel>
                        House number
                    </EventCheckoutBillingInputLabel>
                    <FormTextFieldWithValidation
                        field={formState.house}
                        onChange={(e) => {
                            setFormState({
                                ...formState,
                                house: {
                                    value: e.target.value,
                                    validation: e.target.value.length > 0 ?
                                        {status: 'valid'} :
                                        {status: 'invalid', message: 'house number is required'}
                                }
                            });
                        }}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={6}>
                    <EventCheckoutBillingInputLabel>
                        Apartment number
                    </EventCheckoutBillingInputLabel>
                    <FormTextFieldWithValidation
                        field={formState.apartment}
                        onChange={(e) => {
                            setFormState({
                                ...formState,
                                apartment: {
                                    value: e.target.value,
                                    validation: {status: 'valid'}
                                }
                            });
                        }}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={6}>
                    <EventCheckoutBillingInputLabel>
                        Postal code
                    </EventCheckoutBillingInputLabel>
                    <FormTextFieldWithValidation
                        field={formState.postalCode}
                        onChange={(e) => {
                            setFormState({
                                ...formState,
                                postalCode: {
                                    value: e.target.value,
                                    validation: e.target.value.length > 0 ?
                                        {status: 'valid'} :
                                        {status: 'invalid', message: 'postal code is required'}
                                }
                            });
                        }}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={6}>
                    <EventCheckoutBillingInputLabel>
                        City
                    </EventCheckoutBillingInputLabel>
                    <FormTextFieldWithValidation
                        field={formState.city}
                        onChange={(e) => {
                            setFormState({
                                ...formState,
                                city: {
                                    value: e.target.value,
                                    validation: e.target.value.length > 0 ?
                                        {status: 'valid'} :
                                        {status: 'invalid', message: 'city is required'}
                                }
                            });
                        }}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={6}>
                    <EventCheckoutBillingInputLabel>
                        Country
                    </EventCheckoutBillingInputLabel>
                    <FormTextFieldWithValidation
                        field={formState.country}
                        onChange={(e) => {
                            setFormState({
                                ...formState,
                                country: {
                                    value: e.target.value,
                                    validation: e.target.value.length > 0 ?
                                        {status: 'valid'} :
                                        {status: 'invalid', message: 'country is required'}
                                }
                            });
                        }}
                        disabled={disabled}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default CheckoutBillingAddressSection
