import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import {useDefaultBackground} from "../common/hooks/backgroundHooks";
import {
    Event,
    useGetEventQuery,
    useGetUserQuery,
    useListEventTicketPurchasesQuery,
    useValidateEventTicketPurchaseMutation
} from "../store/api";
import {LoadingBackdrop} from "../common/components/LoadingBackdrop";
import {ErrorBackdrop} from "../common/components/ErrorBackdrop";
import Header from "../common/components/header/Header";
import {
    Box,
    Button,
    CircularProgress,
    Grid,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import {skipToken} from "@reduxjs/toolkit/query";
import store from "../store/store";
import {setSnackbar} from "../store/snackbarSlice";
import PendingIcon from '@mui/icons-material/Pending';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CancelIcon from '@mui/icons-material/Cancel';
import jsQR from 'jsqr';

interface TableRowData {
    userId: string;
    tickets: {
        ticketType: string;
        count: number;
    }[];
    purchaseStatus: string;
    validationStatus: 'awaiting' | 'validated' | 'invalid'
}

interface ValidationData {
    id: string;
    eventId: string;
    userId: string;
}

const validationStatusToIcon = (validationStatus: 'awaiting' | 'validated' | 'invalid') => {
    switch (validationStatus) {
        case 'awaiting':
            return <PendingIcon color={"info"} />
        case 'validated':
            return <CheckBoxIcon color={"success"} />
        case 'invalid':
            return <CancelIcon color={"error"} />
    }
}

const HostValidateEventRenderer: React.FC<{event: Event}> = ({event}) => {
    useDefaultBackground()
    const eventPurchasesFetch = useListEventTicketPurchasesQuery({eventId: event.id})
    const [tableData, setTableData] = React.useState<TableRowData[]>([]);
    useEffect(() => {
        if (!eventPurchasesFetch.data) {
            return
        }
        const data = eventPurchasesFetch.data.data.map((ticketPurchase) => {
            const purchaseStatus = ticketPurchase.status
            const validationStatus: 'awaiting' | 'validated' | 'invalid'
                = purchaseStatus === 'VALIDATED' ? 'validated' :
                ['PAYMENT_SUCCESS', 'REFUND_FAILED'].includes(purchaseStatus) ? 'awaiting' : 'invalid'
            return {
                userId: ticketPurchase.userId,
                tickets: ticketPurchase.tickets.map((ticket) => {
                    return {
                        ticketType: ticket.type,
                        count: ticket.count,
                    }
                }),
                purchaseStatus: purchaseStatus,
                validationStatus: validationStatus
            }
        })
        setTableData(data)
    }, [eventPurchasesFetch.data]);

    return (
        <>
            <LoadingBackdrop open={eventPurchasesFetch.isLoading} />
            <ErrorBackdrop errors={[eventPurchasesFetch.error]} />
            <Header logoStyle={"dark"} hasSearch={false} hasAvatar={false} />
            <Typography variant={"h3"} mt={3}>
                Validate {event.name}
            </Typography>
            <Typography variant={"h4"} mt={5} mb={2}>Purchase list</Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Customer</TableCell>
                        <TableCell>Tickets</TableCell>
                        <TableCell>Purchase status</TableCell>
                        <TableCell>Validation status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableData.map((row) => (
                        <PurchaseListTableRow row={row} key={row.userId} />
                    ))}
                </TableBody>
            </Table>
            {event.id && <TicketValidationForm expectedEventId={event.id} />}
        </>
    )
}

const PurchaseListTableRow: React.FC<{row: TableRowData}> = ({row}) => {
    const userFetch = useGetUserQuery({id: row.userId})
    if (userFetch.isLoading) {
        return (
            <TableRow>
                <TableCell colSpan={3}>
                    <CircularProgress color="primary" />
                </TableCell>
            </TableRow>
        );
    }

    if (userFetch.error) {
        return (
            <TableRow>
                <TableCell colSpan={3}>
                    Error loading data
                </TableCell>
            </TableRow>
        );
    }
    const fullName = userFetch.data ?
        userFetch.data.data.firstName + " " + userFetch.data.data.lastName : ""

    return (
        <TableRow>
            <TableCell>{fullName}</TableCell>
            <TableCell>
                {row.tickets.map((ticket) => (
                    <Typography key={ticket.ticketType}>
                        {ticket.ticketType} x {ticket.count}
                    </Typography>
                ))}
            </TableCell>
            <TableCell>{row.purchaseStatus}</TableCell>
            <TableCell>{validationStatusToIcon(row.validationStatus)}</TableCell>
        </TableRow>
    );
}

const TicketValidationForm: React.FC<{expectedEventId: string}> = ({expectedEventId}) => {
    const [userId, setUserId] = React.useState<string>("");
    const [eventId, setEventId] = React.useState<string>("");
    const [purchaseId, setPurchaseId] = React.useState<string>("");

    const [ validateEventPurchase, validateEventPurchaseStatus ] = useValidateEventTicketPurchaseMutation()

    const [qrCodeText, setQRCodeText] = React.useState<string>('');
    const [qrValidatedData, setQRValidatedData] = React.useState<ValidationData | null>(null);

    useEffect(() => {
        if (qrValidatedData) {
            setUserId(qrValidatedData.userId)
            setEventId(qrValidatedData.eventId)
            setPurchaseId(qrValidatedData.id)
        }
    }, [qrValidatedData]);

    const handleImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            const imageData = await getImageData(file);
            const code = jsQR(imageData.data, imageData.width, imageData.height);

            if (code) {
                try {
                    const data = JSON.parse(code.data);
                    if (typeof data === 'object' && 'id' in data && 'eventId' in data && 'userId' in data) {
                        const validatedData: ValidationData = {
                            id: data.id,
                            eventId: data.eventId,
                            userId: data.userId
                        }
                            setQRCodeText('');
                        setQRValidatedData(validatedData);
                    } else {
                        setQRCodeText('Invalid data format');
                        setQRValidatedData(null);
                    }
                }
                catch (e) {
                    setQRCodeText('Invalid data format');
                }
            } else {
                setQRCodeText('No QR code detected');
                setQRValidatedData(null);
            }
        }
    };

    const getImageData = (file: File): Promise<ImageData> => {
        return new Promise((resolve, reject) => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const img = new Image();

            img.onload = () => {
                canvas.width = img.width;
                canvas.height = img.height;
                ctx?.drawImage(img, 0, 0);
                resolve(ctx?.getImageData(0, 0, img.width, img.height) as ImageData);
            };

            img.onerror = reject;
            img.src = URL.createObjectURL(file);
        });
    };


    const submitForm = () => {
        if (!userId || !eventId || !purchaseId) {
            const message = "Form validation failed."
            store.dispatch(setSnackbar({isOpen: true, message: message, severity: "error"}));
            return
        }
        if (eventId !== expectedEventId) {
            const message = "Event id does not match."
            store.dispatch(setSnackbar({isOpen: true, message: message, severity: "error"}));
            return
        }
        validateEventPurchase({
            id: purchaseId,
            validateEventTicketPurchaseRequest: {
                userId: userId,
                eventId: eventId,
                id: purchaseId
            }
        })
            .unwrap()
            .then(_ => {
                const message = "Ticket validated succesfully"
                store.dispatch(setSnackbar({isOpen: true, message: message, severity: "success"}));
            })
            .catch(error => {
                let message = error.toString()
                if (error.data && error.data.message) {
                    message = error.data.message
                }
                store.dispatch(setSnackbar({isOpen: true, message: message, severity: "error"}));
            })
    }

    return (
        <>
            <Typography variant={"h4"} mt={5} mb={2}>Validate ticket</Typography>
            <Grid container>
                <Grid item xs={6}>
                    <Stack spacing={1}>
                        <TextField
                            id="outlined-basic" label="user id" variant="outlined"
                            value={userId} onChange={(e) => setUserId(e.target.value)}
                            disabled={validateEventPurchaseStatus.isLoading}
                        />
                        <TextField
                            id="filled-basic" label="event id" variant="outlined"
                            value={eventId} onChange={(e) => setEventId(e.target.value)}
                            disabled={validateEventPurchaseStatus.isLoading}
                        />
                        <TextField
                            id="standard-basic" label="purchase id" variant="outlined"
                            value={purchaseId} onChange={(e) => setPurchaseId(e.target.value)}
                            disabled={validateEventPurchaseStatus.isLoading}
                        />
                        <Button
                            variant="contained"
                            onClick={() => submitForm()}
                            disabled={validateEventPurchaseStatus.isLoading}
                        >
                            Submit
                        </Button>
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Stack>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                            <input
                                accept="image/*"
                                id="contained-button-file"
                                type="file"
                                style={{ display: 'none' }}
                                onChange={handleImageChange}
                            />
                            <label htmlFor="contained-button-file">
                                <Button variant="contained" component="span">
                                    Upload Image
                                </Button>
                                <Typography>
                                    {qrCodeText}
                                </Typography>
                            </label>
                        </Box>
                    </Stack>
                </Grid>
            </Grid>
        </>
    );
}

const HostValidateEvent: React.FC = () => {
    const { eventId } = useParams()
    const eventFetch = useGetEventQuery(eventId
        ? {
            id: eventId
        }
        : skipToken);
    const event = eventFetch.data?.data
    return (
        <>
            <LoadingBackdrop open={eventFetch.isLoading} />
            <ErrorBackdrop errors={[eventFetch.error]} />
            {event && <HostValidateEventRenderer event={event} />}
        </>
    )
}

export default HostValidateEvent;