import React, { forwardRef } from "react"
import { FormTextField } from "./formStyles"

const FormPhoneTextField: React.ForwardRefRenderFunction<{}> = (props, ref) => {
    return (
        <FormTextField
            {...props}
            inputRef={ref}
        />
    )
}

export const FormPhoneTextFieldRef = forwardRef(FormPhoneTextField)
