import React from "react";
import Header from "../../common/components/header/Header";
import { Avatar, Button, Grid, Typography } from "@mui/material";
import {
    useGetUserSelfQuery,
    useListPastEventsByUserQuery,
    useListUpcomingEventsByUserQuery
} from "../../store/api";
import { Link } from "react-router-dom";
import { useDefaultBackground } from "../../common/hooks/backgroundHooks"
import { LoadingBackdrop } from "../../common/components/LoadingBackdrop"
import { ErrorBackdrop } from "../../common/components/ErrorBackdrop"
import { EditOutlined } from "@mui/icons-material"
import { EventWithTicketList } from "../../common/components/event/EventWithTicketList"
import { useGetImageQuery } from "../../store/fileApi"
import { skipToken } from "@reduxjs/toolkit/query"
import { EventReviewByUserList } from "../../common/components/event/review/EventReviewByUserList"

const MyProfile: React.FC = () => {
    useDefaultBackground()

    const getUser = useGetUserSelfQuery()

    const avatar = useGetImageQuery(
        getUser.data?.data?.avatar?.id
            ? {
                id: getUser.data?.data?.avatar?.id,
                quality: 0.8,
                maxWidth: 200,
                maxHeight: 200
            }
            : skipToken
    )

    const user = getUser.data?.data

    const listUpcomingEvents = useListUpcomingEventsByUserQuery()
    const listPastEvents = useListPastEventsByUserQuery()

    return (
        <>
            <LoadingBackdrop
                open={
                    getUser.isLoading
                }
            />

            <ErrorBackdrop
                errors={[
                    getUser.error
                ]}
            />

            <Header logoStyle={"dark"} hasSearch={false} hasAvatar={false}/>
            {
                user &&
                <Grid container>
                    <Grid item xs={12} md={4}/>
                    <Grid item container spacing={6} xs={12} md={4}>
                        <Grid item>
                            <Avatar
                                sx={{ width: 96, height: 96 }}
                                src={
                                    avatar.data
                                        ? avatar.data
                                        : undefined
                                }
                            />
                        </Grid>
                        <Grid item alignSelf={"center"}>
                            <Typography
                                variant="h4"
                                fontWeight={700}
                                marginBottom={1}
                            >
                                {user.firstName} {user.lastName}
                            </Typography>
                            <Button
                                variant="outlined"
                                color="success"
                                component={Link}
                                to="/profile/edit"
                                startIcon={<EditOutlined/>}
                            >
                                Edit profile
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            }

            <div style={{ height: "1em" }}/>
            {
                user &&
                <EventWithTicketList
                    title={"My upcoming Events"}
                    user={user}
                    eventsWithTicket={listUpcomingEvents.data?.data ?? []}
                    isLoading={listUpcomingEvents.isLoading}
                />
            }

            <div style={{ height: "1em" }}/>
            {
                user &&
                <EventWithTicketList
                    title={"My past Events"}
                    user={user}
                    eventsWithTicket={listPastEvents.data?.data ?? []}
                    isLoading={listPastEvents.isLoading}
                />
            }

            <div style={{ height: "1em" }}/>
            {
                user &&
                <EventReviewByUserList
                    title={"My reviews"}
                    user={user}
                    pagination={true}
                />
            }

            <div style={{ height: "2em" }}/>
        </>
    )
}

export default MyProfile
