import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface SnackbarState {
    isOpen: boolean;
    message: string;
    severity: 'success' | 'info' | 'warning' | 'error';
}

const initialState: SnackbarState = {
    isOpen: false,
    message: '',
    severity: 'success'
};

const snackbarSlice = createSlice({
    name: 'snackbar',
    initialState,
    reducers: {
        setSnackbar: (state, action: PayloadAction<{
            isOpen?: boolean;
            message?: string;
            severity?: 'success' | 'info' | 'warning' | 'error';
        }>) => {
            return {...state, ...action.payload}
        }
    }
});

export const { setSnackbar } = snackbarSlice.actions;
export default snackbarSlice.reducer;
