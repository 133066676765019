import { useEffect } from "react"
import store from "../../store/store"
import { setBackground } from "../../store/backgroundSlice"
import { defaultBackgroundColor } from "../constants"

export const useDefaultBackground = () => {
    useEffect(() => {
        store.dispatch(
            setBackground({
                value: {
                    type: "fill-color",
                    color: defaultBackgroundColor
                },
                overlay: {
                    type: "none",
                }
            })
        );
    }, []);

}
