import React from "react"
import { Chip, Typography } from "@mui/material"
import { LinkOutlined } from "@mui/icons-material"
import { Link } from "react-router-dom"

export const LinkChip: React.FC<{ title: string, value: string }> = ({ title, value }) => {
    return (
        <Chip
            key={`${title}-${value}`}
            size={"medium"}
            style={{marginRight: "0.5em", marginBottom: "0.5em"}}
            icon={<LinkOutlined fontSize={"small"} />}
            label={
                <Link
                    style={{textDecoration: "none", color: "inherit"}}
                    to={value}
                >
                    <Typography
                        variant="body2"
                        textTransform={"capitalize"}
                        fontWeight={600}
                        component="span"
                    >
                        {`${title}: ${value}`}
                    </Typography>
                </Link>
            }
        />
    )
}
