import {format} from "date-fns";
import {EventAddress, EventTicket} from "../../../store/api";

export const formatDate = (dateStr: string) => {
    const dateObj = new Date(dateStr)
    return format(dateObj, 'MMMM do, yyyy HH:mm');
}

export const getPriceString = (tickets: EventTicket[]) => {
    let priceMin = undefined
    let priceMax = undefined
    for (let i = 0; i < tickets.length; i++) {
        if (priceMin === undefined || tickets[i].price < priceMin)
            priceMin = tickets[i].price
        if (priceMax === undefined || tickets[i].price > priceMax)
            priceMax = tickets[i].price
    }
    if (priceMin === undefined || priceMax === undefined) {
        priceMin = 0
        priceMax = 0
    }
    const free = (tickets.length === 0 || priceMax === 0 )
    const currency = tickets.length > 0 ? 'PLN' : tickets[0].currencyCode
    const price = priceMin === priceMax ? `${priceMin}` : `${priceMin} - ${priceMax}`
    return free ? 'Free' : `${price} ${currency}`
}

export const getAddressString = (address: EventAddress) => {
    return `${address.street} ${address.house}, ${address.postalCode} ${address.city}, ${address.country}`
}
