import _ from "lodash"
import { Attribute, AttributeGroup, useGetAttributeGroupsQuery } from "../../../store/api"
import { Grid, MenuItem } from "@mui/material"
import { FormTextField } from "./formStyles"
import React, { useEffect } from "react"
import { RequestFieldAttributes } from "./formTypes"

export interface FormAttributeSelectorsSectionProps {
    attributesRequestField: RequestFieldAttributes
    onChange: (attributesRequestField: RequestFieldAttributes) => void
}

export const FormAttributeSelectorsSection: React.FC<FormAttributeSelectorsSectionProps> = ({
    attributesRequestField,
    onChange
}) => {
    const getAttributeGroups = useGetAttributeGroupsQuery();

    useEffect(() => {
        if(!getAttributeGroups.data) {
            return;
        }

        const newPlaceAttributes = { ...attributesRequestField };
        getAttributeGroups.data.data.forEach((attributeGroup: AttributeGroup) => {
            newPlaceAttributes[attributeGroup.id as string] = {
                value: undefined,
                validation: { status: 'invalid', message: 'Attribute cannot be empty' }
            }
        })

        onChange(newPlaceAttributes)
    }, [getAttributeGroups.data])

    return (
        <>
            {
                _.chunk(getAttributeGroups.data?.data, 3)
                    .map((attributeGroup: AttributeGroup[], index: number) => (
                        <Grid item container columnSpacing={2} marginTop={1} key={index}>
                            {
                                attributeGroup.map((attributeGroup, index) => (
                                    <Grid item xs={12} md={4} key={index}>
                                        <FormTextField
                                            select
                                            style={{
                                                background: `${attributeGroup.tags.color}`
                                            }}
                                            label={attributeGroup.name}
                                            value={
                                                attributesRequestField[attributeGroup.id as string]
                                                    ?.value
                                                    ?.id
                                                ?? ''
                                            }
                                            onChange={(event) => {
                                                const newPlaceAttributes = { ...attributesRequestField };
                                                newPlaceAttributes[attributeGroup.id as string] = {
                                                    value: attributeGroup.attributes
                                                        .find((attribute) => attribute.id === event.target.value)
                                                        ?? undefined,
                                                    validation: event.target.value.length > 0
                                                        ? { status: 'valid' }
                                                        : { status: 'invalid', message: 'Attribute cannot be empty' }
                                                }

                                                onChange(newPlaceAttributes)
                                            }}
                                        >
                                            <MenuItem value={""}>Select {attributeGroup.name}</MenuItem>
                                            {
                                                attributeGroup.attributes.map((attribute, index) => (
                                                    <MenuItem
                                                        value={attribute.id}
                                                        key={index}
                                                    >
                                                        {`${attribute.tags.icon} ${attribute.name}`}
                                                    </MenuItem>
                                                ))
                                            }
                                        </FormTextField>
                                        <div style={{ height: "0.5em" }}></div>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    ))
            }
        </>
    )
}
