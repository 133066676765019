import { AuthProviderProps } from "react-oidc-context"
import { authAuthority, authClientId, authPostLogoutRedirectUri, authRedirectUri } from "./common/constants"

const oidcConfig: AuthProviderProps = {
    authority: authAuthority,
    client_id: authClientId,
    redirect_uri: authRedirectUri,
    response_type: "code",
    post_logout_redirect_uri: authPostLogoutRedirectUri,
    scope: "openid profile offline_access",
    automaticSilentRenew: true,
    includeIdTokenInSilentRenew: true,
    validateSubOnSilentRenew: true,
    loadUserInfo: true
};

export default oidcConfig;
