import React, {SetStateAction, useEffect} from "react";
import {Checkbox, FormControlLabel, FormGroup} from "@mui/material";

export interface PoliciesCheckoutState {
    termsAndConditions: boolean,
    privacyPolicy: boolean,
    newsletter: boolean,
    error?: string
}

const termsAndConditionsLabel = 'I accept terms and conditions of Live Local Life service'
const privacyPolicyLabel = 'I accept privacy policy and personal data management by Live Local Life and event organizer'
const newsletterLabel = 'I want to subscribe Live Local Life newsletter'

const CheckoutPoliciesSection: React.FC<{
    setPoliciesCheckoutState: React.Dispatch<SetStateAction<PoliciesCheckoutState>>
    disabled: boolean
}> = (
    {setPoliciesCheckoutState, disabled}
) => {
    const [termsAndConditions, setTermsAndConditions] = React.useState(false);
    const [privacyPolicy, setPrivacyPolicy] = React.useState(false);
    const [newsletter, setNewsletter] = React.useState(false);

    useEffect(() => {
        setPoliciesCheckoutState(() => {
            let error = undefined
            if (!termsAndConditions || !privacyPolicy)
                error = 'all required policies must be accepted!'
            return {
                termsAndConditions: termsAndConditions,
                privacyPolicy: privacyPolicy,
                newsletter: newsletter,
                error: error
            }
        })
    },[termsAndConditions, privacyPolicy, newsletter, setPoliciesCheckoutState]);

    return (
        <>
            <FormGroup
                style={{marginLeft: '1rem'}}
            >
                <FormControlLabel
                    required label={termsAndConditionsLabel}
                    control={<Checkbox
                        onChange={(e) => setTermsAndConditions(e.target.checked)}
                        disabled={disabled}
                    />}
                />
                <FormControlLabel
                    required label={privacyPolicyLabel}
                    control={<Checkbox
                        onChange={(e) => setPrivacyPolicy(e.target.checked)}
                        disabled={disabled}
                    />}
                />
                <FormControlLabel
                    label={newsletterLabel}
                    control={<Checkbox
                        onChange={(e) => setNewsletter(e.target.checked)}
                        disabled={disabled}
                    />}
                />
            </FormGroup>
        </>
    )
}

export default CheckoutPoliciesSection