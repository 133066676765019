import {Chip, Grid, Typography} from "@mui/material";
import {findAttributeGroupForAttribute} from "./attributeUtils";
import React from "react";
import {Attribute, useGetAttributeGroupsQuery} from "../../../store/api";

export const AttributesGrid: React.FC<{attributes: Attribute[]}> = ({ attributes}) => {
    const getAttributeGroups = useGetAttributeGroupsQuery();
    const attributeGroups = getAttributeGroups.data?.data ?? [];
    return (
        <Grid item container marginTop={1}>
            {
                attributes
                    ?.map(attribute => ({
                        attribute: attribute,
                        attributeGroup: findAttributeGroupForAttribute(attributeGroups, attribute)
                    }))
                    ?.map(({ attribute, attributeGroup }, i) => (
                        <Chip
                            key={`${attribute.id}-${i}`}
                            size={"medium"}
                            style={{marginRight: "0.5em", marginBottom: "0.5em", backgroundColor: attributeGroup?.tags?.color.toString() ?? "white"}}
                            label={
                                <Typography
                                    variant="body2"
                                    textTransform={"capitalize"}
                                    fontWeight={600}
                                    component="span"
                                >
                                    {`${attribute.tags.icon} ${attribute.name}`}
                                </Typography>
                            }
                        />
                    ))
            }
        </Grid>
    )
}