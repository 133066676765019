import { Button, Card, Chip, Grid, Paper, Skeleton, Stack, styled, Typography } from "@mui/material"
import {
    Event,
    EventHost,
    EventTicketPurchase,
    EventWithEventTicket,
    useGetEventHostQuery, useListReviewsQuery,
    User
} from "../../../store/api"
import jsPDF from "jspdf"
import { formatDate, getAddressString } from "./eventUtils"
import {
    CheckOutlined,
    CheckroomOutlined, DeleteOutline, DownloadOutlined,
    ErrorOutlined,
    EventOutlined,
    LocationOnOutlined,
    PendingOutlined, RateReviewOutlined
} from "@mui/icons-material"
import React, { CSSProperties } from "react"
import { useGetFileQuery, useGetImageQuery } from "../../../store/fileApi"
import { Link } from "react-router-dom"
import QRCode from "react-qr-code"

const EventWithTicketCardTitleTypography = styled(Typography)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 600;
  max-width: 100%;
`;

const renderQrCodeToImageData = async (
    targetId: string = "qr-code"
): Promise<string> => {
    const qrCodeSvg = document.getElementById(targetId)
    if (!qrCodeSvg) {
        throw new Error("QR Code not found")
    }

    const svgData = new XMLSerializer().serializeToString(qrCodeSvg as Node)

    const canvas = document.createElement("canvas")
    canvas.width = 300
    canvas.height = 300

    const ctx = canvas.getContext("2d")
    const img = new Image()

    img.src = "data:image/svg+xml;base64," + btoa(svgData)

    const imgData = await new Promise<string>((resolve, reject) => {
        img.onerror = reject
        img.onload = () => {
            const canvas = document.createElement("canvas")
            canvas.width = 300
            canvas.height = 300

            const ctx = canvas.getContext("2d")
            ctx?.clearRect(0, 0, 300, 300)
            ctx?.drawImage(img, 0, 0, 300, 300)

            resolve(canvas.toDataURL("image/png"))
        }
    })

    return imgData
}

const generatePdf = async (
    {
        event,
        ticket,
        eventHost,
        user
    }: {
        event: Event,
        ticket: EventTicketPurchase,
        eventHost: EventHost,
        user: User
    }
): Promise<void> => {
    // noinspection JSPotentiallyInvalidConstructorUsage
    const doc = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
    })

    doc.setFontSize(20)
    doc.text(event.name, 20, 20)

    doc.setFontSize(14)
    doc.text(`by ${eventHost.name}`, 20, 30)

    doc.setFontSize(12)
    doc.text(`${formatDate(event.startDate)} - ${formatDate(event.endDate)}`, 20, 40)
    doc.text(getAddressString(event.address), 20, 50)

    const qrImage = await renderQrCodeToImageData(`qr-code-${ticket.id}`)
    doc.addImage(
        qrImage,
        'PNG',
        20,
        60,
        60,
        60,
    )

    doc.setFontSize(14)
    doc.text("Ticket Details", 20, 130)

    doc.setFontSize(12)
    doc.text(`Ticket Status: ${getEventTicketStatusText(ticket)}`, 20, 140)
    doc.text(`Purchased at: ${formatDate(ticket.createdDate!)}`, 20, 150)
    doc.text(`Purchased by: ${user.firstName} ${user.lastName}`, 20, 160)

    doc.setFontSize(14)
    doc.text("Purchased Tickets", 20, 180)

    ticket.tickets
        .forEach((ticket, i) => {
            doc.setFontSize(12)
            doc.text(`Ticket #${i + 1}`, 20, 190 + i * 10)
            doc.text(`Type: ${ticket.type}`, 50, 190 + i * 10)
            doc.text(`Count: ${ticket.count}`, 150, 190 + i * 10)
        })

    doc.setFontSize(14)
    doc.text("Ticket Metadata", 20, 210 + ticket.tickets.length * 10)

    doc.setFontSize(12)
    doc.text(`Ticket ID: ${ticket.id}`, 20, 220 + ticket.tickets.length * 10)
    doc.text(`Event ID: ${event.id}`, 20, 230 + ticket.tickets.length * 10)
    doc.text(`User ID: ${user.id}`, 20, 240 + ticket.tickets.length * 10)

    doc.save(`${event.name}.pdf`)
}

const getEventTicketStatusColor = (ticket: EventTicketPurchase): string => {
    switch (ticket.status) {
        case "PAYMENT_PENDING":
            return "primary"
        case "PAYMENT_FAILED":
            return "error"
        case "PAYMENT_SUCCESS":
            return "success"
        case "REFUND_PENDING":
            return "primary"
        case "REFUND_FAILED":
            return "error"
        case "REFUND_SUCCESS":
            return "success"
        case "VALIDATED":
            return "success"
    }
}

const getEventTicketStatusText = (ticket: EventTicketPurchase): string => {
    switch (ticket.status) {
        case "PAYMENT_PENDING":
            return "Payment Pending"
        case "PAYMENT_FAILED":
            return "Payment Failed"
        case "PAYMENT_SUCCESS":
            return "Paid"
        case "REFUND_PENDING":
            return "Refunding..."
        case "REFUND_FAILED":
            return "Refund Failed"
        case "REFUND_SUCCESS":
            return "Refunded"
        case "VALIDATED":
            return "Validated"
    }
}

const getEventTicketStatusIcon = (ticket: EventTicketPurchase): JSX.Element => {
    switch (ticket.status) {
        case "PAYMENT_PENDING":
            return <PendingOutlined/>
        case "PAYMENT_FAILED":
            return <ErrorOutlined/>
        case "PAYMENT_SUCCESS":
            return <CheckOutlined/>
        case "REFUND_PENDING":
            return <PendingOutlined/>
        case "REFUND_FAILED":
            return <ErrorOutlined/>
        case "REFUND_SUCCESS":
            return <CheckOutlined/>
        case "VALIDATED":
            return <CheckroomOutlined/>
    }
}

const EventWithTicketLeftBlob = styled<
    React.FC<{
        blobBackgroundColor?: string,
    }>
>(
    (props) => <Paper
        {...props}
        elevation={0}
    />
)`
  position: relative;
  top: 50%;
  transform: translate(0px, -36px);
  left: 0;
  right: 0;
  width: 35px;
  height: 70px;
  z-index: 2;
  border-bottom-right-radius: 144px;
  border-top-right-radius: 144px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-left: none;
  background: ${props => props.blobBackgroundColor || "white"}
`

const EventWithTicketRightBlob = styled<
    React.FC<{
        blobBackgroundColor?: string,
    }>
>(
    (props) => <Paper
        {...props}
        elevation={0}
    />
)`
  position: relative;
  top: -50%;
  transform: translate(-36px, 36px);
  left: 100%;
  right: 0;
  width: 35px;
  height: 70px;
  z-index: 2;
  border-bottom-left-radius: 144px;
  border-top-left-radius: 144px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-right: none;
  background: ${props => props.blobBackgroundColor || "white"}
`

const EventWithTicketCardBackgroundLayout = styled<
    React.FC<{
        children?: React.ReactNode
    }>
>(
    (props) => <Paper
        {...props}
        elevation={0}
    />
)`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  align-items: end;
  justify-content: center;
`

const EventWithTicketCardBackgroundImage = styled<
    React.FC<{
        image: string
    }>
>(
    (props) => <Paper
        {...props}
        elevation={0}
    />
)`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(${props => props.image});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`

const EventWithTicketCardBackgroundPlaceholder = styled(
    (props) => <Skeleton
        {...props}
        variant="rectangular"
    />
)`
  position: absolute;
  width: 100%;
  height: 100%;
`
const EventWithTicketCardBackgroundGradientOverlay = styled(
    (props) => <Paper
        {...props}
        elevation={0}
    />
)`
  position: absolute;
  top: 0;
  left: 90%;
  width: 10%;
  height: 100%;
  background: linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,1));
`

const EventWithTicketBackground: React.FC<{
    image?: string,
    children?: React.ReactNode
}> = ({
    image,
    children
}) => {

    return (
        <EventWithTicketCardBackgroundLayout>
            {
                image &&
                <EventWithTicketCardBackgroundImage image={image} />
            }
            {
                !image &&
                <EventWithTicketCardBackgroundPlaceholder />
            }
            <EventWithTicketCardBackgroundGradientOverlay />

            {
                children
            }
        </EventWithTicketCardBackgroundLayout>
    )
}

const EventWithTicketCard = styled<
    React.FC<{
        children?: React.ReactNode
    }>
>(
    (props) => <Card
        {...props}
        variant={"outlined"}
    />
)`
    margin-top: -72px;
    margin-bottom: -72px;
`

const EventWithTicketCardRightPart = styled<
    React.FC<{
        children?: React.ReactNode
    }>
>(
    (props) => <Paper
        {...props}
        elevation={0}
    />
)`
  border-left: 1px dashed rgba(0, 0, 0, 0.12);
`

const EventWithTicketContainer = styled<
    React.FC<{
        children?: React.ReactNode
    }>
>(
    (props) => <Paper
        {...props}
        elevation={0}
    />
)`
  display: inline-block;
  vertical-align: top;
  width: 100%;
`

const EventWithTicketQrCodeContainer = styled<
    React.FC<{
        children?: React.ReactNode
    }>
>(
    (props) => <Paper
        {...props}
        elevation={0}
    />
)`
  padding: 8px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  width: 100px;
  height: 100px;
  min-width: 100px;
  min-height: 100px;
  background: white;
  z-index: 2;
`

const EventWithTicketQrCode = styled<
    React.FC<{
        id: string,
        value: string
    }>
>(
    (props) => <QRCode
        {...props}
    />
)`
  width: 100%;
  height: 100%;
`

export const EventWithTicketPlaceholder: React.FC<{
    blobBackgroundColor?: string,
}> = ({
    blobBackgroundColor
}) => {
    return (
        <Grid container>
            <EventWithTicketContainer>
                <EventWithTicketLeftBlob
                    blobBackgroundColor={blobBackgroundColor}
                />
                <EventWithTicketCard>
                    <Grid container>
                        <Grid item xs={4}>
                            <EventWithTicketBackground />
                        </Grid>
                        <Grid
                            item
                            xs={8}
                            zIndex={2}
                            padding={2}
                            component={EventWithTicketCardRightPart}
                        >
                            <Skeleton variant="text" height={32} width={"100%"} />
                            <Skeleton variant="text" height={28} width={"100%"} />
                            <div style={{ height: "16px" }} />
                            <Skeleton variant="text" height={24} width={"100%"} />
                            <Skeleton variant="text" height={24} width={"100%"} />
                            <div style={{ height: "16px" }} />
                            <Skeleton variant="rounded" height={120} width={"100%"} />
                        </Grid>
                    </Grid>
                </EventWithTicketCard>
                <EventWithTicketRightBlob
                    blobBackgroundColor={blobBackgroundColor}
                />
            </EventWithTicketContainer>
        </Grid>
    )
}

export const EventWithTicket: React.FC<{
    eventWithTicket: EventWithEventTicket,
    user: User,
    blobBackgroundColor?: string,
}> = ({
    eventWithTicket,
    user,
    blobBackgroundColor
}) => {
    const { event, ticket } = eventWithTicket

    const imageId = event.coverPicture ? event.coverPicture.id : "undefined"
    const image =  useGetImageQuery({
        id: imageId,
        quality: 0.5,
        maxHeight: 350
    })

    const getEventHost = useGetEventHostQuery({ id: event.eventHostId })
    const listReviews = useListReviewsQuery({
        eventId: eventWithTicket.event.id,
        userId: user.id
    })

    const eventHost = getEventHost.data?.data

    return (
        <Grid container>
            <EventWithTicketContainer>
                <EventWithTicketLeftBlob
                    blobBackgroundColor={blobBackgroundColor}
                />
                <EventWithTicketCard>
                    <Grid container>
                        <Grid item xs={4}>
                            <EventWithTicketBackground image={image.data}>
                                {
                                    (
                                        ticket.status === "PAYMENT_SUCCESS"
                                        || ticket.status === "VALIDATED"
                                    ) &&
                                    <Stack direction={"column"} spacing={1} width={"120px"} marginBottom={2}>
                                        <EventWithTicketQrCodeContainer>
                                            <EventWithTicketQrCode
                                                id={`qr-code-${ticket.id}`}
                                                value={JSON.stringify({
                                                    id: ticket.id,
                                                    eventId: event.id,
                                                    userId: ticket.userId,
                                                })}
                                            />
                                        </EventWithTicketQrCodeContainer>
                                    </Stack>
                                }
                            </EventWithTicketBackground>
                        </Grid>
                        <Grid
                            item
                            xs={8}
                            zIndex={2}
                            padding={2}
                            component={EventWithTicketCardRightPart}
                        >
                            <EventWithTicketCardTitleTypography gutterBottom variant="h5" marginBottom={0}>{event.name}</EventWithTicketCardTitleTypography>
                            {
                                eventHost &&
                                <Typography variant={"subtitle1"} color={"#"}>
                                    by <Link to={`/host/${eventHost?.id}`} style={{ color: '#000', textDecoration: "none" }}>{eventHost?.name}</Link>
                                </Typography>
                            }
                            {
                                !eventHost &&
                                <Skeleton variant="text" height={28} width={"100%"} />
                            }
                            <Stack direction={"row"} spacing={1} marginTop={2}>
                                <EventOutlined fontSize="small" />
                                <Typography variant="body2" component="span">{formatDate(event.startDate)}</Typography>
                            </Stack>
                            <Stack direction={"row"} spacing={1} marginTop={1}>
                                <LocationOnOutlined fontSize="small" />
                                <Typography variant="body2" component="span">{getAddressString(event.address)}</Typography>
                            </Stack>

                            {
                                eventHost &&
                                <Stack direction={"column"} width={"100%"} marginTop={2}>
                                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                        <Typography variant="subtitle1" fontWeight={600}>Your Ticket</Typography>
                                        <Chip
                                            variant={"outlined"}
                                            label={getEventTicketStatusText(ticket)}
                                            color={getEventTicketStatusColor(ticket) as any}
                                            icon={getEventTicketStatusIcon(ticket)}
                                            size={"small"}
                                        />
                                    </Stack>
                                    <Stack direction={"row"} spacing={1} marginTop={2}>
                                        <Button
                                            variant="outlined"
                                            color="success"
                                            fullWidth
                                            startIcon={<DownloadOutlined/>}
                                            size={"small"}
                                            disabled={
                                                !(
                                                    ticket.status === "PAYMENT_SUCCESS"
                                                    || ticket.status === "VALIDATED"
                                                )
                                            }
                                            onClick={() => generatePdf({
                                                event,
                                                ticket,
                                                eventHost,
                                                user
                                            })}
                                        >
                                            <Typography
                                                variant={"button"}
                                                lineHeight={"1em"}
                                                fontSize={"1em"}
                                                textAlign={"center"}
                                            >
                                                Download PDF
                                            </Typography>
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            color="error"
                                            fullWidth
                                            startIcon={<DeleteOutline/>}
                                            size={"small"}
                                            disabled={
                                                ticket.status !== "PAYMENT_SUCCESS"
                                                || new Date(event.endDate) < new Date()
                                            }
                                            component={Link}
                                            to={`/events/checkout/${ticket.id}/cancel`}
                                        >
                                            <Typography
                                                variant={"button"}
                                                lineHeight={"1em"}
                                                fontSize={"1em"}
                                                textAlign={"center"}
                                            >
                                                Cancel Ticket
                                            </Typography>
                                        </Button>
                                    </Stack>
                                    <Stack direction={"row"} spacing={1} marginTop={1} marginBottom={1}>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            size={"small"}
                                            fullWidth
                                            component={Link}
                                            to={`/events/detail/${event.id}`}
                                            startIcon={<EventOutlined/>}
                                        >
                                            <Typography
                                                variant={"button"}
                                                lineHeight={"1em"}
                                                fontSize={"1em"}
                                                textAlign={"center"}
                                            >
                                                View Event
                                            </Typography>
                                        </Button>
                                        {
                                            listReviews.isLoading &&
                                            <Skeleton variant="rectangular" height={36} width={120} />
                                        }
                                        {
                                            !listReviews.isLoading &&
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                size={"small"}
                                                fullWidth
                                                component={Link}
                                                to={`/events/review/${ticket.id}`}
                                                startIcon={<RateReviewOutlined/>}
                                                disabled={
                                                    event.status !== "FINISHED"
                                                    || ticket.status !== "VALIDATED"
                                                    || listReviews.data?.data.length !== 0
                                                }
                                            >
                                                <Typography
                                                    variant={"button"}
                                                    lineHeight={"1em"}
                                                    fontSize={"1em"}
                                                    textAlign={"center"}
                                                >
                                                    Rate Event
                                                </Typography>
                                            </Button>
                                        }
                                    </Stack>
                                </Stack>
                            }
                            {
                                !eventHost &&
                                <Stack direction={"column"} width={"100%"} marginTop={2}>
                                    <Skeleton variant="rounded" height={120} width={"100%"} />
                                </Stack>
                            }
                        </Grid>
                    </Grid>
                </EventWithTicketCard>
                <EventWithTicketRightBlob
                    blobBackgroundColor={blobBackgroundColor}
                />
            </EventWithTicketContainer>
        </Grid>
    )
}
