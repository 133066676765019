import React from "react"
import { useDefaultBackground } from "../common/hooks/backgroundHooks"
import { Link, useParams } from "react-router-dom"
import { useGetEventQuery } from "../store/api"
import { skipToken } from "@reduxjs/toolkit/query"
import { LoadingBackdrop } from "../common/components/LoadingBackdrop"
import { ErrorBackdrop } from "../common/components/ErrorBackdrop"
import Header from "../common/components/header/Header"
import { Button, Card, CardContent, Grid, Stack, Typography } from "@mui/material"
import LabeledFormSection from "../common/components/form/LabeledFormSection"
import QRCode from "react-qr-code"
import { makeUrl } from "../common/utils/urlUtils"
import {
    CopyAllOutlined,
    DownloadOutlined,
    EmailOutlined,
    FacebookOutlined,
    PhonelinkRing, Telegram,
    Twitter, WhatsApp
} from "@mui/icons-material"
import {
    EmailShareButton,
    FacebookShareButton,
    TelegramShareButton,
    TwitterShareButton, ViberShareButton, WhatsappShareButton
} from "react-share"

export const HostPromoteEvent: React.FC = ({ }) => {
    useDefaultBackground()
    const { eventId } = useParams()
    const getEvent = useGetEventQuery(
        eventId
            ? {
                id: eventId
            }
            : skipToken
    );

    const generateLink = () => {
        const location = window.location
        return makeUrl(
            location.origin,
            "events",
            "detail",
            getEvent.data?.data.id ?? ""
        )
    }

    return (
        <>
            <LoadingBackdrop
                open={
                    getEvent.isLoading
                }
            />

            <ErrorBackdrop
                errors={[
                    getEvent.error
                ]}
            />

            <Header logoStyle={"dark"} hasSearch={false} hasAvatar={false} />

            <Grid container spacing={3} marginTop={4}>
                <Grid item xs={2}></Grid>
                <Grid item xs={8}>
                    <LabeledFormSection
                        title={"Promote Event"}
                        child={
                            <Typography>
                                Promote Event "{getEvent.data?.data.name}".
                            </Typography>
                        }
                    />
                    <LabeledFormSection
                        title={"Share Event with QR Code"}
                        child={
                            <Card variant={"outlined"}>
                                <Grid container>
                                    <Grid xs={12} md={3} item padding={4}>
                                        <QRCode
                                            id={"qr-code"}
                                            value={generateLink()}
                                            style={{ width: "164px", height: "164px" }}
                                        />
                                    </Grid>
                                    <Grid xs={12} md={9} item padding={4}>
                                        <Typography variant={"h6"} fontWeight={700}>
                                            {getEvent.data?.data.name}
                                        </Typography>
                                        <Typography variant={"body1"}>
                                            Share this QR Code with your friends!
                                        </Typography>
                                        <Stack direction={"row"} marginTop={2}>
                                            <Button
                                                variant={"outlined"}
                                                size={"small"}
                                                sx={{
                                                    minWidth: "fit-content",
                                                    minHeight: "fit-content",
                                                    paddingTop: "8px",
                                                    paddingBottom: "8px",
                                                    marginRight: "16px"
                                                }}
                                                onClick={() => {
                                                    navigator.clipboard.writeText(generateLink())
                                                }}
                                            >
                                                <CopyAllOutlined />
                                            </Button>
                                            <Link to={`/events/detail/${getEvent.data?.data.id}`} style={{ margin: "auto 0" }}>
                                                <Typography textTransform={"none"} color={"black"} variant={"subtitle2"}>{generateLink()}</Typography>
                                            </Link>
                                        </Stack>
                                        <Stack direction={"row"} marginTop={1}>
                                            <Button
                                                variant={"outlined"}
                                                size={"small"}
                                                sx={{
                                                    minWidth: "fit-content",
                                                    minHeight: "fit-content",
                                                    paddingTop: "8px",
                                                    paddingBottom: "8px",
                                                    marginRight: "16px"
                                                }}
                                                onClick={() => {
                                                    const qrCodeSvg = document.getElementById("qr-code")
                                                    if(!qrCodeSvg) {
                                                        return
                                                    }

                                                    const svgData = new XMLSerializer().serializeToString(qrCodeSvg as Node)

                                                    const canvas = document.createElement("canvas")
                                                    canvas.width = 300
                                                    canvas.height = 300

                                                    const ctx = canvas.getContext("2d")
                                                    const img = new Image()

                                                    img.src = "data:image/svg+xml;base64," + btoa(svgData)
                                                    img.onload = () => {
                                                        ctx?.drawImage(img, 0, 0, 300, 300)
                                                        canvas.toBlob((blob) => {
                                                            const downloadLink = document.createElement("a")
                                                            downloadLink.download = `${getEvent.data?.data.name}.png`
                                                            downloadLink.href = URL.createObjectURL(blob!)
                                                            downloadLink.click()
                                                        })
                                                    }
                                                }}
                                                >
                                                <DownloadOutlined />
                                            </Button>
                                            <Typography variant={"subtitle2"} style={{ margin: "auto 0" }}>Download QR Code</Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Card>
                        }
                    />
                    <LabeledFormSection
                        title={"Share Event with Social Media"}
                        child={
                            <Card variant={"outlined"}>
                                <CardContent>
                                    <Typography variant={"h6"} fontWeight={700}>
                                        Share Event "{getEvent.data?.data.name}" with your friends on social media!
                                    </Typography>
                                    <Grid container spacing={2} marginTop={1}>
                                        <Grid item xs={12} md={4}>
                                            <EmailShareButton
                                                url={generateLink()}
                                                subject={getEvent.data?.data.name}
                                                body={`Check out this event!`}
                                                style={{ width: "100%" }}
                                            >
                                                <Button
                                                    variant={"outlined"}
                                                    sx={{ width: "100%" }}
                                                    startIcon={<EmailOutlined />}
                                                >
                                                    Share via Email
                                                </Button>
                                            </EmailShareButton>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FacebookShareButton
                                                url={generateLink()}
                                                hashtag={"#LiveLocalLife"}
                                                style={{ width: "100%" }}
                                            >
                                                <Button
                                                    variant={"outlined"}
                                                    sx={{ width: "100%" }}
                                                    startIcon={<FacebookOutlined />}
                                                >
                                                    Share via Facebook
                                                </Button>
                                            </FacebookShareButton>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TwitterShareButton
                                                url={generateLink()}
                                                style={{ width: "100%" }}
                                                title={getEvent.data?.data.name}
                                                hashtags={["LiveLocalLife"]}
                                            >
                                                <Button
                                                    variant={"outlined"}
                                                    sx={{ width: "100%" }}
                                                    startIcon={<Twitter />}
                                                >
                                                    Share via Twitter
                                                </Button>
                                            </TwitterShareButton>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TelegramShareButton
                                                url={generateLink()}
                                                title={getEvent.data?.data.name}
                                                style={{ width: "100%" }}
                                            >
                                                <Button
                                                    variant={"outlined"}
                                                    sx={{ width: "100%" }}
                                                    startIcon={<Telegram />}
                                                >
                                                    Share via Telegram
                                                </Button>
                                            </TelegramShareButton>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <WhatsappShareButton
                                                url={generateLink()}
                                                title={getEvent.data?.data.name}
                                                style={{ width: "100%" }}
                                            >
                                                <Button
                                                    variant={"outlined"}
                                                    sx={{ width: "100%" }}
                                                    startIcon={<WhatsApp />}
                                                >
                                                    Share via WhatsApp
                                                </Button>
                                            </WhatsappShareButton>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <ViberShareButton
                                                url={generateLink()}
                                                title={getEvent.data?.data.name}
                                                style={{ width: "100%" }}
                                            >
                                                <Button
                                                    variant={"outlined"}
                                                    sx={{ width: "100%" }}
                                                    startIcon={<PhonelinkRing />}
                                                >
                                                    Share via Viber
                                                </Button>
                                            </ViberShareButton>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        }
                    />
                </Grid>
                <Grid item xs={2}></Grid>
            </Grid>
        </>
    )
}
