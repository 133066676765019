import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface EventListFiltersState {
    attributeIds: string[];
}

const initialState: EventListFiltersState = {
    attributeIds: []
};

const eventListFiltersSlice = createSlice({
    name: 'eventListFilters',
    initialState,
    reducers: {
        setEventListFilters: (state, action: PayloadAction<string[]>) => {
            state.attributeIds = action.payload;
        }
    }
});

export const { setEventListFilters } = eventListFiltersSlice.actions;
export default eventListFiltersSlice.reducer;
