import { EventHost, useGetRatingQuery, useListReviewsQuery } from "../../../store/api"
import { Card, Grid, Pagination, Paper, Rating, Stack, Typography } from "@mui/material"
import { EventReviewCard, EventReviewCardPlaceholder } from "../event/review/EventReviewCard"
import React from "react"

export const HostEventReviewListCard: React.FC<{
    eventHost: EventHost,
    pagination?: boolean
}> = ({
    eventHost,
    pagination = false
}) => {
    const paginationSize = 6
    const [paginationPage, setPaginationPage] = React.useState(0)

    const getRating = useGetRatingQuery({
        eventHostId: eventHost.id!
    })

    const listReviews = useListReviewsQuery({
        eventHostId: eventHost.id,
        order: ["CREATED_DATE:DESC"],
        size: paginationSize,
        page: paginationPage
    })

    const paginationCount = Math.ceil((listReviews.data?.count ?? 0) / paginationSize)

    return (
        <Card variant="outlined">
            <Stack padding={4}>
                <Grid item container>
                    <Typography variant="h5" fontWeight={700}>Reviews</Typography>
                </Grid>
                {
                    !getRating.isLoading
                    && <Stack direction="row" alignItems="center" marginTop={1}>
                        <Stack direction="column">
                            <Stack direction="row" alignItems="center">
                                <Typography variant="h4" fontWeight={700}>{getRating.data?.data?.rating}</Typography>
                                <Typography variant="h5" marginLeft={1}>/ 5</Typography>
                                <div style={{ width: "2em" }} />
                                <Rating
                                    value={getRating.data?.data?.rating}
                                    size={"large"}
                                    readOnly
                                />
                            </Stack>
                            <Typography variant="body1">{listReviews.data?.count ?? 0} reviews</Typography>
                        </Stack>
                    </Stack>
                }
                <Grid item marginTop={1} container spacing={2}>
                    {
                        listReviews.isLoading &&
                        [1, 2, 3]
                            .map(i => (
                                <Grid item xs={12} md={4} key={`event-${i}`} marginBottom={1}>
                                    <EventReviewCardPlaceholder/>
                                </Grid>
                            ))
                    }
                    {
                        !listReviews.isLoading &&
                        listReviews.data?.data
                            .map((review, i) => (
                                <Grid item xs={12} md={4} key={`review-${review.id}`} marginBottom={1}>
                                    <EventReviewCard review={review} />
                                </Grid>
                            ))
                    }
                </Grid>
                {
                    !listReviews.isLoading &&
                    listReviews.data?.data.length === 0 &&
                    <Typography variant="body1">No reviews to show</Typography>
                }
                {
                    !listReviews.isLoading &&
                    pagination &&
                    <Stack
                        marginTop={2}
                        justifyContent="center"
                        alignItems="center"
                        direction="row"
                    >
                        <Pagination
                            variant="outlined"
                            count={paginationCount}
                            page={paginationPage + 1}
                            onChange={(e, page) => setPaginationPage(page - 1)}
                        />
                    </Stack>
                }
            </Stack>
        </Card>
    )
}
