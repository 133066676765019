import { Backdrop, Grid, Stack, styled, Typography } from "@mui/material"
import React from "react"
import { SerializedError } from "@reduxjs/toolkit"
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query/fetchBaseQuery"
import { ErrorOutline } from "@mui/icons-material"

const StyledErrorBackdrop = styled(Backdrop)`
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
`;


export const ErrorBackdrop: React.FC<{ errors: (SerializedError | FetchBaseQueryError | undefined)[] }> = ({ errors }) => {
    const serializedErrors = errors
        .filter((error) => error)
        .filter((error) => 'message' in error!) as SerializedError[];

    const fetchBaseQueryErrors = errors
        .filter((error) => error)
        .filter((error) => 'status' in error!) as FetchBaseQueryError[];

    const hasErrors = serializedErrors.length > 0 || fetchBaseQueryErrors.length > 0;

    return (
        <StyledErrorBackdrop open={hasErrors} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Stack width={"100%"}>
                <Grid container item padding={4}>
                    <Typography alignSelf={"center"}><ErrorOutline color={"error"} fontSize={"large"} /></Typography>
                    <Typography color={"error"} variant={"h4"} marginLeft={2}>Error</Typography>
                </Grid>
                {
                    serializedErrors.map((error) => (
                        <Typography
                            key={error.message}
                            variant={"subtitle1"}
                            color={"black"}
                            padding={4}
                        >
                            {`General error: ${error.message}`}
                        </Typography>
                    ))
                }
                {
                    fetchBaseQueryErrors.map((error) => (
                        <Typography
                            key={error.status}
                            variant={"subtitle1"}
                            color={"black"}
                            padding={4}
                        >
                            {`Network error, status: ${error.status}, data: ${JSON.stringify(error.data)}`}
                        </Typography>
                    ))
                }
            </Stack>
        </StyledErrorBackdrop>
    );
}
