import React, { useEffect } from "react";
import Header from "../../../common/components/header/Header";
import { Button, Grid, Stack, Typography } from "@mui/material";
import {
    EmailOutlined,
    FacebookOutlined,
    FlagOutlined,
    LocationOn,
    LocationOnOutlined, PhonelinkRing, Telegram,
    Twitter, WhatsApp
} from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { Event, useGetEventHostQuery, useGetEventQuery, useGetUserQuery } from "../../../store/api";
import { formatDate, getAddressString } from "../../../common/components/event/eventUtils";
import { CheckoutButton, EventDetailPaper, EventDetailPaperTitle, EventDetailTitle } from "../eventStyles";
import { AttributesGrid } from "../../../common/components/attribute/AttributesGrid";
import { EventAddressMap } from "../../../common/components/event/EventAddressMap";
import store from "../../../store/store";
import { setBackground } from "../../../store/backgroundSlice";
import { makeUrl } from "../../../common/utils/urlUtils";
import { PhoneChip } from "../../../common/components/contact/PhoneChip";
import { EmailChip } from "../../../common/components/contact/EmailChip";
import { PersonChip } from "../../../common/components/contact/PersonChip";
import { LinkChip } from "../../../common/components/contact/LinkChip";
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import {
    EmailShareButton,
    FacebookShareButton,
    TelegramShareButton,
    TwitterShareButton, ViberShareButton,
    WhatsappShareButton
} from "react-share"
import { useGetImageQuery } from "../../../store/fileApi"
import { skipToken } from "@reduxjs/toolkit/query"

const EventDetailRenderer: React.FC<{ event: Event }> = ({ event }) => {
    const eventHostFetcher = useGetEventHostQuery({ id: event.eventHostId });
    const getUser = useGetUserQuery(
        eventHostFetcher.data?.data?.userId
            ? {
                id: eventHostFetcher.data.data.userId
            }
            : skipToken
    )

    const navigate = useNavigate();

    const handleCheckout = () => {
        navigate(`/events/checkout/${event.id}`);
    };

    const coverImage = useGetImageQuery(
        event.coverPicture?.id
            ? {
                id: event.coverPicture.id,
                quality: 0.8,
                maxWidth: 1500
            }
            : skipToken
    )

    useEffect(() => {
        store.dispatch(
            setBackground({
                value: coverImage.data
                    ? {
                        type: "start-end-image",
                        image: coverImage.data,
                        height: "75vh"
                    }
                    : {
                        type: "fill-color",
                        color: "rgba(5,24,40,0.77)",
                        height: "75vh"
                    },
                overlay: {
                    type: "start-end-linear-gradient",
                    direction: "to bottom",
                    startColor: "rgba(245, 245, 245, 0)",
                    endColor: "rgba(245, 245, 245, 1)",
                    top: "30vh",
                    height: "45.5vh"
                }
            })
        );
    }, [coverImage]);

    const generateLink = () => {
        const location = window.location
        return makeUrl(
            location.origin,
            "events",
            "detail",
            event.id ?? ""
        )
    }

    const eventHost = eventHostFetcher.data?.data
    const user = getUser.data?.data

    return (
        <div>
            <Header logoStyle={"light"} hasSearch={false} hasAvatar={true}/>
            <Grid container spacing={2} marginTop={"3rem"}>
                <Grid item xs={9}>
                    <EventDetailTitle>
                        {event.name}
                    </EventDetailTitle>
                </Grid>
                <Grid item xs={3} alignSelf={"flex-end"}>
                    <EventDetailPaper>
                        <EventDetailPaperTitle gutterBottom>
                            Date and Time
                        </EventDetailPaperTitle>
                        <Stack spacing={1}>
                            <Stack direction={"row"} spacing={1}>
                                <LocationOnOutlined fontSize="small"/>
                                <Typography variant={"body2"}>
                                    {formatDate(event.startDate)}
                                </Typography>
                            </Stack>
                            <Stack direction={"row"} spacing={1}>
                                <FlagOutlined fontSize="small"/>
                                <Typography variant={"body2"}>
                                    {formatDate(event.endDate)}
                                </Typography>
                            </Stack>
                        </Stack>
                    </EventDetailPaper>
                </Grid>
                <Grid item xs={12}>
                    <EventDetailPaper>
                        <EventDetailPaperTitle variant={"subtitle1"}>
                            Event description
                        </EventDetailPaperTitle>
                        <Typography variant={"body1"}>
                            {event.description}
                        </Typography>
                    </EventDetailPaper>
                </Grid>
            </Grid>
            <Grid container spacing={4} style={{ padding: "20px 0" }}>
                <Grid item xs={6}>
                    <Stack spacing={4}>
                        <EventDetailPaper>
                            <Stack spacing={1}>
                                <EventDetailPaperTitle gutterBottom>Event organizer</EventDetailPaperTitle>
                                {
                                    user &&
                                    eventHost &&
                                    <Grid>
                                        <PhoneChip phone={user.phone}/>
                                        <EmailChip email={user.email}/>
                                        <PersonChip
                                            firstName={user.firstName}
                                            lastName={user.lastName}
                                        />
                                        {
                                            eventHost.contact.links
                                                ?.map((link, i) => (
                                                    <LinkChip
                                                        key={i}
                                                        title={link.title}
                                                        value={link.value}
                                                    />
                                                ))
                                        }
                                    </Grid>
                                }
                            </Stack>
                        </EventDetailPaper>
                        <EventDetailPaper>
                            <Stack>
                                <EventDetailPaperTitle>
                                    Share
                                </EventDetailPaperTitle>
                                <Grid container spacing={2} marginTop={1}>
                                    <Grid item xs={12} md={6}>
                                        <EmailShareButton
                                            url={generateLink()}
                                            subject={event.name}
                                            body={`Check out this event!`}
                                            style={{ width: "100%" }}
                                        >
                                            <Button
                                                variant={"outlined"}
                                                sx={{ width: "100%" }}
                                                startIcon={<EmailOutlined/>}
                                            >
                                                Share via Email
                                            </Button>
                                        </EmailShareButton>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FacebookShareButton
                                            url={generateLink()}
                                            hashtag={"#LiveLocalLife"}
                                            style={{ width: "100%" }}
                                        >
                                            <Button
                                                variant={"outlined"}
                                                sx={{ width: "100%" }}
                                                startIcon={<FacebookOutlined/>}
                                            >
                                                Share via Facebook
                                            </Button>
                                        </FacebookShareButton>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TwitterShareButton
                                            url={generateLink()}
                                            style={{ width: "100%" }}
                                            title={event.name}
                                            hashtags={["LiveLocalLife"]}
                                        >
                                            <Button
                                                variant={"outlined"}
                                                sx={{ width: "100%" }}
                                                startIcon={<Twitter/>}
                                            >
                                                Share via Twitter
                                            </Button>
                                        </TwitterShareButton>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TelegramShareButton
                                            url={generateLink()}
                                            title={event.name}
                                            style={{ width: "100%" }}
                                        >
                                            <Button
                                                variant={"outlined"}
                                                sx={{ width: "100%" }}
                                                startIcon={<Telegram/>}
                                            >
                                                Share via Telegram
                                            </Button>
                                        </TelegramShareButton>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <WhatsappShareButton
                                            url={generateLink()}
                                            title={event.name}
                                            style={{ width: "100%" }}
                                        >
                                            <Button
                                                variant={"outlined"}
                                                sx={{ width: "100%" }}
                                                startIcon={<WhatsApp/>}
                                            >
                                                Share via WhatsApp
                                            </Button>
                                        </WhatsappShareButton>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <ViberShareButton
                                            url={generateLink()}
                                            title={event.name}
                                            style={{ width: "100%" }}
                                        >
                                            <Button
                                                variant={"outlined"}
                                                sx={{ width: "100%" }}
                                                startIcon={<PhonelinkRing/>}
                                            >
                                                Share via Viber
                                            </Button>
                                        </ViberShareButton>
                                    </Grid>
                                </Grid>
                            </Stack>

                        </EventDetailPaper>
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Stack spacing={4}>
                        <EventDetailPaper>
                            <EventDetailPaperTitle variant={"subtitle1"}>
                                Location
                            </EventDetailPaperTitle>
                            <Stack spacing={1}>
                                <Stack direction={"row"} spacing={1}>
                                    <LocationOn fontSize="small"/>
                                    <Typography variant={"body2"}>
                                        {getAddressString(event.address)}
                                    </Typography>
                                </Stack>
                                <div style={{ display: "block", height: "300px", width: "100%" }}>
                                    <EventAddressMap address={event.address}/>
                                </div>
                            </Stack>
                        </EventDetailPaper>
                        <EventDetailPaper>
                            <EventDetailPaperTitle variant={"subtitle1"}>
                                Important to know
                            </EventDetailPaperTitle>
                            <AttributesGrid attributes={event.attributes}/>
                        </EventDetailPaper>
                        {
                            event.status === "CREATED" &&
                            event.totalTicketCount > 0 &&
                            <CheckoutButton onClick={() => handleCheckout()}>
                                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                    <ShoppingCartOutlinedIcon/>
                                    <Typography variant={"inherit"}>
                                        Checkout
                                    </Typography>
                                </Stack>
                            </CheckoutButton>
                        }
                    </Stack>
                </Grid>
            </Grid>
        </div>
    );
}

const EventFetcher: React.FC<{ eventId: string }> = ({ eventId }) => {
    const eventFetch = useGetEventQuery({ id: eventId });
    const event = eventFetch.data?.data
    return (
        <div>
            {event && <EventDetailRenderer event={event}/>}
        </div>
    )
}

const EventDetail: React.FC = () => {
    const { eventId } = useParams()
    if (!eventId)
        return <div>no id provided</div>
    return <EventFetcher eventId={eventId}/>
};

export default EventDetail;
