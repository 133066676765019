import React from "react"
import { useDefaultBackground } from "../common/hooks/backgroundHooks"
import { LoadingBackdrop } from "../common/components/LoadingBackdrop"
import { ErrorBackdrop } from "../common/components/ErrorBackdrop"
import Header from "../common/components/header/Header"
import { DataGridPremium, GridColDef, GridToolbar, LicenseInfo } from '@mui/x-data-grid-premium';
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Dialog, DialogActions, DialogContent, DialogContentText,
    DialogTitle,
    LinearProgress,
    Stack, TextField,
    Typography
} from "@mui/material"
import {
    EventHost,
    EventHostPhone,
    useConfirmEventHostMutation,
    useListEventHostsQuery,
    useRejectEventHostMutation
} from "../store/api"
import { getPhoneString } from "../common/components/host/hostUtils"
import { getAddressString } from "../common/components/event/eventUtils"
import { Link } from "react-router-dom"

LicenseInfo.setLicenseKey("c11f7e3c241021d6ddfb9bb8a0ffb32bTz0xMDEyMjMsRT0yMDE3NzMwNjkyMTkxLFM9cHJlbWl1bSxMTT1wZXJwZXR1YWwsS1Y9Mg==")

type DialogState =
    | {
        open: false
    }
    | {
        open: true
        type: "APPROVE"
        eventHost: EventHost
    }
    | {
        open: true
        type: "REJECT"
        eventHost: EventHost
    }

const getDialogTitleForState = (state: DialogState) => {
    if (!state.open) {
        return ""
    }

    switch (state.type) {
        case "APPROVE":
            return `Approve Event Host ${state.eventHost.name}`
        case "REJECT":
            return `Reject Event Host ${state.eventHost.name}`
    }
}

const getDialogDescriptionForState = (state: DialogState) => {
    if (!state.open) {
        return ""
    }

    switch (state.type) {
        case "APPROVE":
            return `Are you sure you want to approve ${state.eventHost.name}? If you want to approve this event host, please provide user id from keycloak.`
        case "REJECT":
            return `Are you sure you want to reject ${state.eventHost.name}?`
    }
}

export const AdminHome: React.FC = () => {
    useDefaultBackground()

    const [pagination, setPagination] = React.useState({
        page: 0,
        size: 16
    })

    const [dialogState, setDialogState] = React.useState<DialogState>({
        open: false
    })

    const [userId, setUserId] = React.useState<string>("")

    const listEventHostsPendingApproval = useListEventHostsQuery({
        page: pagination.page,
        size: pagination.size,
        status: "PENDING_APPROVAL"
    })

    const [confirmEventHost, confirmEventHostStatus] = useConfirmEventHostMutation()

    const [rejectEventHost, rejectEventHostStatus] = useRejectEventHostMutation()

    const eventHosts = listEventHostsPendingApproval.data?.data ?? []

    const eventHostsDataGridColumns: GridColDef[] = [
        { field: 'id', headerName: 'ID', resizable: true, width: 200 },
        { field: 'name', headerName: 'Name', resizable: true, width: 200 },
        {
            field: 'email',
            headerName: 'Email',
            resizable: true,
            width: 200,
            renderCell: (params) => {
                const email = params.value as string
                return (
                    <Link to={`mailto:${email}`} style={{ textDecoration: 'none', color: "#000" }}>
                        {email}
                    </Link>
                )
            }
        },
        {
            field: 'phoneNumber',
            headerName: 'Phone Number',
            resizable: true,
            width: 200,
            renderCell: (params) => {
                const phone = params.value as EventHostPhone
                return (
                    <Link to={`tel:${getPhoneString(phone)}`} style={{ textDecoration: 'none', color: "#000" }}>
                        {getPhoneString(phone)}
                    </Link>
                )
            }
        },
        { field: 'address', headerName: 'Address', resizable: true, width: 200 },
        { field: 'description', headerName: 'Description', resizable: true, width: 200 },
        { field: 'status', headerName: 'Status', resizable: true },
        {
            field: 'actions',
            headerName: 'Actions',
            resizable: true,
            width: 240,
            renderCell: (params) => {
                const eventHost = params.value as EventHost
                return (
                    <>
                        {
                            eventHost.status === "PENDING_APPROVAL" &&
                            <Stack direction="row" spacing={2}>
                                <Button
                                    variant="outlined"
                                    color="success"
                                    onClick={() => setDialogState({
                                        open: true,
                                        type: "APPROVE",
                                        eventHost: eventHost
                                    })}
                                >
                                    <Typography variant={"body2"} fontWeight={600}>Approve</Typography>
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="error"
                                    onClick={() => setDialogState({
                                        open: true,
                                        type: "REJECT",
                                        eventHost: eventHost
                                    })}
                                >
                                    <Typography variant={"body2"} fontWeight={600}>Reject</Typography>
                                </Button>
                            </Stack>
                        }
                    </>
                )
            }
        }
    ]

    const eventHostsDataGridRows = eventHosts.map(eventHost => {
        return {
            id: eventHost.id,
            name: eventHost.name,
            email: eventHost.contact.email,
            phoneNumber: eventHost.contact.phone,
            address: getAddressString(eventHost.address),
            description: eventHost.description,
            status: eventHost.status,
            actions: eventHost
        }
    })

    return (
        <>
            <LoadingBackdrop
                open={
                    false
                }
            />
            <ErrorBackdrop
                errors={[
                    listEventHostsPendingApproval.error
                ]}
            />

            <Header logoStyle={"dark"} hasSearch={false} hasAvatar={true}/>

            <div style={{ height: "2em" }}/>
            <Card variant={"outlined"}>
                <CardHeader title={<Typography variant={"h6"} fontWeight={600}>Event Host Requests</Typography>}/>
                <CardContent>
                    {
                        <DataGridPremium
                            autoHeight
                            loading={
                                listEventHostsPendingApproval.isLoading
                                || listEventHostsPendingApproval.isFetching
                            }
                            slots={{
                                loadingOverlay: LinearProgress,
                                toolbar: GridToolbar
                            }}
                            columns={eventHostsDataGridColumns}
                            rows={eventHostsDataGridRows}
                            pagination
                            pageSizeOptions={[16, 32, 64]}
                            paginationModel={{
                                pageSize: pagination.size,
                                page: pagination.page
                            }}
                            onPaginationModelChange={(newPagination) => {
                                setPagination({
                                    page: newPagination.page,
                                    size: newPagination.pageSize
                                })
                            }}
                        />
                    }
                </CardContent>
            </Card>

            <Dialog
                open={dialogState.open}
                onClose={() => setDialogState({ open: false })}
            >
                <DialogTitle>{getDialogTitleForState(dialogState)}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{getDialogDescriptionForState(dialogState)}</DialogContentText>
                    {
                        dialogState.open &&
                        dialogState.type === "APPROVE" &&
                        <TextField
                            autoFocus
                            margin="dense"
                            label="User ID"
                            type="text"
                            fullWidth
                            value={userId}
                            onChange={(e) => setUserId(e.target.value)}
                        />
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogState({ open: false })}>Cancel</Button>
                    {
                        dialogState.open &&
                        dialogState.type === "APPROVE" &&
                        <Button
                            disabled={userId === ""}
                            onClick={() => {
                                setDialogState({ open: false })
                                setUserId("")
                                confirmEventHost({
                                    id: dialogState.eventHost.id!,
                                    confirmEventHostRequest: {
                                        userId: userId
                                    }
                                })
                                    .unwrap()
                                    .then(() => {
                                        listEventHostsPendingApproval.refetch()
                                    })
                                    .catch((error) => {
                                        console.log(error)
                                    })
                            }}
                        >
                            Approve
                        </Button>
                    }
                    {
                        dialogState.open &&
                        dialogState.type === "REJECT" &&
                        <Button
                            onClick={() => {
                                setDialogState({ open: false })
                                rejectEventHost({
                                    id: dialogState.eventHost.id!
                                })
                                    .unwrap()
                                    .then(() => {
                                        listEventHostsPendingApproval.refetch()
                                    })
                                    .catch((error) => {
                                        console.log(error)
                                    })
                            }}
                        >
                            Reject
                        </Button>
                    }
                </DialogActions>
            </Dialog>
        </>
    )
}
