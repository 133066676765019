import React, {SetStateAction, useEffect} from "react";
import {FormControlLabel, Radio, RadioGroup} from "@mui/material";


export interface PaymentCheckoutState {
    paymentMethod: string,
    error?: string
}

const CheckoutPaymentSection: React.FC<{
    setPaymentCheckoutState: React.Dispatch<SetStateAction<PaymentCheckoutState>>,
    disabled: boolean
}> = (
    {setPaymentCheckoutState, disabled}
) => {
    const [paymentMethod, setPaymentMethod] = React.useState('');
    useEffect(() => {
        setPaymentCheckoutState(() => {
            let error = undefined
            if (!paymentMethod)
                error = 'choose a payment method!'
            return {
                paymentMethod: paymentMethod,
                error: error
            }
        })
    }, [paymentMethod, setPaymentCheckoutState]);
    return (
        <>
            <RadioGroup
                style={{marginLeft: '1rem'}}
                name="payment-selection"
                value={paymentMethod}
                onChange={(event) => {
                    setPaymentMethod(event.target.value);
                }}
            >
                <FormControlLabel value="blik" control={<Radio />} label="Blik" disabled={disabled} />
                <FormControlLabel value="payu" control={<Radio />} label="PayU" disabled={disabled} />
                <FormControlLabel value="google-pay" control={<Radio />} label="Google Pay" disabled={disabled} />
                <FormControlLabel value="apple-pay" control={<Radio />} label="Apple Pay" disabled={disabled} />
            </RadioGroup>
        </>
    )
}

export default CheckoutPaymentSection