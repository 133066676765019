import React, { useEffect, useMemo, useState } from "react";
import Header from "../common/components/header/Header";
import { Card, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import LabeledFormSection from "../common/components/form/LabeledFormSection";
import { useDefaultBackground } from "../common/hooks/backgroundHooks"
import {
    EventTicket, Event,
    useGetAttributeGroupsQuery,
    useGetEventHostSelfQuery, useGetEventQuery, useMutateEventMutation, MutateEventRequest
} from "../store/api"
import { FormAttributeSelectorsSection } from "../common/components/form/FormAttributeSelectorsSection"
import { CoverPicture } from "../common/components/form/CoverPicture"
import { AdvancedMarker, Map, Pin } from "@vis.gl/react-google-maps"
import { googleMapsMapId } from "../common/constants"
import { FormTextFieldWithValidation } from "../common/components/form/FormTextFieldWithValidation"
import { LoadingBackdrop } from "../common/components/LoadingBackdrop"
import { ErrorBackdrop } from "../common/components/ErrorBackdrop"
import {
    ConfirmButton,
    TicketingAddButton,
    TicketingDeleteButton
} from "./hostStyles"
import AddIcon from "@mui/icons-material/Add"
import { DeleteOutline } from "@mui/icons-material"
import {
    RequestField,
    RequestFieldAttributes,
    RequestFieldCoverPicture,
    RequestFieldValidation
} from "../common/components/form/formTypes"
import { FormDateTimePickerWithValidation } from "../common/components/form/FormDateTimePickerWithValidation"
import { useUploadFileMutation } from "../store/fileApi"
import { makeUrl } from "../common/utils/urlUtils"
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query/fetchBaseQuery"
import { getAddressString } from "../common/components/event/eventUtils"
import { useGetGeocodeQuery } from "../store/geocodeApi"
import { skipToken } from "@reduxjs/toolkit/query"
import { useDebounce } from "use-debounce";
import { findAttributeGroupForAttribute } from "../common/components/attribute/attributeUtils"
import { useParams } from "react-router-dom"

interface HostEditEventFieldTicketType {
    isLocked: boolean;
    type: RequestField<string>;
    price: RequestField<number | ''>;
    description: RequestField<string>;
}

interface HostEditEventState {
    event: Event | undefined;

    name: RequestField<string>;
    description: RequestField<string>;
    startDate: RequestField<Date | null>;
    endDate: RequestField<Date | null>;
    attributes: RequestFieldAttributes;
    coverPicture: RequestFieldCoverPicture;

    street: RequestField<string>;
    houseNumber: RequestField<string>;
    postalCode: RequestField<string>;
    city: RequestField<string>;
    country: RequestField<string>;

    totalNumberOfTickets: RequestField<number | ''>;
    ticketTypes: HostEditEventFieldTicketType[];
}

const initialState: HostEditEventState = {
    event: undefined,
    name: {
        value: '',
        validation: { status: 'initial' }
    },
    description: {
        value: '',
        validation: { status: 'initial' }
    },
    startDate: {
        value: null,
        validation: { status: 'initial' }
    },
    endDate: {
        value: null,
        validation: { status: 'initial' }
    },
    attributes: {},

    coverPicture: {
        status: 'initial'
    },

    street: {
        value: '',
        validation: { status: 'initial' }
    },
    houseNumber: {
        value: '',
        validation: { status: 'initial' }
    },
    postalCode: {
        value: '',
        validation: { status: 'initial' }
    },
    city: {
        value: '',
        validation: { status: 'initial' }
    },
    country: {
        value: '',
        validation: { status: 'initial' }
    },

    totalNumberOfTickets: {
        value: 0,
        validation: { status: 'initial' }
    },
    ticketTypes: []
}

const areAllFieldsValid = (state: HostEditEventState) => {
    const allOuterFieldsValid = Object
        .values(state)
        .every((field) =>
            field?.validation?
                field.validation.status === 'valid'
                : true
        );

    const allCoverPictureFieldsValid = state.coverPicture.status === 'valid';

    const allTicketTypesValid = state.ticketTypes
        .every((ticketType) =>
            Object
                .values(ticketType)
                .every((field) =>
                    field.validation?.status
                        ? field.validation.status === 'valid'
                        : true
                )
        );

    const allAttributesValid = Object
        .values(state.attributes)
        .every((attribute) =>
            attribute?.validation.status === 'valid'
        );

    return allOuterFieldsValid
        && allCoverPictureFieldsValid
        && allTicketTypesValid
        && allAttributesValid;
}

const mapStateToMutateEventRequest = (state: HostEditEventState): MutateEventRequest => {
    const attributeIds = Object
        .values(state.attributes)
        .map((attribute) => attribute?.value)
        .filter((attribute) => attribute)
        .map((attribute) => attribute?.id as string) as string[];

    const tickets: EventTicket[] = state.ticketTypes
        .map((ticketType) => ({
            type: ticketType.type.value,
            price: ticketType.price.value as number,
            additionalInformation: ticketType.description.value,
            currencyCode: 'PLN'
        }));

    const coverPicture = state.coverPicture.status === 'valid'
        ? state.coverPicture.value
        : undefined;

    return {
        name: state.name.value,
        description: state.description.value,
        startDate: state.startDate.value!.toISOString(),
        endDate: state.endDate.value!.toISOString(),
        attributeIds: attributeIds,
        coverPicture: coverPicture,
        address: {
            street: state.street.value,
            house: state.houseNumber.value,
            postalCode: state.postalCode.value,
            city: state.city.value,
            country: state.country.value
        },
        totalTicketCount: state.totalNumberOfTickets.value as number,
        tickets: tickets
    }
}

const mapStateToAddressString = (state: HostEditEventState): string | undefined => {
    if(
        state.street.value.length === 0
        && state.houseNumber.value.length === 0
        && state.postalCode.value.length === 0
        && state.city.value.length === 0
        && state.country.value.length === 0
    ) {
        return undefined;
    }

    return getAddressString({
        street: state.street.value,
        house: state.houseNumber.value,
        postalCode: state.postalCode.value,
        city: state.city.value,
        country: state.country.value
    })
}

const HostCreateEvent: React.FC = () => {
    useDefaultBackground()

    const { eventId } = useParams()

    const [hostCreateEventState, setHostCreateEventState] = useState<HostEditEventState>(initialState);
    const [address] = useDebounce(
        mapStateToAddressString(hostCreateEventState),
        250
    )

    const getAttributeGroups = useGetAttributeGroupsQuery();
    const getEventHostSelf = useGetEventHostSelfQuery();
    const getEvent = useGetEventQuery(
        eventId
        ? {
            id: eventId
        }
        : skipToken
    );
    const getGeocode = useGetGeocodeQuery(
        address
            ? {
                address
            }
            : skipToken
    );

    const [uploadFile, uploadFileStatus] = useUploadFileMutation();
    const [mutateEvent, mutateEventStatus] = useMutateEventMutation();

    const currentDateTime = useMemo(() => new Date(), []);

    useEffect(() => {
        if(getEvent.isLoading || getEvent.isError || getEvent.isUninitialized) {
            return;
        }

        if(getAttributeGroups.isLoading || getAttributeGroups.isError || getAttributeGroups.isUninitialized) {
            return;
        }

        const event: Event = getEvent.data!.data;

        const getAttributeGroupsData = getAttributeGroups.data?.data ?? [];
        const eventAttributes = event.attributes;

        setHostCreateEventState({
            event: event,
            name: {
                value: event.name,
                validation: { status: 'valid' }
            },
            description: {
                value: event.description,
                validation: { status: 'valid' }
            },
            startDate: {
                value: new Date(event.startDate),
                validation: { status: 'valid' }
            },
            endDate: {
                value: new Date(event.endDate),
                validation: { status: 'valid' }
            },
            attributes: Object
                .fromEntries(
                    eventAttributes
                        .map((attribute) => ([
                            findAttributeGroupForAttribute(getAttributeGroupsData, attribute)?.id as string,
                            {
                                value: attribute,
                                validation: { status: 'valid' }
                            }
                        ]))
                ),
            coverPicture: {
                status: 'valid',
                value: event.coverPicture!
            },
            street: {
                value: event.address.street,
                validation: { status: 'valid' }
            },
            houseNumber: {
                value: event.address.house,
                validation: { status: 'valid' }
            },
            postalCode: {
                value: event.address.postalCode ?? '',
                validation: { status: 'valid' }
            },
            city: {
                value: event.address.city,
                validation: { status: 'valid' }
            },
            country: {
                value: event.address.country,
                validation: { status: 'valid' }
            },
            totalNumberOfTickets: {
                value: event.totalTicketCount,
                validation: { status: 'valid' }
            },
            ticketTypes: event.tickets
                .map((ticket) => ({
                    isLocked: true,
                    type: {
                        value: ticket.type,
                        validation: { status: 'valid' }
                    },
                    price: {
                        value: ticket.price,
                        validation: { status: 'valid' }
                    },
                    description: {
                        value: ticket.additionalInformation ?? '',
                        validation: { status: 'valid' }
                    }
                }))
        })
    }, [getEvent, getAttributeGroups]);

    if (!eventId) {
        return (<></>)
    }

    return (
        <>
            <LoadingBackdrop
                open={
                    getAttributeGroups.isLoading
                    || getEvent.isLoading
                    || getEventHostSelf.isLoading
                    || uploadFileStatus.isLoading
                }
            />

            <ErrorBackdrop
                errors={[
                    getAttributeGroups.error,
                    getEvent.error,
                    getEventHostSelf.error
                ]}
            />

            <Header logoStyle={"dark"} hasSearch={false} hasAvatar={false} />
            <Grid container spacing={3} marginTop={4}>
                <Grid item xs={2}></Grid>
                <Grid item xs={8}>
                    <Stack>
                        <LabeledFormSection
                            title={"Name"}
                            child={
                                <FormTextFieldWithValidation
                                    placeholder={"Name your event"}
                                    field={hostCreateEventState.name}
                                    onChange={(event) => {
                                        setHostCreateEventState({
                                            ...hostCreateEventState,
                                            name: {
                                                value: event.target.value,
                                                validation: event.target.value.length > 0
                                                    ? { status: 'valid' }
                                                    : { status: 'invalid', message: 'Name cannot be empty' }
                                            }
                                        })
                                    }}
                                />
                            }
                        />

                        <LabeledFormSection
                            title={"Description"}
                            child={
                                <FormTextFieldWithValidation
                                    multiline
                                    rows={4}
                                    placeholder={"Write a description for your event"}
                                    field={hostCreateEventState.description}
                                    onChange={(event) => {
                                        setHostCreateEventState({
                                            ...hostCreateEventState,
                                            description: {
                                                value: event.target.value,
                                                validation: event.target.value.length > 0
                                                    ? { status: 'valid' }
                                                    : { status: 'invalid', message: 'Description cannot be empty' }
                                            }
                                        })
                                    }}
                                />
                            }
                        />

                        <LabeledFormSection
                            title={"Date and Time"}
                            child={
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant={"subtitle1"} marginTop={1}>Start Date and Time</Typography>
                                        <FormDateTimePickerWithValidation
                                            field={hostCreateEventState.startDate}
                                            onChange={(value) => {
                                                let startDateValidation: RequestFieldValidation = { status: 'valid' };
                                                if (value < currentDateTime) {
                                                    startDateValidation = { status: 'invalid', message: 'Start date must be in the future' };
                                                }

                                                if (hostCreateEventState.event?.startDate && value < new Date(hostCreateEventState.event.startDate)) {
                                                    startDateValidation = { status: 'invalid', message: 'Start date must be after previous start date' };
                                                }

                                                if (hostCreateEventState.endDate.value && value > hostCreateEventState.endDate.value) {
                                                    startDateValidation = { status: 'invalid', message: 'Start date must be before end date' };
                                                }

                                                let endDateValidation: RequestFieldValidation = hostCreateEventState.endDate.validation;
                                                if (
                                                    hostCreateEventState.endDate.value
                                                    && value < hostCreateEventState.endDate.value
                                                    && hostCreateEventState.endDate.value > currentDateTime
                                                ) {
                                                    endDateValidation = { status: 'valid' };
                                                }

                                                setHostCreateEventState({
                                                    ...hostCreateEventState,
                                                    startDate: {
                                                        value: value,
                                                        validation: startDateValidation
                                                    },
                                                    endDate: {
                                                        value: hostCreateEventState.endDate.value,
                                                        validation: endDateValidation
                                                    }
                                                })
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant={"subtitle1"} marginTop={1}>End Date and Time</Typography>
                                        <FormDateTimePickerWithValidation
                                            field={hostCreateEventState.endDate}
                                            onChange={(value) => {
                                                let endDateValidation: RequestFieldValidation = { status: 'valid' };

                                                if (value < currentDateTime) {
                                                    endDateValidation = { status: 'invalid', message: 'End date must be in the future' };
                                                }

                                                if (hostCreateEventState.startDate.value && value < hostCreateEventState.startDate.value) {
                                                    endDateValidation = { status: 'invalid', message: 'End date must be after start date' };
                                                }

                                                let startDateValidation: RequestFieldValidation = hostCreateEventState.startDate.validation;

                                                if (
                                                    hostCreateEventState.startDate.value
                                                    && value > hostCreateEventState.startDate.value
                                                    && hostCreateEventState.startDate.value > currentDateTime
                                                ) {
                                                    startDateValidation = { status: 'valid' };
                                                }

                                                setHostCreateEventState({
                                                    ...hostCreateEventState,
                                                    endDate: {
                                                        value: value,
                                                        validation: endDateValidation
                                                    },
                                                    startDate: {
                                                        value: hostCreateEventState.startDate.value,
                                                        validation: startDateValidation
                                                    }
                                                })
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            }
                        />

                        <LabeledFormSection
                            title={"Attributes"}
                            child={
                                <FormAttributeSelectorsSection
                                    attributesRequestField={hostCreateEventState.attributes}
                                    onChange={(attributesRequestField) => {
                                        setHostCreateEventState({
                                            ...hostCreateEventState,
                                            attributes: attributesRequestField
                                        })
                                    }}
                                />
                            }
                        />

                        <LabeledFormSection
                            title={"Cover picture"}
                            child={
                                <CoverPicture
                                    coverPictureState={hostCreateEventState.coverPicture}
                                    uploadFile={({ id, file }) => {
                                        uploadFile({
                                            id: id,
                                            file: file
                                        })
                                            .unwrap()
                                            .then((response) => {
                                                setHostCreateEventState({
                                                    ...hostCreateEventState,
                                                    coverPicture: {
                                                        status: 'valid',
                                                        value: response.data
                                                    }
                                                })
                                            })
                                            .catch((error) => {
                                                console.log(error);
                                            })
                                    }}
                                />
                            }
                        />

                        <LabeledFormSection
                            title={"Location"}
                            child={
                                <>
                                    <Grid item container columnSpacing={2}>
                                        <Grid item xs={6}>
                                            <Typography variant={"subtitle2"} fontWeight={500} marginTop={1}>Street</Typography>
                                            <FormTextFieldWithValidation
                                                placeholder={"Enter street name"}
                                                field={hostCreateEventState.street}
                                                onChange={(event) => {
                                                    setHostCreateEventState({
                                                        ...hostCreateEventState,
                                                        street: {
                                                            value: event.target.value,
                                                            validation: event.target.value.length > 0
                                                                ? { status: 'valid' }
                                                                : { status: 'invalid', message: 'Street cannot be empty' }
                                                        }
                                                    })
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant={"subtitle2"} fontWeight={500} marginTop={1}>House number</Typography>
                                            <FormTextFieldWithValidation
                                                placeholder={"Enter house number"}
                                                field={hostCreateEventState.houseNumber}
                                                onChange={(event) => {
                                                    setHostCreateEventState({
                                                        ...hostCreateEventState,
                                                        houseNumber: {
                                                            value: event.target.value,
                                                            validation: event.target.value.length > 0
                                                                ? { status: 'valid' }
                                                                : { status: 'invalid', message: 'House number cannot be empty' }
                                                        }
                                                    })
                                                }}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item container columnSpacing={2}>
                                        <Grid item xs={6}>
                                            <Typography variant={"subtitle2"} fontWeight={500} marginTop={1}>Postal Code</Typography>
                                            <FormTextFieldWithValidation
                                                placeholder={"Enter postal code"}
                                                field={hostCreateEventState.postalCode}
                                                onChange={(event) => {
                                                    setHostCreateEventState({
                                                        ...hostCreateEventState,
                                                        postalCode: {
                                                            value: event.target.value,
                                                            validation: event.target.value.length > 0
                                                                ? { status: 'valid' }
                                                                : { status: 'invalid', message: 'Postal code cannot be empty' }
                                                        }
                                                    })
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant={"subtitle2"} fontWeight={500} marginTop={1}>City</Typography>
                                            <FormTextFieldWithValidation
                                                placeholder={"Enter city"}
                                                field={hostCreateEventState.city}
                                                onChange={(event) => {
                                                    setHostCreateEventState({
                                                        ...hostCreateEventState,
                                                        city: {
                                                            value: event.target.value,
                                                            validation: event.target.value.length > 0
                                                                ? { status: 'valid' }
                                                                : { status: 'invalid', message: 'City cannot be empty' }
                                                        }
                                                    })
                                                }}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item container columnSpacing={2}>
                                        <Grid item xs={6}>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant={"subtitle2"} fontWeight={500} marginTop={1}>Country</Typography>
                                            <FormTextFieldWithValidation
                                                placeholder={"Enter country"}
                                                field={hostCreateEventState.country}
                                                onChange={(event) => {
                                                    setHostCreateEventState({
                                                        ...hostCreateEventState,
                                                        country: {
                                                            value: event.target.value,
                                                            validation: event.target.value.length > 0
                                                                ? { status: 'valid' }
                                                                : { status: 'invalid', message: 'Country cannot be empty' }
                                                        }
                                                    })
                                                }}
                                            />
                                        </Grid>
                                    </Grid>

                                    <div style={{ height: "0.5em" }} />
                                    <Typography variant={"subtitle2"} fontWeight={500} marginTop={1}>Check location on map</Typography>
                                    <Card variant={"outlined"} style={{ height: "300px" }}>
                                        <Map
                                            zoom={16}
                                            center={
                                                getGeocode.data?.results?.length
                                                    ? getGeocode.data.results[0].geometry.location
                                                    : { lat: 0, lng: 0 }
                                            }
                                            gestureHandling={'greedy'}
                                            disableDefaultUI={false}
                                            mapTypeControl={false}
                                            mapId={googleMapsMapId}
                                        >
                                            {
                                                getGeocode.data?.results?.length
                                                    ? <AdvancedMarker position={getGeocode.data.results[0].geometry.location}>
                                                        <Pin />
                                                    </AdvancedMarker>
                                                    : null
                                            }
                                        </Map>
                                    </Card>
                                </>
                            }
                        />

                        <LabeledFormSection
                            title={"Ticketing"}
                            child={
                                <>
                                    <Typography variant={"subtitle2"} fontWeight={500} marginTop={1}>Total number of tickets</Typography>
                                    <FormTextFieldWithValidation
                                        placeholder={"Enter total number of tickets"}
                                        type={"number"}
                                        field={hostCreateEventState.totalNumberOfTickets}
                                        onChange={(event) => {
                                            let totalNumberOfTicketsValidation: RequestFieldValidation = { status: 'valid' };
                                            if (event.target.value.length === 0) {
                                                totalNumberOfTicketsValidation = { status: 'invalid', message: 'Total number of tickets cannot be empty' };
                                            }

                                            if (parseInt(event.target.value) <= 0) {
                                                totalNumberOfTicketsValidation = { status: 'invalid', message: 'Total number of tickets must be a positive number' };
                                            }

                                            if (hostCreateEventState.event?.totalTicketCount && parseInt(event.target.value) < hostCreateEventState.event.totalTicketCount) {
                                                totalNumberOfTicketsValidation = { status: 'invalid', message: 'Total number of tickets must be greater than previous total number of tickets' };
                                            }

                                            setHostCreateEventState({
                                                ...hostCreateEventState,
                                                totalNumberOfTickets: {
                                                    value: parseInt(event.target.value) || '',
                                                    validation: totalNumberOfTicketsValidation
                                                }
                                            })
                                        }}
                                    />
                                </>
                            }
                        />

                        <Grid item container marginTop={2} alignItems="center" justifyContent="space-between">
                            <Typography variant={"subtitle1"} fontWeight={700}>Ticket types</Typography>
                            <TicketingAddButton
                                size="small"
                                aria-label="add"
                                onClick={() => {
                                    setHostCreateEventState({
                                        ...hostCreateEventState,
                                        ticketTypes: [
                                            ...hostCreateEventState.ticketTypes,
                                            {
                                                isLocked: false,
                                                type: {
                                                    value: '',
                                                    validation: { status: 'initial' }
                                                },
                                                price: {
                                                    value: '',
                                                    validation: { status: 'initial' }
                                                },
                                                description: {
                                                    value: '',
                                                    validation: { status: 'valid' }
                                                }
                                            }
                                        ]
                                    })
                                }}
                            >
                                <AddIcon />
                            </TicketingAddButton>
                        </Grid>
                        {
                            hostCreateEventState.ticketTypes.map((ticketType, index) => (
                                <Grid item container columnSpacing={1} marginTop={1} key={index}>
                                    <Grid item xs={2.5}>
                                        <Typography variant={"subtitle2"} fontWeight={500} marginTop={1}>Type</Typography>
                                        <FormTextFieldWithValidation
                                            disabled={ticketType.isLocked}
                                            placeholder={"Enter ticket type"}
                                            field={ticketType.type}
                                            onChange={(event) => {
                                                const newTicketTypes = [...hostCreateEventState.ticketTypes];
                                                newTicketTypes[index].type = {
                                                    value: event.target.value,
                                                    validation: event.target.value.length > 0
                                                        ? { status: 'valid' }
                                                        : { status: 'invalid', message: 'Ticket type cannot be empty' }
                                                };

                                                setHostCreateEventState({
                                                    ...hostCreateEventState,
                                                    ticketTypes: newTicketTypes
                                                })
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={3.5}>
                                        <Typography variant={"subtitle2"} fontWeight={500} marginTop={1}>Price (PLN)</Typography>
                                        <FormTextFieldWithValidation
                                            disabled={ticketType.isLocked}
                                            placeholder={"Enter price"}
                                            type={"number"}
                                            field={ticketType.price}
                                            onChange={(event) => {
                                                const newTicketTypes = [...hostCreateEventState.ticketTypes];
                                                newTicketTypes[index].price = {
                                                    value: parseInt(event.target.value) || '',
                                                    validation: event.target.value.length > 0 && parseInt(event.target.value) > 0
                                                        ? { status: 'valid' }
                                                        : { status: 'invalid', message: 'Price cannot be empty' }
                                                };

                                                setHostCreateEventState({
                                                    ...hostCreateEventState,
                                                    ticketTypes: newTicketTypes
                                                })
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Typography variant={"subtitle2"} fontWeight={500} marginTop={1}>Description</Typography>
                                        <FormTextFieldWithValidation
                                            disabled={ticketType.isLocked}
                                            placeholder={"Enter additional description"}
                                            field={ticketType.description}
                                            onChange={(event) => {
                                                const newTicketTypes = [...hostCreateEventState.ticketTypes];
                                                newTicketTypes[index].description = {
                                                    value: event.target.value || '',
                                                    validation: { status: 'valid' }
                                                };

                                                setHostCreateEventState({
                                                    ...hostCreateEventState,
                                                    ticketTypes: newTicketTypes
                                                })
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Typography variant={"subtitle2"} fontWeight={500} marginTop={1}>&nbsp;</Typography>
                                        <TicketingDeleteButton
                                            disabled={ticketType.isLocked}
                                            variant={"outlined"}
                                            color={"error"}
                                            onClick={() => {
                                                const newTicketTypes = [...hostCreateEventState.ticketTypes];
                                                newTicketTypes.splice(index, 1);

                                                setHostCreateEventState({
                                                    ...hostCreateEventState,
                                                    ticketTypes: newTicketTypes
                                                })
                                            }}
                                        >
                                            <DeleteOutline />
                                        </TicketingDeleteButton>
                                    </Grid>
                                </Grid>
                            ))
                        }
                        <ConfirmButton
                            variant={"contained"}
                            disabled={
                                !areAllFieldsValid(hostCreateEventState)
                                    || mutateEventStatus.isLoading
                                    || mutateEventStatus.isError
                                    || mutateEventStatus.isSuccess
                            }
                            onClick={() => {
                                mutateEvent({
                                    id: eventId,
                                    mutateEventRequest: mapStateToMutateEventRequest(hostCreateEventState)
                                })
                                    .unwrap()
                                    .then((response) => {
                                        console.log(response);
                                    })
                                    .catch((error: FetchBaseQueryError) => {
                                        console.log(error);
                                    })
                            }}
                        >
                            {
                                !(
                                    mutateEventStatus.isLoading
                                    || mutateEventStatus.isError
                                    || mutateEventStatus.isSuccess
                                )
                                    ? 'Update event'
                                    : <></>
                            }
                            {
                                mutateEventStatus.isLoading
                                    ? <CircularProgress color="inherit" size={24} />
                                    : null
                            }
                            {
                                mutateEventStatus.isSuccess
                                    ? 'Event updated successfully'
                                    : null
                            }
                            {
                                mutateEventStatus.isError
                                    ? 'Error updating event'
                                    : null
                            }
                        </ConfirmButton>
                        {
                            mutateEventStatus.isError
                                ? <LabeledFormSection
                                    title={"Error Details"}
                                    child={
                                        <Typography variant={"subtitle1"} color={"error"}>
                                            {JSON.stringify(mutateEventStatus.error)}
                                        </Typography>
                                    }
                                />
                                : null
                        }
                    </Stack>
                </Grid>
            </Grid>
            <Grid item xs={2}></Grid>
        </>
    )
}

export default HostCreateEvent;
