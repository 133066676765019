import React, { useEffect } from "react";
import { Button, Container, Grid, Stack, Typography } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { setBackground } from "../store/backgroundSlice";
import store from "../store/store";
import { useAuth } from "react-oidc-context";
import { findRolesByJwt } from "./components/user/userUtils"


const LandingPage: React.FC = () => {
    const auth = useAuth()
    const location = useLocation()
    const navigate = useNavigate()

    const backgroundImage = '/images/landing.jpg';
    useEffect(() => {
        if (auth.isAuthenticated) {
            return
        }

        store.dispatch(
            setBackground({
                value: {
                    type: "fill-image",
                    image: backgroundImage
                },
                overlay: {
                    type: "fill-color",
                    color: "rgba(5,24,40,0.77)"
                }
            })
        );
    }, [auth]);

    useEffect(() => {
        if (!auth.isAuthenticated || !auth.user) {
            return
        }

        const roles = findRolesByJwt(auth.user)
        if (location.pathname === "/" && roles.includes("administrator")) {
            navigate("/admin")
            return
        }

        if (location.pathname === "/" && roles.includes("event-host")) {
            navigate("/host")
            return
        }

        if (location.pathname === "/" && roles.includes("customer")) {
            navigate("/events")
            return
        }
    }, [auth, location.pathname, navigate])

    const titleStyle = {
        color: "white",
        fontFamily: "Gilroy",
        fontSize: "7em",
        fontWeight: 800,
        lineHeight: "1em",
        paddingTop: "1em",
    }

    const subtitleStyle = {
        color: "white",
        fontFamily: "Roboto Flex",
        fontSize: "4em",
        fontWeight: 1200,
        lineHeight: "normal",
        padding: "1em 0",
    }

    const startButtonStyle = {
        borderRadius: "8px",
        border: "3px solid #A0B7CD",
        background: "linear-gradient(92deg, rgba(201, 214, 255, 0.95) 0%, rgba(226, 226, 226, 0.95) 100.4%)",
        color: "#374857",
        width: "100%", padding: "0.5em", margin: "1em 0",
        fontFamily: "Gilroy",
        textTransform: "none",
        fontSize: "1.5em",
        fontWeight: 900
    }

    const hostButtonStyle = {
        borderRadius: "8px",
        border: "3px solid #A0B7CD",
        background: "transparent",
        color: "#A0B7CD",
        width: "100%", padding: "0.5em",
        fontFamily: "Gilroy",
        textTransform: "none",
        fontSize: "1.5em",
        fontWeight: 900
    }

    if (auth.isAuthenticated) {
        return (<>
            Redirecting...
        </>)
    }

    return (
        <div>
            <Container>
                <Grid container>
                    <Grid xs={12} item>
                        <Typography style={titleStyle}>
                            Live Local Life
                        </Typography>
                        <Typography style={subtitleStyle}>
                            ENJOY<br/>LIFE<br/>LIKE<br/>LOCALS
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid xs={0} md={3} item/>
                    <Grid xs={12} md={6} item>
                        <Link to={"/events"}>
                            <Button style={startButtonStyle as React.CSSProperties}>
                                Start Your Local Life Here
                            </Button>
                        </Link>
                        <Link to={"/host/register"}>
                            <Button variant={"outlined"} style={hostButtonStyle as React.CSSProperties}>
                                Hosting Events and Want to Join Us?
                            </Button>
                        </Link>
                    </Grid>
                    <Grid xs={0} md={3} item/>
                </Grid>
            </Container>
        </div>
    );
};

export default LandingPage;
