import React, {ReactNode} from "react";
import {Typography} from "@mui/material";

const LabeledFormSection: React.FC<{
    title: string,
    child: ReactNode,
    marginTop?: number
}> = ({
    title,
    child,
    marginTop = 2
}) => {
    return (
        <>
            <Typography variant={"subtitle1"} fontWeight={700} marginTop={marginTop}>{title}</Typography>
            {child}
        </>
    )
}

export default LabeledFormSection
