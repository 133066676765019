import {createBrowserRouter, createRoutesFromElements, Route, RouterProvider, UIMatch} from "react-router-dom";
import LandingPage from "../common/LandingPage";
import React from "react";
import HostRegister from "../host/public/HostRegister"
import {HomeOutlined} from "@mui/icons-material"
import {SecuredRoute} from "../common/components/user/SecuredRoute"
import MyProfile from "../customer/profile/MyProfile"
import EditProfile from "../customer/profile/EditProfile"
import EventList from "../customer/events/event_list/EventList"
import EventDetail from "../customer/events/event_detail/EventDetail"
import HostHome from "../host/HostHome"
import HostCreateEvent from "../host/HostCreateEvent"
import HostEditEvent from "../host/HostEditEvent"
import {LiveLocalLifeJwtUserRole} from "../common/components/user/userTypes"
import {HeaderBreadcrumbChoice} from "../common/components/header/headerTypes"
import {HostCancelEvent} from "../host/HostCancelEvent"
import EventCheckout from "../customer/events/event_checkout/EventCheckout";
import { HostPromoteEvent } from "../host/HostPromoteEvent"
import HostDetail from "../host/HostDetail"
import { EventCancel } from "../customer/events/event_cancel/EventCancel"
import { AdminHome } from "../admin/AdminHome"
import HostValidateEvent from "../host/HostValidateEvent";
import { EventReview } from "../customer/events/event_review/EventReview"
import CompleteProfile from "../customer/profile/CompleteProfile"


const MainRouter = () => {
    const makeChoicesForHomeRoutes = (match: UIMatch) => {
        const matchData = match.data as { userRoles?: LiveLocalLifeJwtUserRole[] }
        const userRoles = matchData.userRoles || []

        const choices = [] as HeaderBreadcrumbChoice[]
        if(userRoles.includes("event-host")) {
            choices.push({
                path: "/host",
                name: "Host Home"
            })
        }

        if(userRoles.includes("customer")) {
            choices.push({
                path: "/events",
                name: "Customer Home"
            })
        }

        if(userRoles.includes("administrator")) {
            choices.push({
                path: "/admin",
                name: "Admin Home"
            })
        }

        choices.push({
            path: "/profile",
            name: "My Profile"
        })

        return choices
    }

    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route
                handle={{
                    type: "ignore"
                }}
            >
                <Route
                    index
                    element={<LandingPage/>}
                    handle={{
                        type: "ignore"
                    }}
                />
                <Route
                    path="/host/register"
                    element={<HostRegister/>}
                    handle={{
                        type: "replace",
                        crumbs: (match: UIMatch) => [
                            {
                                path: "/",
                                label: "Home",
                                icon: <HomeOutlined/>,
                                navigatable: true
                            },
                            {
                                path: match.pathname,
                                label: "Host Registration"
                            }
                        ]
                    }}
                />
                <Route
                    path="/profile/complete"
                    element={<CompleteProfile/>}
                    handle={{
                        type: "replace",
                        crumbs: (match: UIMatch) => [
                            {
                                path: "/",
                                label: "Home",
                                icon: <HomeOutlined/>,
                                navigatable: true
                            },
                            {
                                path: match.pathname,
                                label: "Complete Profile"
                            }
                        ]
                    }}
                />
                <Route
                    path="/*"
                    element={<SecuredRoute/>}
                    handle={{
                        type: "ignore"
                    }}
                >
                    <Route
                        path="events"
                        handle={{
                            type: "push",
                            crumbs: (match: UIMatch) => [
                                {
                                    path:  match.pathname,
                                    label: "Customer Home",
                                    icon: <HomeOutlined/>,
                                    navigatable: true,
                                    choices: makeChoicesForHomeRoutes(match)
                                }
                            ]
                        }}
                    >
                        <Route
                            index
                            element={<EventList />}
                            handle={{
                                type: "ignore"
                            }}
                        />
                        <Route
                            path="detail/:eventId"
                            element={<EventDetail />}
                            handle={{
                                type: "push",
                                crumbs: (match: UIMatch) => [
                                    {
                                        path:  match.pathname,
                                        label: "Event Details"
                                    }
                                ]
                            }}
                        />
                        <Route
                            path="checkout/:eventId"
                            element={<EventCheckout />}
                            handle={{
                                type: "push",
                                crumbs: (match: UIMatch) => [
                                    {
                                        path:  match.pathname,
                                        label: "Event Checkout"
                                    }
                                ]
                            }}
                        />
                        <Route
                            path="checkout/:eventTicketId/cancel"
                            element={<EventCancel />}
                            handle={{
                                type: "replace",
                                crumbs: (match: UIMatch) => [
                                    {
                                        path:  "/profile",
                                        label: "My Profile",
                                        icon: <HomeOutlined/>,
                                        navigatable: true,
                                        choices: makeChoicesForHomeRoutes(match)
                                    },
                                    {
                                        path:  match.pathname,
                                        label: "Event Refund"
                                    }
                                ]
                            }}
                        />
                        <Route
                            path="review/:eventTicketId"
                            element={<EventReview />}
                            handle={{
                                type: "replace",
                                crumbs: (match: UIMatch) => [
                                    {
                                        path:  "/profile",
                                        label: "My Profile",
                                        icon: <HomeOutlined/>,
                                        navigatable: true,
                                        choices: makeChoicesForHomeRoutes(match)
                                    },
                                    {
                                        path:  match.pathname,
                                        label: "Event Review"
                                    }
                                ]
                            }}
                        />
                    </Route>
                    <Route
                        path="profile"
                        handle={{
                            type: "push",
                            crumbs: (match: UIMatch) => [
                                {
                                    path:  match.pathname,
                                    label: "My Profile",
                                    icon: <HomeOutlined/>,
                                    navigatable: true,
                                    choices: makeChoicesForHomeRoutes(match)
                                }
                            ]
                        }}
                    >
                        <Route
                            index
                            element={<MyProfile/>}
                            handle={{
                                type: "ignore"
                            }}
                        />
                        <Route
                            path="edit"
                            element={<EditProfile/>}
                            handle={{
                                type: "push",
                                crumbs: (match: UIMatch) => [
                                    {
                                        path:  match.pathname,
                                        label: "Edit Profile"
                                    }
                                ]
                            }}
                        />
                    </Route>
                    <Route
                        path="host"
                        handle={{
                            type: "push",
                            crumbs: (match: UIMatch) => [
                                {
                                    path: match.pathname,
                                    label: "Host Home",
                                    icon: <HomeOutlined/>,
                                    navigatable: true,
                                    choices: makeChoicesForHomeRoutes(match)
                                }
                            ]
                        }}
                    >
                        <Route
                            index
                            element={<HostHome />}
                            handle={{
                                type: "ignore"
                            }}
                        />
                        <Route
                            path=":eventHostId"
                            element={<HostDetail />}
                            handle={{
                                type: "replace",
                                crumbs: (match: UIMatch) => [
                                    {
                                        path: "/events",
                                        label: "Customer Home",
                                        icon: <HomeOutlined/>,
                                        navigatable: true,
                                        choices: makeChoicesForHomeRoutes(match)
                                    },
                                    {
                                        path: match.pathname,
                                        label: "Host Details"
                                    }
                                ]
                            }}
                        />
                        <Route
                            path="events/create"
                            element={<HostCreateEvent/>}
                            handle={{
                                type: "push",
                                crumbs: (match: UIMatch) => [
                                    {
                                        path: match.pathname,
                                        label: "Create Event"
                                    }
                                ]
                            }}
                        />
                        <Route
                            path="events/edit/:eventId"
                            element={<HostEditEvent />}
                            handle={{
                                type: "push",
                                crumbs: (match: UIMatch) => [
                                    {
                                        path: match.pathname,
                                        label: "Edit Event"
                                    }
                                ]
                            }}
                        />
                        <Route
                            path="events/cancel/:eventId"
                            element={<HostCancelEvent />}
                            handle={{
                                type: "push",
                                crumbs: (match: UIMatch) => [
                                    {
                                        path: match.pathname,
                                        label: "Cancel Event"
                                    }
                                ]
                            }}
                        />
                        <Route
                            path="events/promote/:eventId"
                            element={<HostPromoteEvent />}
                            handle={{
                                type: "push",
                                crumbs: (match: UIMatch) => [
                                    {
                                        path: match.pathname,
                                        label: "Promote Event"
                                    }
                                ]
                            }}
                        />
                        <Route
                            path="events/validate/:eventId"
                            element={<HostValidateEvent />}
                            handle={{
                                type: "push",
                                crumbs: (match: UIMatch) => [
                                    {
                                        path: match.pathname,
                                        label: "Validate Event"
                                    }
                                ]
                            }}
                        />
                    </Route>
                    <Route
                        path="admin"
                        handle={{
                            type: "push",
                            crumbs: (match: UIMatch) => [
                                {
                                    path: match.pathname,
                                    label: "Admin Home",
                                    icon: <HomeOutlined/>,
                                    navigatable: true,
                                    choices: makeChoicesForHomeRoutes(match)
                                }
                            ]
                        }}
                    >
                        <Route
                            index
                            element={<AdminHome />}
                            handle={{
                                type: "ignore"
                            }}
                        />
                    </Route>
                </Route>
            </Route>
        )
    )

    return (
        <RouterProvider router={router} />
    )
}

export default MainRouter
