import React, {useCallback, useEffect, useRef, useState} from 'react';
import EventCard, {EventCardPlaceholder} from '../../../common/components/event/EventCard';
import {Button, Grid, Skeleton} from "@mui/material";
import {Event, useListEventsQuery} from "../../../store/api";
import {useSelector} from "react-redux";
import {RootState} from "../../../store/store";
import {uniq} from "lodash"

export const EventSectionPlaceholder: React.FC<{size: number}> = ({size}) => (
    <>
        <div style={{ margin: '20px 0' }}>
            <Skeleton variant="text" height={32} />
        </div>
        <Grid container spacing={4}>
            {[...Array(size)].map((_, i) => (
                <Grid item key={i} xs={12} sm={6} md={4}>
                    <EventCardPlaceholder />
                </Grid>
            ))}
        </Grid>
    </>
)

const EventSection: React.FC<{
    isExpanded: boolean,
    title: string,
    tags: { [key in string]: any },
    categoryId: string
}> = ({
    isExpanded,
    title,
    tags,
    categoryId
}) => {
    const query = useSelector((state: RootState) => state.headerSearch.value);
    const attributeIds = useSelector((state: RootState) => state.eventListFilters.attributeIds);

    const [page, setPage] = useState<number>(0);
    const [lastFetchedPage, setLastFetchedPage] = useState<number>(-1);
    const [events, setEvents] = useState<Event[]>([]);

    const attributeIdsWithCategory = uniq([...attributeIds, categoryId]);

    useEffect(() => {
        setPage(0)
        setLastFetchedPage(-1)
        setEvents([])
    }, [attributeIds, query]);

    const eventsListFetch = useListEventsQuery({
        name: query,
        attributeId: attributeIdsWithCategory,
        status: "CREATED",
        page: page,
        size: 6
    });

    const fetchedEvents = eventsListFetch.data?.data
    const loading = (eventsListFetch.isLoading || eventsListFetch.error || !fetchedEvents)

    useEffect(() => {
        const fetchedPage = eventsListFetch.originalArgs?.page
        if (loading || fetchedPage !== lastFetchedPage + 1)
            return;

        const fetchedEvents = eventsListFetch.data?.data;
        if (!fetchedEvents || fetchedEvents.length === 0)
            return

        if (fetchedEvents) {
            setEvents(prevEvents => [...prevEvents, ...fetchedEvents]);
            setLastFetchedPage(page);
        }
    }, [eventsListFetch.data, events]);

    const observer = useRef<IntersectionObserver | null>(null);

    const lastEventRef = useCallback((node: Element | null) => {
        if (loading || page === lastFetchedPage + 1)
            return
        if (observer.current) observer.current.disconnect();

        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
                setPage(lastFetchedPage + 1);
            }
        });
        if (node) observer.current.observe(node);
    }, [lastFetchedPage]);

    const [displayFullList, setDisplayFullList] = useState<boolean>(false);

    const DISPLAY_LIMIT = 6;

    if (events.length === 0) {
        if (eventsListFetch.isLoading) {
            return <EventSectionPlaceholder size={DISPLAY_LIMIT} />
        } else {
            return <></>
        }
    }

    const lastEventId = events.length !== 0 ? events[events.length - 1].id : "undefined"
    const displayedEvents = displayFullList ? events : events.slice(0, DISPLAY_LIMIT);

    return (
        <div style={{ margin: '20px 0' }}>
            <h2>{`${tags.icon} ${title}`}</h2>
            <Grid container spacing={4}>
                {displayedEvents.map((event, index) => (
                    <Grid item key={event.id} xs={12} sm={6} md={4}
                          ref={event.id === lastEventId ? lastEventRef : null}>
                        <EventCard event={event} mode={"customer"} />
                    </Grid>
                ))}
            </Grid>
            {events.length > DISPLAY_LIMIT && !displayFullList && (
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <Button onClick={() => setDisplayFullList(true)}>Show More</Button>
                </div>
            )}
            {displayFullList && events.length > DISPLAY_LIMIT && (
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <Button onClick={() => setDisplayFullList(false)}>Show Less</Button>
                </div>
            )}
        </div>
    );
}

export default EventSection;
