import { parsePhoneNumber } from "libphonenumber-js/min"

export const getPhoneString = (phone: { countryCode: string, number: string }) => {
    let countryCode = ''

    if (phone.countryCode) {
        countryCode = `+${phone.countryCode}`
    }

    if (phone.countryCode && phone.countryCode.startsWith('+')) {
        countryCode = phone.countryCode
    }

    const phoneNumber = countryCode
        ? `${countryCode}${phone.number}`
        : phone.number

    try {
        return parsePhoneNumber(phoneNumber)
            .formatInternational()
    } catch (e) {
        return phoneNumber
    }
}

export const getPhoneLink = (phone: { countryCode: string, number: string }) => {
    return phone.countryCode
        ? `tel:+${phone.countryCode}${phone.number}`
        : `tel:${phone.number}`
}
