import {combineReducers, configureStore} from "@reduxjs/toolkit";
import backgroundReducer from "./backgroundSlice";
import snackbarReducer from "./snackbarSlice";
import eventListFiltersReducer from "./eventListFiltersSlice";
import {api} from "./api";
import fileApi from "./fileApi";
import headerSearchReducer from "./headerSearchSlice";
import geocodeApi from "./geocodeApi"

const rootReducer = combineReducers({
    background: backgroundReducer,
    snackbar: snackbarReducer,
    headerSearch: headerSearchReducer,
    eventListFilters: eventListFiltersReducer,
    [api.reducerPath]: api.reducer,
    [fileApi.reducerPath]: fileApi.reducer,
    [geocodeApi.reducerPath]: geocodeApi.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;


const store = configureStore({
    devTools: true,
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(api.middleware)
            .concat(fileApi.middleware)
            .concat(geocodeApi.middleware),
});

export default store;
