import React from "react"
import { RequestField } from "./formTypes"
import { FormTextField } from "./formStyles"
import { FormFieldError } from "./FormFieldError"

export const FormTextFieldWithValidation = <T,>(
    props: {
        field: RequestField<T>,
        onChange: (value: React.ChangeEvent<HTMLInputElement>) => void,
        placeholder?: string,
        multiline?: boolean,
        rows?: number,
        type?: string,
        disabled?: boolean,
    }
) => {
    return (
        <>
            <FormTextField
                value={props.field.value}
                placeholder={props.placeholder}
                multiline={props.multiline}
                rows={props.rows}
                type={props.type}
                error={props.field.validation.status === 'invalid'}
                disabled={props.disabled}
                onChange={props.onChange}
            />
            {
                props.field.validation.status === 'invalid'
                    ? FormFieldError({ message: props.field.validation.message })
                    : FormFieldError({})
            }
        </>
    )
}
