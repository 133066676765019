import React from "react"
import { Event, EventHost, useListEventsQuery } from "../../../store/api"
import EventCard, { EventCardMode, EventCardPlaceholder } from "../event/EventCard"
import { Button, Card, Grid, Pagination, Stack, Typography } from "@mui/material"
import { Link } from "react-router-dom"
import { AddOutlined } from "@mui/icons-material"
import { skipToken } from "@reduxjs/toolkit/query"

export const HostEventsCard: React.FC<{
    title: string,
    showCreateButton: boolean,
    eventCardMode: EventCardMode,
    status: "CREATED" | "CANCELED" | "IN_PROGRESS" | "FINISHED",
    eventHost: EventHost,
    pagination?: boolean
}> = ({
    title,
    showCreateButton,
    eventCardMode,
    status,
    eventHost,
    pagination = false
}) => {
    const paginationSize = 6;
    const [paginationPage, setPaginationPage] = React.useState(0);

    const listEvents = useListEventsQuery({
        eventHostId: eventHost.id,
        page: paginationPage,
        size: paginationSize,
        status: status,
        order: ["START_DATE:ASC"]
    });

    const paginationCount = Math.ceil((listEvents.data?.count ?? 0) / paginationSize);

    return (
        <Card variant="outlined">
            <Stack padding={4}>
                <Grid item container>
                    <Typography variant="h5" fontWeight={700}>{title}</Typography>
                    {
                        showCreateButton &&
                        <Button
                            component={Link}
                            to="/host/events/create"
                            variant="outlined"
                            color="success"
                            startIcon={<AddOutlined />}
                            style={{marginLeft: "auto"}}
                        >
                            Create event
                        </Button>
                    }
                </Grid>
                <div style={{height: "1em"}} />
                <Grid marginTop={1} container spacing={2}>
                    {
                        listEvents.isLoading &&
                        [1, 2, 3]
                            .map(i => (
                                <Grid item xs={12} md={4} key={`event-${i}`} marginBottom={1}>
                                    <EventCardPlaceholder />
                                </Grid>
                            ))
                    }
                    {
                        !listEvents.isLoading &&
                        listEvents.data?.data
                            .map((event, i) => (
                                <Grid item xs={12} md={4} key={`event-${event.id}`} marginBottom={1}>
                                    <EventCard event={event} mode={eventCardMode} />
                                </Grid>
                            ))
                    }
                </Grid>
                {
                    !listEvents.isLoading &&
                    listEvents.data?.data.length === 0 &&
                    <Typography variant="body1">No events to show</Typography>
                }
                {
                    !listEvents.isLoading &&
                    pagination &&
                    <Stack
                        marginTop={2}
                        justifyContent="center"
                        alignItems="center"
                        direction="row"
                    >
                        <Pagination
                            variant="outlined"
                            count={paginationCount}
                            page={paginationPage + 1}
                            onChange={(e, page) => setPaginationPage(page - 1)}
                        />
                    </Stack>
                }
            </Stack>
        </Card>
    )
}
