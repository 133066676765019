import { Attribute, AttributeGroup } from "../../../store/api"

export const findAttributeGroupForAttribute = (attributeGroups: AttributeGroup[], attribute: Attribute): AttributeGroup | undefined => {
    // TODO: this is stupid doodoo fix it laters
    return attributeGroups
        .find(attributeGroup =>
            attributeGroup.attributes
                .some(a => a.id === attribute.id)
        )
}
