
export const defaultBackgroundColor = '#f5f5f5';

export const apiBaseUrl = process.env.REACT_APP_LIVELOCALLIFE_API_BASE_URL
    || (() => { throw new Error('REACT_APP_LIVELOCALLIFE_API_BASE_URL is not set'); })();

export const accountManagementUrl = process.env.REACT_APP_LIVELOCALLIFE_ACCOUNT_MANAGEMENT_BASE_URL
    || (() => { throw new Error('REACT_APP_LIVELOCALLIFE_ACCOUNT_MANAGEMENT_BASE_URL is not set'); })();

export const authAuthority = process.env.REACT_APP_LIVELOCALLIFE_AUTH_AUTHORITY
    || (() => { throw new Error('REACT_APP_LIVELOCALLIFE_AUTH_AUTHORITY is not set'); })();

export const authClientId = process.env.REACT_APP_LIVELOCALLIFE_AUTH_CLIENT_ID
    || (() => { throw new Error('REACT_APP_LIVELOCALLIFE_AUTH_CLIENT_ID is not set'); })();

export const authRedirectUri = process.env.REACT_APP_LIVELOCALLIFE_AUTH_REDIRECT_URI
    || (() => { throw new Error('REACT_APP_LIVELOCALLIFE_AUTH_REDIRECT_URI is not set'); })();

export const authPostLogoutRedirectUri = process.env.REACT_APP_LIVELOCALLIFE_AUTH_POST_LOGOUT_REDIRECT_URI
    || (() => { throw new Error('REACT_APP_LIVELOCALLIFE_AUTH_POST_LOGOUT_REDIRECT_URI is not set'); })();

export const categoriesAttributeGroupId = process.env.REACT_APP_LIVELOCALLIFE_CATEGORIES_ATTRIBUTE_GROUP_ID
    || (() => { throw new Error('REACT_APP_LIVELOCALLIFE_CATEGORIES_ATTRIBUTE_GROUP_ID is not set'); })();

export const googleMapsApiKey = process.env.REACT_APP_LIVELOCALLIFE_GOOGLE_MAPS_API_KEY;

export const googleMapsMapId = process.env.REACT_APP_LIVELOCALLIFE_GOOGLE_MAPS_MAP_ID;
