import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import { StorageObjectMeta } from "./api"
import { apiBaseUrl } from "../common/constants"

const fileApi = createApi({
    reducerPath: 'fileApi',
    baseQuery: fetchBaseQuery({
        baseUrl: apiBaseUrl,
        prepareHeaders: (headers) => {
            const token = localStorage.getItem('token');
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }

            return headers;
        }
    }),
    endpoints: (builder) => ({
        getFile: builder.query<GetFileApiResponse, GetFileApiArg>({
            query: (queryArg) => ({
                url: `/storage/${queryArg.id}`,
                responseHandler: (response) => response.blob().then(blob => URL.createObjectURL(blob))
            }),
        }),
        uploadFile: builder.mutation<UploadFileApiResponse, UploadFileApiArg>({
            query: ({id, file}) => {
                const formData = new FormData();
                formData.append('file', file);

                return {
                    url: `/storage/${id}`,
                    method: 'POST',
                    body: formData,
                    formData: true,
                }
            },
        }),
        getImage: builder.query<GetImageApiResponse, GetImageApiArg>({
            query: (queryArg) => ({
                url: `/storage/image/${queryArg.id}`,
                params: {
                    scale: queryArg.scale,
                    maxWidth: queryArg.maxWidth,
                    maxHeight: queryArg.maxHeight,
                    quality: queryArg.quality,
                },
                responseHandler: (response) => response.blob().then(blob => URL.createObjectURL(blob))
            })
        }),
        saveImage: builder.mutation<SaveImageApiResponse, SaveImageApiArg>({
            query: ({ id, file }) => {
                const formData = new FormData();
                formData.append('file', file);

                return {
                    url: `/storage/image/${id}`,
                    method: "POST",
                    body: formData,
                }
            }
        })
    }),
});

export type GetFileApiResponse = string;
export type GetFileApiArg = {
    /** File id */
    id: string;
};

export type UploadFileResponseSuccess = {
    data: StorageObjectMeta;
    count?: number;
};

export type UploadFileApiResponse =
/** status 200 Success */ UploadFileResponseSuccess;
export type UploadFileApiArg = {
    /** Id */
    id: string;
    /** File */
    file: Blob;
};

export type GetImageApiResponse = string;
export type GetImageApiArg = {
    id: string;
    scale?: number;
    maxWidth?: number;
    maxHeight?: number;
    quality?: number;
};

export type GetImageMetaResponseSuccess = {
    data: StorageObjectMeta;
    count?: number;
};

export type SaveImageApiResponse =
/** status 200 Success */ GetImageMetaResponseSuccess;
export type SaveImageApiArg = {
    id: string;
    file: Blob;
};

export const {
    useGetFileQuery,
    useUploadFileMutation,
    useGetImageQuery,
    useSaveImageMutation,
} = fileApi;

export default fileApi;
