import React from "react"
import { EventHost } from "../../../store/api"
import { Card, Stack, Typography } from "@mui/material"

export const HostDescriptionCard: React.FC<{ eventHost: EventHost }> = ({ eventHost }) => {
    return (
        <Card variant="outlined">
            <Stack padding={4}>
                <Typography variant="h5" fontWeight={700}>Place description</Typography>
                <Typography variant="body1" marginTop={2}>{eventHost.description}</Typography>
            </Stack>
        </Card>
    )
}
