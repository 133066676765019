import React, {CSSProperties} from 'react';
import {Button, Card, CardActions, CardContent, Grid, Skeleton, Stack, Typography} from '@mui/material';

import {Link} from "react-router-dom";
import {Event, EventHost} from "../../../store/api";
import {BuyNowButton, CardTitleTypography, MoreInfoButton} from "./eventCardStyles";
import {formatDate, getAddressString, getPriceString} from "./eventUtils";
import EventDataFetcher from "./EventDataFetcher";
import {
    Checklist,
    DeleteOutline,
    EditOutlined,
    EventOutlined,
    LocationOnOutlined,
    SellOutlined,
    SpeakerPhoneOutlined
} from "@mui/icons-material"

export const EventCardPlaceholder = () => (
    <Card variant="outlined">
        <Skeleton variant="rectangular" height={200} />
        <Stack direction={"column"} padding={2}>
            <div style={{ height: "0.5em" }} />
            <Skeleton variant="text" height={32} />
            <div style={{ height: "1em" }} />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
        </Stack>
    </Card>
)


export type EventCardMode =
    | "customer"
    | "hostEdit"
    | "hostPromote"
    | "hostView"

const EventCard: React.FC<{event: Event, mode: EventCardMode}> = ({ event, mode }) => {

    return (
        <EventDataFetcher
            event={event}
            loadingChild={<EventCardPlaceholder />}
            errorChild={<EventCardPlaceholder />}
            child={({ event, eventHost, image }) => EventCardRenderer({ event, eventHost, image, mode })}
        />
    );
}

const EventCardControls: React.FC<{
    event: Event,
    mode: EventCardMode
}> = ({ event, mode }) => {
    switch (mode) {
        case "customer":
            return (<>
                <Grid item xs={6}>
                    <Link to={`/events/detail/${event.id}`} style={{ width: '100%' }}>
                        <MoreInfoButton fullWidth size="small" color="primary">
                            More info
                        </MoreInfoButton>
                    </Link>
                </Grid>
                <Grid item xs={6}>
                    <Link to={`/events/checkout/${event.id}`} style={{ width: '100%' }}>
                        <BuyNowButton fullWidth size="small" color="primary" variant="contained">
                            Buy now
                        </BuyNowButton>
                    </Link>
                </Grid>
            </>)
        case "hostEdit":
            return (<>
                <Grid item xs={4}>
                    <Link to={`/host/events/cancel/${event.id}`} style={{ width: '100%' }}>
                        <Button fullWidth size="small" color="error" variant="outlined" style={{ fontWeight: 600 }}>
                            <DeleteOutline fontSize={"small"} />
                            Cancel
                        </Button>
                    </Link>
                </Grid>
                <Grid item xs={4}>
                    <Link to={`/host/events/promote/${event.id}`} style={{ width: '100%' }}>
                        <Button fullWidth size="small" color="success" variant="outlined" style={{ fontWeight: 600 }}>
                            <SpeakerPhoneOutlined fontSize={"small"} />
                            Promote
                        </Button>
                    </Link>
                </Grid>
                <Grid item xs={4}>
                    <Link to={`/host/events/edit/${event.id}`} style={{ width: '100%' }}>
                        <Button fullWidth size="small" color="primary" variant="outlined" style={{ fontWeight: 600 }}>
                            <EditOutlined fontSize={"small"} />
                            Edit
                        </Button>
                    </Link>
                </Grid>
            </>)
        case "hostPromote":
            return (<>
                <Grid item xs={4}>
                    <Link to={`/events/detail/${event.id}`} style={{ width: '100%' }}>
                        <Button fullWidth size="small" variant="outlined"
                                style={{ fontWeight: 600, color: "grey", borderColor: "grey" }}>
                            More info
                        </Button>
                    </Link>
                </Grid>
                <Grid item xs={4}>
                    <Link to={`/host/events/promote/${event.id}`} style={{ width: '100%' }}>
                        <Button fullWidth size="small" color="success" variant="outlined" style={{ fontWeight: 600 }}>
                            <SpeakerPhoneOutlined fontSize={"small"} />
                            Promote
                        </Button>
                    </Link>
                </Grid>
                <Grid item xs={4}>
                    <Link to={`/host/events/validate/${event.id}`} style={{ width: '100%' }}>
                        <Button fullWidth size="small" color="info" variant="outlined" style={{ fontWeight: 600 }}>
                            <Checklist fontSize={"small"} />
                            Validate
                        </Button>
                    </Link>
                </Grid>
            </>)
        case "hostView":
            return (<></>)

    }

    return (<></>)
}

const EventCardRenderer: React.FC<{
    event: Event,
    eventHost: EventHost,
    image: string,
    mode: EventCardMode
}> = ({
    event,
    eventHost,
    image,
    mode
}) => {
    const layoutStyle: CSSProperties = {
        position: "relative",
        height: 240
    }

    const backgroundStyle: CSSProperties = image
        ? {
            position: 'absolute',
            width: '100%',
            height: '100%',
            backgroundImage: `url(${image})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
        }
        : {};

    const skeletonStyle: CSSProperties = {
        position: 'absolute',
        width: '100%',
        height: '100%',
    }

    const gradientOverlayStyle: CSSProperties = {
        position: 'absolute',
        top: '65%',
        width: '100%',
        height: '35%',
        background: 'linear-gradient(rgba(255,255,255,0), rgba(255,255,255,1))',
    }

    // @ts-ignore
    return (
        <Card variant="outlined">
            <div style={layoutStyle}>
                {
                    image ?
                        <div style={backgroundStyle} />
                        : <Skeleton variant="rectangular" height={240} style={skeletonStyle} />
                }
                {
                    <div style={gradientOverlayStyle} />
                }
            </div>
            <CardContent>
                <Stack direction="column">
                    <CardTitleTypography gutterBottom variant="h5" marginBottom={0} style={{ zIndex: 3 }}>{event.name}</CardTitleTypography>

                    <Typography variant={"subtitle1"} color={"#"}>
                        by <Link to={`/host/${eventHost.id}`} style={{ color: '#000', textDecoration: "none"}}>{eventHost.name}</Link>
                    </Typography>

                    <Stack direction={"row"} spacing={1} marginTop={2}>
                        <EventOutlined fontSize="small" />
                        <Typography variant="body2" component="span">{formatDate(event.startDate)}</Typography>
                    </Stack>

                    <Stack direction={"row"} spacing={1} marginTop={1}>
                        <LocationOnOutlined fontSize="small" />
                        <Typography variant="body2" component="span">{getAddressString(event.address)}</Typography>
                    </Stack>

                    <Stack direction={"row"} spacing={1} marginTop={1}>
                        <SellOutlined fontSize="small" />
                        <Typography variant="body2" component="span">{getPriceString(event.tickets)}</Typography>
                    </Stack>
                </Stack>
            </CardContent>
            <CardActions>
                <Grid container spacing={2}>
                    <EventCardControls
                        event={event}
                        mode={mode}
                    />
                </Grid>
            </CardActions>
        </Card>
    );
}

export default EventCard;
