import { useListReviewsQuery, User } from "../../../../store/api"
import { Card, Grid, Pagination, Stack, Typography } from "@mui/material"
import React from "react"
import { EventReviewCard, EventReviewCardPlaceholder } from "./EventReviewCard"

export const EventReviewByUserList: React.FC<{
    title: string,
    user: User,
    pagination?: boolean
}> = ({
    title,
    user,
    pagination = false
}) => {
    const paginationSize = 6

    const [paginationPage, setPaginationPage] = React.useState(0)

    const listReviews = useListReviewsQuery({
        size: paginationSize,
        page: paginationPage,
        userId: user.id,
        order: ["CREATED_DATE:DESC"]
    })

    const paginationCount = Math.ceil((listReviews.data?.count ?? 0) / paginationSize)

    return (
        <Card variant="outlined">
            <Stack padding={4}>
                <Grid item container>
                    <Typography variant="h5" fontWeight={700}>{title}</Typography>
                </Grid>
                <Grid item marginTop={1} container spacing={2}>
                    {
                        listReviews.isLoading
                        && [1, 2, 3]
                            .map(i => (
                                <Grid item xs={12} md={4} key={`event-${i}`} marginBottom={1}>
                                    <EventReviewCardPlaceholder/>
                                </Grid>
                            ))
                    }
                    {
                        !listReviews.isLoading
                        && listReviews.data?.data
                            .map((review, i) => (
                                <Grid item xs={12} md={4} key={`review-${review.id}`} marginBottom={1}>
                                    <EventReviewCard review={review} />
                                </Grid>
                            ))
                    }
                </Grid>
                {
                    !listReviews.isLoading
                    && listReviews.data?.data.length === 0
                    && <Typography variant="body1">No reviews to show</Typography>
                }
                {
                    !listReviews.isLoading &&
                    pagination &&
                    <Stack
                        marginTop={2}
                        justifyContent="center"
                        alignItems="center"
                        direction="row"
                    >
                        <Pagination
                            variant="outlined"
                            count={paginationCount}
                            page={paginationPage + 1}
                            onChange={(e, page) => setPaginationPage(page - 1)}
                        />
                    </Stack>
                }
            </Stack>
        </Card>
    )
}
