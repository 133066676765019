import React from "react";
import { LoadingBackdrop } from "./components/LoadingBackdrop"

const LoadingPage = () => {
    return (
        <LoadingBackdrop open={true} />
    )
}

export default LoadingPage
