import { Card, CardActionArea, styled, TextField } from "@mui/material"
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers"
import { Dayjs } from "dayjs"


export const FormTextField = styled(TextField)<{ variant?: string }>`
    width: 100%;
    background: #FFFFFF;
    border-radius: 4px;
`;

export const FormDatePicker = styled(DatePicker)<{
    defaultValue?: string | Date | null | undefined | Dayjs
}>`
    width: 100%;
    background: #FFFFFF;
    border-radius: 4px;
`;

export const FormDateTimePicker = styled(DateTimePicker)<{
    defaultValue?: string | Date | null | undefined | Dayjs
}>`
    width: 100%;
    background: #FFFFFF;
    border-radius: 4px;
`;

export const CoverPictureArea = styled(Card)`
    height: 300px;
`;

export const CoverPictureActionArea = styled(CardActionArea)`
    height: 100%;
`;
