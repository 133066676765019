import React from "react"
import Header from "../../common/components/header/Header"
import {
    CircularProgress,
    Grid, Stack,
    Typography
} from "@mui/material"
import LabeledFormSection from "../../common/components/form/LabeledFormSection"
import AddIcon from '@mui/icons-material/Add';
import {
    CreateEventHostRequest, EventHostLink,
    StorageObjectMeta, useCreateEventHostRequestMutation,
    useGetAttributeGroupsQuery
} from "../../store/api"
import { useUploadFileMutation } from "../../store/fileApi"
import { DeleteOutline } from "@mui/icons-material"
import { makeUrl } from "../../common/utils/urlUtils"
import { LoadingBackdrop } from "../../common/components/LoadingBackdrop"
import { ErrorBackdrop } from "../../common/components/ErrorBackdrop"
import { useDefaultBackground } from "../../common/hooks/backgroundHooks"
import {
    RequestField,
    RequestFieldAttributes,
    RequestFieldCoverPicture,
    RequestFieldValidation
} from "../../common/components/form/formTypes"
import { FormTextFieldWithValidation } from "../../common/components/form/FormTextFieldWithValidation"
import {
    ConfirmButton,
    SocialMediaAddButton,
    SocialMediaDeleteButton
} from "../hostStyles"
import { FormTextField } from "../../common/components/form/formStyles"
import { CoverPicture } from "../../common/components/form/CoverPicture"
import { FormAttributeSelectorsSection } from "../../common/components/form/FormAttributeSelectorsSection"

const CreateHostRequestFieldSocialMediaTypes = [
    '',
    'Instagram',
    'Facebook',
    'Personal website'
] as const;

interface CreateHostRequestFieldSocialMedia {
    type: RequestField<typeof CreateHostRequestFieldSocialMediaTypes[number]>;
    title: RequestField<string>;
    url: RequestField<string>;
}

interface CreateHostRequestState {
    name: RequestField<string>;
    description: RequestField<string>;

    street: RequestField<string>;
    houseNumber: RequestField<string>;
    postalCode: RequestField<string>;
    city: RequestField<string>;
    country: RequestField<string>;

    firstName: RequestField<string>;
    lastName: RequestField<string>;
    emailAddress: RequestField<string>;
    contactNumber: RequestField<string>;

    password: RequestField<string>;
    confirmPassword: RequestField<string>;

    coverPicture: RequestFieldCoverPicture;
    socialMedia: CreateHostRequestFieldSocialMedia[];
    placeAttributes: RequestFieldAttributes;

}

const createHostRequestInitialState: CreateHostRequestState = {
    name: {
        value: '',
        validation: { status: 'initial' }
    },
    description: {
        value: '',
        validation: { status: 'initial' }
    },

    street: {
        value: '',
        validation: { status: 'initial' }
    },
    houseNumber: {
        value: '',
        validation: { status: 'valid' }
    },
    postalCode: {
        value: '',
        validation: { status: 'initial' }
    },
    city: {
        value: '',
        validation: { status: 'initial' }
    },
    country: {
        value: '',
        validation: { status: 'initial' }
    },

    firstName: {
        value: '',
        validation: { status: 'initial' }
    },
    lastName: {
        value: '',
        validation: { status: 'initial' }
    },
    emailAddress: {
        value: '',
        validation: { status: 'initial' }
    },
    contactNumber: {
        value: '',
        validation: { status: 'initial' }
    },

    password: {
        value: '',
        validation: { status: 'initial' }
    },
    confirmPassword: {
        value: '',
        validation: { status: 'initial' }
    },

    coverPicture: { status: 'initial' },
    socialMedia: [],
    placeAttributes: {}
}

const validateEmail = (email: string): RequestFieldValidation => {
    if (email.length === 0) {
        return { status: 'invalid', message: 'Email cannot be empty' }
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!emailRegex.test(email)) {
        return { status: 'invalid', message: 'Invalid email' }
    }

    return { status: 'valid' }
}

const validatePhoneNumber = (phoneNumber: string): RequestFieldValidation => {
    if (phoneNumber.length === 0) {
        return { status: 'invalid', message: 'Phone number cannot be empty' }
    }

    const phoneNumberRegex = /^\+\d{11}$/
    if (!phoneNumberRegex.test(phoneNumber)) {
        return { status: 'invalid', message: 'Invalid phone number, it should be in format +12345678901' }
    }

    return { status: 'valid' }
}

const validatePassword = (password: string): RequestFieldValidation => {
    if (password.length === 0) {
        return { status: 'invalid', message: 'Password cannot be empty' }
    }

    return { status: 'valid' }
}

const validateConfirmPassword = (password: string, confirmPassword: string): RequestFieldValidation => {
    if (confirmPassword.length === 0) {
        return { status: 'invalid', message: 'Confirm password cannot be empty' }
    }

    if (password !== confirmPassword) {
        return { status: 'invalid', message: 'Passwords do not match' }
    }

    return { status: 'valid' }
}

const validateUrl = (url: string): RequestFieldValidation => {
    if (url.length === 0) {
        return { status: 'invalid', message: 'URL cannot be empty' }
    }

    const urlRegex = /^(http|https):\/\/[^ "]+$/
    if (!urlRegex.test(url)) {
        return { status: 'invalid', message: 'Invalid URL' }
    }

    return { status: 'valid' }
}

const areAllFieldsValid = (state: CreateHostRequestState): boolean => {
    const allOuterFieldsValid = Object
        .values(state)
        .every((field) =>
            field.validation?
                field.validation.status === 'valid'
                : true
        );

    const allCoverPictureFieldsValid = state.coverPicture.status === 'valid';

    const allSocialMediaFieldsValid = state.socialMedia
        .every((socialMedia) => {
            return Object
                .values(socialMedia)
                .every((field) => field.validation.status === 'valid');
        });

    const allPlaceAttributesValid = Object
        .values(state.placeAttributes)
        .every((placeAttribute) => placeAttribute?.validation.status === 'valid');

    return allOuterFieldsValid
        && allCoverPictureFieldsValid
        && allSocialMediaFieldsValid
        && allPlaceAttributesValid;
}

const mapStateToCreateEventHostRequest = (state: CreateHostRequestState): CreateEventHostRequest => {
    const socialMedia = state.socialMedia
        .map((socialMedia) => ({
            title: `${socialMedia.type.value} - ${socialMedia.title.value}`,
            value: socialMedia.url.value
        })) as EventHostLink[];

    const placeAttributeIds = Object
        .values(state.placeAttributes)
        .map((placeAttribute) => placeAttribute?.value)
        .filter((placeAttribute) => placeAttribute !== null)
        .map((placeAttribute) => placeAttribute?.id as string) as string[];

    const coverPicture = state.coverPicture.status === 'valid'
        ? state.coverPicture.value
        : null;

    return {
        name: state.name.value,
        description: state.description.value,
        address: {
            street: state.street.value,
            house: state.houseNumber.value,
            postalCode: state.postalCode.value,
            city: state.city.value,
            country: state.country.value
        },
        contact: {
            contactPersonFirstName: state.firstName.value,
            contactPersonLastName: state.lastName.value,
            email: state.emailAddress.value,
            phone: {
                countryCode: state.contactNumber.value.length > 0
                    ? state.contactNumber.value.substring(0, 3)
                    : '',
                number: state.contactNumber.value.length > 0
                    ? state.contactNumber.value.substring(3)
                    : ''
            },
            links: socialMedia
        },
        credentials: {
            password: state.password.value
        },
        coverPicture: coverPicture as StorageObjectMeta,
        placeAttributeIds: placeAttributeIds
    }
}

const HostRegister = () => {
    useDefaultBackground();

    const [createHostState, setCreateHostState] = React.useState<CreateHostRequestState>(createHostRequestInitialState);

    const getAttributeGroups = useGetAttributeGroupsQuery();
    const [uploadFile, uploadFileStatus] = useUploadFileMutation();
    const [createEventHostRequest, createEventHostRequestStatus] = useCreateEventHostRequestMutation();

    return (
        <div>
            <LoadingBackdrop
                open={
                    getAttributeGroups.isLoading
                    || uploadFileStatus.isLoading
                    || createEventHostRequestStatus.isLoading
                }
            />

            <ErrorBackdrop
                errors={[
                    getAttributeGroups.error,
                    createEventHostRequestStatus.error
                ]}
            />

            <Header logoStyle={"dark"} hasSearch={false} hasAvatar={false} />
            <Grid container spacing={3} marginTop={4}>
                <Grid item xs={2}></Grid>
                <Grid item xs={8}>
                    <Stack>
                        <LabeledFormSection
                            title={"Name"}
                            child={
                                <FormTextFieldWithValidation
                                    placeholder={"Enter your place name"}
                                    field={createHostState.name}
                                    onChange={(event) => {
                                        setCreateHostState({
                                            ...createHostState,
                                            name: {
                                                value: event.target.value,
                                                validation: event.target.value.length > 0
                                                    ? { status: 'valid' }
                                                    : { status: 'invalid', message: 'Name cannot be empty' }
                                            }
                                        })
                                    }}
                                />
                            }
                        />

                        <Typography variant={"subtitle1"} fontWeight={700} marginTop={2}>Place description</Typography>
                        <FormTextFieldWithValidation
                            multiline={true}
                            placeholder={"Write brief description here. It will be visible under company profile."}
                            rows={4}
                            field={createHostState.description}
                            onChange={(event) => {
                                setCreateHostState({
                                    ...createHostState,
                                    description: {
                                        value: event.target.value,
                                        validation: event.target.value.length > 0
                                            ? { status: 'valid' }
                                            : { status: 'invalid', message: 'Description cannot be empty' }
                                    }
                                })
                            }}
                        />

                        <Typography variant={"subtitle1"} fontWeight={700} marginTop={2}>Cover Picture</Typography>
                        <CoverPicture
                            uploadFile={
                                ({ id, file }) => {
                                    uploadFile({
                                        id: id,
                                        file: file
                                    })
                                        .unwrap()
                                        .then((response) => {
                                            setCreateHostState({
                                                ...createHostState,
                                                coverPicture: {
                                                    status: 'valid',
                                                    value: response.data
                                                }
                                            })
                                        })
                                        .catch((error) => {
                                            console.log(error);
                                        })
                                }
                            }
                            coverPictureState={
                                createHostState.coverPicture
                            }
                        />

                        <Typography variant={"subtitle1"} fontWeight={700} marginTop={2}>Location</Typography>

                        <Grid item container columnSpacing={2}>
                            <Grid item xs={6}>
                                <Typography variant={"subtitle2"} fontWeight={500} marginTop={1}>Street</Typography>
                                <FormTextFieldWithValidation
                                    placeholder={"Enter street name"}
                                    field={createHostState.street}
                                    onChange={(event) => {
                                        setCreateHostState({
                                            ...createHostState,
                                            street: {
                                                value: event.target.value,
                                                validation: event.target.value.length > 0
                                                    ? { status: 'valid' }
                                                    : { status: 'invalid', message: 'Street cannot be empty' }
                                            }
                                        })
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant={"subtitle2"} fontWeight={500} marginTop={1}>House number</Typography>
                                <FormTextFieldWithValidation
                                    placeholder={"Enter house number"}
                                    field={createHostState.houseNumber}
                                    onChange={(event) => {
                                        setCreateHostState({
                                            ...createHostState,
                                            houseNumber: {
                                                value: event.target.value,
                                                validation: event.target.value.length > 0
                                                    ? { status: 'valid' }
                                                    : { status: 'invalid', message: 'House number cannot be empty' }
                                            }
                                        })
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Grid item container columnSpacing={2}>
                            <Grid item xs={6}>
                                <Typography variant={"subtitle2"} fontWeight={500} marginTop={1}>Postal Code</Typography>
                                <FormTextFieldWithValidation
                                    field={createHostState.postalCode}
                                    onChange={(event) => {
                                        setCreateHostState({
                                            ...createHostState,
                                            postalCode: {
                                                value: event.target.value,
                                                validation: event.target.value.length > 0
                                                    ? { status: 'valid' }
                                                    : { status: 'invalid', message: 'Postal code cannot be empty' }
                                            }
                                        })
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant={"subtitle2"} fontWeight={500} marginTop={1}>City</Typography>
                                <FormTextFieldWithValidation
                                    field={createHostState.city}
                                    onChange={(event) => {
                                        setCreateHostState({
                                            ...createHostState,
                                            city: {
                                                value: event.target.value,
                                                validation: event.target.value.length > 0
                                                    ? { status: 'valid' }
                                                    : { status: 'invalid', message: 'City cannot be empty' }
                                            }
                                        })
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container columnSpacing={2}>
                            <Grid item xs={6}>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant={"subtitle2"} fontWeight={500} marginTop={1}>Country</Typography>
                                <FormTextFieldWithValidation
                                    field={createHostState.country}
                                    onChange={(event) => {
                                        setCreateHostState({
                                            ...createHostState,
                                            country: {
                                                value: event.target.value,
                                                validation: event.target.value.length > 0
                                                    ? { status: 'valid' }
                                                    : { status: 'invalid', message: 'Country cannot be empty' }
                                            }
                                        })
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Typography variant={"subtitle1"} fontWeight={700} marginTop={2}>Contact</Typography>

                        <Grid item container columnSpacing={2}>
                            <Grid item xs={6}>
                                <Typography variant={"subtitle2"} fontWeight={500} marginTop={1}>First Name</Typography>
                                <FormTextFieldWithValidation
                                    field={createHostState.firstName}
                                    onChange={(event) => {
                                        setCreateHostState({
                                            ...createHostState,
                                            firstName: {
                                                value: event.target.value,
                                                validation: event.target.value.length > 0
                                                    ? { status: 'valid' }
                                                    : { status: 'invalid', message: 'First name cannot be empty' }
                                            }
                                        })
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant={"subtitle2"} fontWeight={500} marginTop={1}>Last Name</Typography>
                                <FormTextFieldWithValidation
                                    field={createHostState.lastName}
                                    onChange={(event) => {
                                        setCreateHostState({
                                            ...createHostState,
                                            lastName: {
                                                value: event.target.value,
                                                validation: event.target.value.length > 0
                                                    ? { status: 'valid' }
                                                    : { status: 'invalid', message: 'Last name cannot be empty' }
                                            }
                                        })
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Typography variant={"subtitle2"} fontWeight={500} marginTop={1}>Email Address</Typography>
                        <FormTextFieldWithValidation
                            field={createHostState.emailAddress}
                            onChange={(event) => {
                                setCreateHostState({
                                    ...createHostState,
                                    emailAddress: {
                                        value: event.target.value,
                                        validation: validateEmail(event.target.value)
                                    }
                                })
                            }}
                        />

                        <Typography variant={"subtitle2"} fontWeight={500} marginTop={1}>Contact Number</Typography>
                        <FormTextFieldWithValidation
                            field={createHostState.contactNumber}
                            onChange={(event) => {
                                setCreateHostState({
                                    ...createHostState,
                                    contactNumber: {
                                        value: event.target.value,
                                        validation: validatePhoneNumber(event.target.value)
                                    }
                                })
                            }}
                        />

                        <Grid item container marginTop={2} alignItems="center" justifyContent="space-between">
                            <Typography variant={"subtitle1"} fontWeight={700}>Social Media</Typography>
                            <SocialMediaAddButton
                                size="small"
                                aria-label="add"
                                onClick={() => {
                                    setCreateHostState({
                                        ...createHostState,
                                        socialMedia: [
                                            ...createHostState.socialMedia,
                                            {
                                                type: {
                                                    value: '',
                                                    validation: { status: 'initial' }
                                                },
                                                title: {
                                                    value: '',
                                                    validation: { status: 'initial' }
                                                },
                                                url: {
                                                    value: '',
                                                    validation: { status: 'initial' }
                                                }
                                            }
                                        ]
                                    })
                                }}
                            >
                                <AddIcon />
                            </SocialMediaAddButton>
                        </Grid>
                        {
                            createHostState.socialMedia.map((socialMedia, index) => (
                                <Grid item container columnSpacing={1} marginTop={1}>
                                    <Grid item xs={2.5}>
                                        <Typography variant={"subtitle2"} fontWeight={500} marginTop={1}>Type</Typography>
                                        <FormTextField
                                            select
                                            SelectProps={{
                                                native: true,
                                            }}
                                            onChange={(event) => {
                                                const newSocialMedia = [...createHostState.socialMedia];
                                                newSocialMedia[index].type = {
                                                    value: event.target.value as typeof CreateHostRequestFieldSocialMediaTypes[number],
                                                    validation: event.target.value.length > 0
                                                        ? { status: 'valid' }
                                                        : { status: 'invalid', message: 'Type cannot be empty' }
                                                }

                                                setCreateHostState({
                                                    ...createHostState,
                                                    socialMedia: newSocialMedia
                                                })
                                            }}
                                        >
                                            {
                                                CreateHostRequestFieldSocialMediaTypes
                                                    .map((type, index) => (
                                                        <option value={type} key={index}>{type}</option>
                                                    ))
                                            }
                                        </FormTextField>
                                    </Grid>
                                    <Grid item xs={3.5}>
                                        <Typography variant={"subtitle2"} fontWeight={500} marginTop={1}>Title</Typography>
                                        <FormTextFieldWithValidation
                                            field={socialMedia.title}
                                            onChange={(event) => {
                                                const newSocialMedia = [...createHostState.socialMedia];
                                                newSocialMedia[index].title = {
                                                    value: event.target.value,
                                                    validation: event.target.value.length > 0
                                                        ? { status: 'valid' }
                                                        : { status: 'invalid', message: 'Title cannot be empty' }
                                                }

                                                setCreateHostState({
                                                    ...createHostState,
                                                    socialMedia: newSocialMedia
                                                })
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Typography variant={"subtitle2"} fontWeight={500} marginTop={1}>URL</Typography>
                                        <FormTextFieldWithValidation
                                            field={socialMedia.url}
                                            onChange={(event) => {
                                                const newSocialMedia = [...createHostState.socialMedia];
                                                newSocialMedia[index].url = {
                                                    value: event.target.value,
                                                    validation: validateUrl(event.target.value)
                                                }

                                                setCreateHostState({
                                                    ...createHostState,
                                                    socialMedia: newSocialMedia
                                                })
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Typography variant={"subtitle2"} fontWeight={500} marginTop={1}>&nbsp;</Typography>
                                        <SocialMediaDeleteButton
                                            variant={"outlined"}
                                            color={"error"}
                                            onClick={() => {
                                                const newSocialMedia = [...createHostState.socialMedia];
                                                newSocialMedia.splice(index, 1);

                                                setCreateHostState({
                                                    ...createHostState,
                                                    socialMedia: newSocialMedia
                                                })
                                            }}
                                        >
                                            <DeleteOutline />
                                        </SocialMediaDeleteButton>
                                    </Grid>
                                </Grid>
                            ))
                        }

                        <Typography variant={"subtitle1"} fontWeight={700} marginTop={2}>Credentials</Typography>

                        <Typography variant={"subtitle2"} fontWeight={500} marginTop={1}>Password</Typography>
                        <FormTextFieldWithValidation
                            type={"password"}
                            field={createHostState.password}
                            onChange={(event) => {
                                setCreateHostState({
                                    ...createHostState,
                                    password: {
                                        value: event.target.value,
                                        validation: validatePassword(event.target.value)
                                    },
                                    confirmPassword: {
                                        ...createHostState.confirmPassword,
                                        validation: validateConfirmPassword(event.target.value, createHostState.confirmPassword.value)
                                    }
                                })
                            }}
                        />

                        <Typography variant={"subtitle2"} fontWeight={500} marginTop={1}>Confirm Password</Typography>
                        <FormTextFieldWithValidation
                            type={"password"}
                            field={createHostState.confirmPassword}
                            onChange={(event) => {
                                setCreateHostState({
                                    ...createHostState,
                                    confirmPassword: {
                                        value: event.target.value,
                                        validation: validateConfirmPassword(createHostState.password.value, event.target.value)
                                    }
                                })
                            }}
                        />

                        <Typography variant={"subtitle1"} fontWeight={700} marginTop={2}>Place attributes</Typography>
                        <FormAttributeSelectorsSection
                            attributesRequestField={createHostState.placeAttributes}
                            onChange={(attributesRequestField) => {
                                setCreateHostState({
                                    ...createHostState,
                                    placeAttributes: attributesRequestField
                                })
                            }}
                        />

                        <ConfirmButton
                            variant={"contained"}
                            disabled={
                                !areAllFieldsValid(createHostState)
                                    || createEventHostRequestStatus.isLoading
                                    || createEventHostRequestStatus.isError
                                    || createEventHostRequestStatus.isSuccess
                            }
                            onClick={() => {
                                createEventHostRequest({
                                    createEventHostRequest: mapStateToCreateEventHostRequest(createHostState)
                                })
                                    .unwrap()
                                    .then((response) => {
                                        console.log(response);
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                    })
                            }}
                        >
                            {
                                !(
                                    createEventHostRequestStatus.isLoading
                                    || createEventHostRequestStatus.isError
                                    || createEventHostRequestStatus.isSuccess
                                )
                                    ? 'Confirm Host Registration'
                                    : <></>
                            }
                            {
                                createEventHostRequestStatus.isLoading
                                    ? <CircularProgress color="inherit" size={24} />
                                    : <></>
                                }
                            {
                                createEventHostRequestStatus.isError
                                    ? 'Error creating host'
                                    : <></>
                            }
                            {
                                createEventHostRequestStatus.isSuccess
                                    ? 'Host created successfully'
                                    : <></>
                            }
                        </ConfirmButton>
                    </Stack>
                </Grid>
                <Grid item xs={2}></Grid>
            </Grid>
        </div>
    )
}

export default HostRegister;
