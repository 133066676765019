import React, {useEffect, useState} from "react";
import { FormControl, Grid, InputLabel, MenuItem, Select, Skeleton, TextField } from "@mui/material";
import {useGetAttributeGroupsQuery} from "../../../store/api";
import store from "../../../store/store";
import {setEventListFilters} from "../../../store/eventListFiltersSlice";

const EventListFilters: React.FC = () => {
    const attributeGroupsFetch = useGetAttributeGroupsQuery();
    const [selectedAttributes, setSelectedAttributes] = useState<{[key: string]: string | null}>({});

    useEffect(() => {
        let attributeIds: string[] = [];
        for (const groupId in selectedAttributes) {
            if (selectedAttributes[groupId])
                attributeIds.push(selectedAttributes[groupId] as string);
        }

        store.dispatch(setEventListFilters(attributeIds));
    }, [selectedAttributes]);

    if (attributeGroupsFetch.isLoading)
        return <Skeleton variant="rectangular" width={"100%"} height={160} />

    if (attributeGroupsFetch.error || !attributeGroupsFetch.data) {
        console.error(attributeGroupsFetch.error);
        return <Skeleton variant="rectangular" width={"100%"} height={160} />
    }

    const attributeGroups = attributeGroupsFetch.data.data;

    const handleSelectChange = (groupId: string | undefined, value: string) => {
        if (!groupId) {
            return;
        }

        setSelectedAttributes(prevState => ({
            ...prevState,
            [groupId]: value === "none" ? null : value
        }));
    };

    return (
        <div>
            <Grid container spacing={3}>
                {
                    attributeGroups
                        .map(attributeGroup => (
                            attributeGroup.id &&
                            <Grid item xs={12} sm={6} md={4} key={attributeGroup.id}>
                                <FormControl fullWidth>
                                    <TextField
                                        select
                                        label={attributeGroup.name}
                                        value={selectedAttributes[attributeGroup.id] || ''}
                                        onChange={(event) => handleSelectChange(attributeGroup.id, event.target.value as string)}
                                        style={{ backgroundColor: "white" }}
                                    >
                                        <MenuItem value="none">---</MenuItem>
                                        {
                                            attributeGroup.attributes
                                                .map(attribute => (
                                                    <MenuItem
                                                        key={attribute.id}
                                                        value={attribute.id}
                                                    >
                                                        {`${attribute.tags.icon} ${attribute.name}`}
                                                    </MenuItem>
                                                ))
                                        }
                                    </TextField>
                                </FormControl>
                            </Grid>
                        ))
                }
            </Grid>
        </div>
    );
}

export default EventListFilters;
