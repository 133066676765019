import React from "react"
import { Chip, Typography } from "@mui/material"
import { EmailOutlined } from "@mui/icons-material"
import { Link } from "react-router-dom"

export const EmailChip: React.FC<{ email: string | undefined }> = ({ email }) => {
    return (
        <Chip
            key={`email-${email}`}
            size={"medium"}
            style={{marginRight: "0.5em", marginBottom: "0.5em"}}
            icon={<EmailOutlined fontSize={"small"} />}
            label={
                <Link
                    style={{textDecoration: "none", color: "inherit"}}
                    to={
                        email
                            ? `mailto:${email}`
                            : "#"
                    }
                >
                    <Typography
                        variant="body2"
                        fontWeight={600}
                        component="span"
                    >
                        {`${email ?? "No email provided"}`}
                    </Typography>
                </Link>
            }
        />
    )
}
