import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { defaultBackgroundColor } from "../common/constants"

export interface BackgroundState {
    value:
        | {
            type: 'start-end-image';
            image: string;
            height: string;
        }
        | {
            type: 'fill-image';
            image: string;
            height?: string;
        }
        | {
            type: 'fill-color';
            color: string;
            height?: string;
        }
    overlay:
        | {
            type: 'start-end-linear-gradient'
            top: string;
            height: string;
            direction: string;
            startColor: string;
            endColor: string;
        }
        | {
            type: 'fill-linear-gradient';
            direction: string;
            startColor: string;
            endColor: string;
        }
        | {
            type: 'fill-color';
            color: string;
        }
        | {
            type: 'none';
        };
    body?: {
        backgroundColor: string;
    }
}

const initialState: BackgroundState = {
    value: {
        type: 'fill-color',
        color: defaultBackgroundColor,
    },
    overlay: {
        type: 'none',
    },
    body: {
        backgroundColor: defaultBackgroundColor,
    }
};

const backgroundSlice = createSlice({
    name: 'background',
    initialState,
    reducers: {
        setBackground: (state, action: PayloadAction<BackgroundState>) => {
            state.value = action.payload.value;
            state.overlay = action.payload.overlay;

            if (action.payload.body) {
                state.body = action.payload.body;
            }
        }
    }
});

export const { setBackground } = backgroundSlice.actions;
export default backgroundSlice.reducer;
