import React from "react"
import { useDefaultBackground } from "../../hooks/backgroundHooks"
import { useAuth } from "react-oidc-context"
import { useGetUserSelfQuery } from "../../../store/api"
import LoadingPage from "../../LoadingPage"
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query/fetchBaseQuery"
import CompleteProfile from "../../../customer/profile/CompleteProfile"
import { Outlet, useNavigate } from "react-router-dom"
import { findRolesByJwt } from "./userUtils"

export const SecuredRoute: React.FC = ({ }) => {
    useDefaultBackground()

    const auth = useAuth()
    const navigate = useNavigate()

    const { isLoading: isUserGetSelfLoading, error: userGetSelfError } = useGetUserSelfQuery()

    if (isUserGetSelfLoading || auth.isLoading) {
        return (
            <LoadingPage />
        )
    }


    if (!auth.isLoading && !auth.isAuthenticated) {
        auth.signinRedirect()
            .then(() => console.log('redirecting'))
            .catch((err) => console.log(err))

        return (
            <div>
                Redirecting...
            </div>
        )
    }

    if (userGetSelfError) {
        console.log(`userGetSelfError: ${JSON.stringify(userGetSelfError)}`)
        if (!Object.keys(userGetSelfError).includes('status')) {
            return <LoadingPage />
        }

        const userGetSelfErrorTyped = userGetSelfError as FetchBaseQueryError

        if (userGetSelfErrorTyped.status === 404) {
            navigate('/profile/complete')

            return (
                <div>
                    Redirecting...
                </div>
            )
        }

        if (userGetSelfErrorTyped.status === 401 || userGetSelfErrorTyped.status === 'FETCH_ERROR') {
            auth.signinRedirect()
                .then(() => console.log('redirecting'))
                .catch((err) => console.log(err))

            return (
                <div>
                    Redirecting...
                </div>
            )
        }
    }

    return (
        <Outlet/>
    )
}
