import { Breadcrumbs, Chip, emphasize, styled } from "@mui/material"
import { LogoStyle } from "./headerTypes"

export const HeaderBreadcrumb = styled(
    Chip
)<{
    [key: string]: any
}>(({
    theme,
}) => {
    return {
        height: theme.spacing(4),
    };
});

export const HeaderBreadcrumbs = styled(Breadcrumbs, {
    shouldForwardProp: (prop) => prop !== "logoStyle"
})<{
    [key: string]: unknown,
    logoStyle: LogoStyle,
}>(({
    theme,
    logoStyle
}) => {
    const backgroundColor =
        logoStyle === "dark"
            ? "rgba(255, 255, 255, 0.4)"
            : "rgba(0, 0, 0, 0.4)";

    const borderColor =
        logoStyle === "dark"
            ? "rgba(20, 20, 20, 0.4)"
            : "rgba(200, 200, 200, 0.4)";

    const textColor =
        logoStyle === "dark"
            ? "rgb(0, 0, 0)"
            : "rgb(255, 255, 255)";

    return {
        color: textColor,
        '.MuiChip-root': {
            backgroundColor,
            borderColor,
            color: textColor,
            borderStyle: "solid",
            borderWidth: 1,
            fontWeight: 700,
            fontSize: "0.90rem",
            padding: theme.spacing(1, 0.5),
            '&:hover, &:focus': {
                backgroundColor: emphasize(backgroundColor, 0.06),
            },
            '&:active': {
                boxShadow: theme.shadows[1],
                backgroundColor: emphasize(backgroundColor, 0.12),
            },
            '.MuiSvgIcon-root': {
                color: textColor
            },
        },
        '.MuiSvgIcon-root': {
            color: textColor,
            fontSize: "1.25rem",
        },
    }
});
