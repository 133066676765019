import React, { useState } from "react"
import { useDefaultBackground } from "../common/hooks/backgroundHooks"
import { useParams } from "react-router-dom"
import { Event, useCancelEventMutation, useGetEventQuery } from "../store/api"
import { skipToken } from "@reduxjs/toolkit/query"
import { LoadingBackdrop } from "../common/components/LoadingBackdrop"
import { ErrorBackdrop } from "../common/components/ErrorBackdrop"
import Header from "../common/components/header/Header"
import { CircularProgress, Grid, Stack, Typography } from "@mui/material"
import LabeledFormSection from "../common/components/form/LabeledFormSection"
import { FormTextField } from "../common/components/form/formStyles"
import { ConfirmButton } from "./hostStyles"
import { RequestField } from "../common/components/form/formTypes"
import { FormTextFieldWithValidation } from "../common/components/form/FormTextFieldWithValidation"
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query/fetchBaseQuery"

interface HostCancelEventState {
    event: Event | undefined;

    reason: RequestField<string>;
    retypeEventName: RequestField<string>;
}

const initialState: HostCancelEventState = {
    event: undefined,

    reason: {
        value: '',
        validation: { status: 'initial' },
    },
    retypeEventName: {
        value: '',
        validation: { status: 'initial' },
    }
}

const areAllFieldsValid = (state: HostCancelEventState) => {
    return Object
        .values(state)
        .every((field) =>
            field?.validation ?
                field.validation.status === 'valid'
                : true
        );
}

const mapStateToCancelEventRequest = (state: HostCancelEventState) => {
    return {
        reason: state.reason.value,
    }
}

export const HostCancelEvent: React.FC = () => {
    useDefaultBackground()

    const [hostCancelEventState, setHostCancelEventState] = useState(initialState)

    const { eventId } = useParams()
    const getEvent = useGetEventQuery(
        eventId
            ? {
                id: eventId
            }
            : skipToken
    );

    const [cancelEvent, cancelEventStatus] = useCancelEventMutation();

    return (
        <>
            <LoadingBackdrop
                open={
                    getEvent.isLoading
                }
            />

            <ErrorBackdrop
                errors={[
                    getEvent.error
                ]}
            />

            <Header logoStyle={"dark"} hasSearch={false} hasAvatar={false} />
            <Grid container spacing={3} marginTop={4}>
                <Grid item xs={2}></Grid>
                <Grid item xs={8}>
                    <Stack>
                        <LabeledFormSection
                            title={"Cancel Event"}
                            child={
                                  <Typography>
                                       Are you sure you want to cancel event "{getEvent.data?.data.name}"?
                                  </Typography>
                            }
                        />
                        <LabeledFormSection
                             title={"Reason of Canceling"}
                             child={
                                  <FormTextFieldWithValidation
                                      multiline
                                      rows={4}
                                      placeholder={"Enter reason of canceling"}
                                      field={hostCancelEventState.reason}
                                      onChange={(event) => {
                                          setHostCancelEventState({
                                              ...hostCancelEventState,
                                              reason: {
                                                  value: event.target.value,
                                                  validation: event.target.value.length > 0
                                                      ? { status: 'valid' }
                                                      : { status: 'invalid', message: 'Reason is required' }
                                              }
                                          })
                                      }}
                                  />
                             }
                        />
                        <LabeledFormSection
                            title={"Retype name of event to confirm"}
                            child={
                                <FormTextFieldWithValidation
                                    placeholder={"Retype name of event to confirm"}
                                    field={hostCancelEventState.retypeEventName}
                                    onChange={(event) => {
                                        setHostCancelEventState({
                                            ...hostCancelEventState,
                                            retypeEventName: {
                                                value: event.target.value,
                                                validation: event.target.value === getEvent.data?.data.name
                                                    ? { status: 'valid' }
                                                    : { status: 'invalid', message: 'Event name does not match' }
                                            }
                                        })
                                    }}
                                />
                            }
                        />
                        <ConfirmButton
                            variant={"contained"}
                            disabled={
                                !areAllFieldsValid(hostCancelEventState)
                                || cancelEventStatus.isLoading
                                || cancelEventStatus.isError
                                || cancelEventStatus.isSuccess
                            }
                            onClick={() => {
                                cancelEvent({
                                    id: eventId!,
                                    cancelEventRequest: mapStateToCancelEventRequest(hostCancelEventState)
                                })
                                    .unwrap()
                                    .then((response) => {
                                        console.log(response);
                                    })
                                    .catch((error: FetchBaseQueryError) => {
                                        console.log(error);
                                    })
                            }}
                        >
                            {
                                !(
                                    cancelEventStatus.isLoading
                                    || cancelEventStatus.isError
                                    || cancelEventStatus.isSuccess
                                )
                                    ? 'Cancel Event'
                                    : <></>
                            }
                            {
                                cancelEventStatus.isLoading
                                    ? <CircularProgress color="inherit" size={24} />
                                    : null
                            }
                            {
                                cancelEventStatus.isSuccess
                                    ? 'Event canceled successfully'
                                    : null
                            }
                            {
                                cancelEventStatus.isError
                                    ? 'Error canceling event'
                                    : null
                            }
                        </ConfirmButton>
                        {
                            cancelEventStatus.isError
                                ? <LabeledFormSection
                                    title={"Error Details"}
                                    child={
                                        <Typography variant={"subtitle1"} color={"error"}>
                                            {JSON.stringify(cancelEventStatus.error)}
                                        </Typography>
                                    }
                                />
                                : null
                        }
                    </Stack>
                </Grid>
                <Grid item xs={2}></Grid>
           </Grid>
        </>
    )
}
