import React from 'react';
import { UIMatch, useMatches, useNavigate } from 'react-router-dom';
import {
    Avatar,
    Grid,
    InputAdornment,
    Menu,
    MenuItem,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import { NavigateNextOutlined, Search } from "@mui/icons-material";
import {Link} from "react-router-dom";
import {useAuth} from "react-oidc-context";
import store from "../../../store/store";
import {setHeaderSearch} from "../../../store/headerSearchSlice";
import { HeaderBreadcrumbs } from "./headerStyles"
import { HeaderBreadcrumbRoute } from "./HeaderBreadcrumbRoute"
import { HeaderBreadcrumbHandler, HeaderBreadcrumbRouteProps } from "./headerTypes"
import { findRolesByJwt } from "../user/userUtils"
import { useGetUserSelfQuery } from "../../../store/api"
import { makeUrl } from "../../utils/urlUtils"
import { useGetImageQuery } from "../../../store/fileApi"
import { skipToken } from "@reduxjs/toolkit/query"

type LogoStyle = "light" | "dark";

const Header: React.FC<{
    logoStyle: LogoStyle,
    hasSearch: boolean,
    hasAvatar: boolean,
    hasBreadcrumbs?: boolean
}> = ({
    logoStyle,
    hasSearch,
    hasAvatar,
    hasBreadcrumbs = true
}) => {
    const auth = useAuth()
    const navigate = useNavigate()

    const matches = useMatches() as UIMatch<{}, HeaderBreadcrumbHandler | undefined>[]

    const { isAuthenticated, signinRedirect, signoutRedirect } = useAuth()
    const [searchTerm, setSearchTerm] = React.useState("")

    const getUser = useGetUserSelfQuery()
    const avatar = useGetImageQuery(
        getUser.data?.data?.avatar?.id
            ? {
                id: getUser.data?.data?.avatar?.id,
                quality: 0.5,
                maxWidth: 100,
            }
            : skipToken
    )

    const user = getUser.data?.data

    React.useEffect(() => {
        store.dispatch(setHeaderSearch(searchTerm));
    }, [searchTerm])

    const onSearch = (searchTerm: string) => {
        setSearchTerm(searchTerm);
    }

    const logoColor = logoStyle === "light" ? "white" : "black";

    const headerStyle = {
        color: logoColor,
        fontFamily: "Gilroy",
        fontWeight: 800,
        fontSize: "2.5em",
        // padding: "0.2em 0"
    }

    const [anchorProfileMenuElement, setAnchorProfileMenuElement] = React.useState<null | HTMLElement>(null);
    const isProfileMenuOpen = Boolean(anchorProfileMenuElement);

    const userRoles = auth.user
        ? findRolesByJwt(auth.user)
        : []

    const handleProfileMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorProfileMenuElement(event.currentTarget);
    };
    const handleProfileMenuClose = () => {
        setAnchorProfileMenuElement(null);
    };

    return (
        <Stack paddingTop={"20px"} paddingBottom={"20px"}>
            <Grid container>
                <Grid item xs={12} md={11} container justifyContent={"space-between"}>
                    <Grid item xs={12} md={"auto"} alignSelf={"center"} marginBottom={2}>
                        <Grid container>
                            <Grid item xs={10} md={12}>
                                <Typography variant={"h3"} style={headerStyle} alignSelf={"center"}>Live Local Life</Typography>
                            </Grid>
                            <Grid item xs={2} display={{ xs: "flex", md: "none" }} justifyContent={"flex-end"}>
                                {
                                    hasAvatar &&
                                    <Avatar
                                        onClick={handleProfileMenuClick}
                                        sx={{ width: 56, height: 56 }}
                                        src={
                                            avatar.data
                                                ? avatar.data
                                                : undefined
                                        }
                                    />
                                }
                                {
                                    !hasAvatar &&
                                    <div style={{ width: 56, height: 56 }} />
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={8} marginBottom={2}>
                        {
                            hasSearch &&
                            <TextField
                                variant={"outlined"}
                                fullWidth
                                placeholder={"Search"}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search />
                                        </InputAdornment>
                                    ),
                                }}
                                value={searchTerm}
                                onChange={(event) => onSearch(event.target.value)}
                                style={{ backgroundColor: "white" }}
                            />
                        }
                    </Grid>
                </Grid>
                <Grid item md={1} container justifyContent={"flex-end"} display={{ xs: "none", md: "flex" }}>
                    <Grid item marginBottom={2}>
                        {
                            hasAvatar &&
                            <Avatar
                                onClick={handleProfileMenuClick}
                                sx={{ width: 56, height: 56 }}
                                src={
                                    avatar.data
                                        ? avatar.data
                                        : undefined
                                }
                            />
                        }
                        {
                            !hasAvatar &&
                            <div style={{ width: 56, height: 56 }} />
                        }
                    </Grid>
                    <Menu
                        id="profile-menu"
                        anchorEl={anchorProfileMenuElement}
                        open={isProfileMenuOpen}
                        onClose={handleProfileMenuClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={() => navigate('/profile')}>My Profile</MenuItem>
                        {
                            isAuthenticated
                                ? <MenuItem onClick={() => signoutRedirect()}>Logout</MenuItem>
                                : <MenuItem onClick={() => signinRedirect()}>Login</MenuItem>
                        }
                    </Menu>
                </Grid>
            </Grid>
            {
                hasBreadcrumbs &&
                <HeaderBreadcrumbs
                    logoStyle={logoStyle}
                    aria-label="breadcrumb"
                    separator={
                        <
                            NavigateNextOutlined
                            fontSize="small"
                        />
                    }
                >
                    {
                        matches
                            .reduce<HeaderBreadcrumbRouteProps[]>((acc, match): HeaderBreadcrumbRouteProps[] => {
                                switch (match.handle?.type) {
                                    case "replace":
                                        return match.handle.crumbs({
                                            ...match,
                                            data: { userRoles }
                                        });
                                    case "push":
                                        return [
                                            ...acc,
                                            ...match.handle.crumbs({
                                                ...match,
                                                data: { userRoles }
                                            })
                                        ];
                                    case "ignore":
                                        return acc;
                                }

                                return [
                                    ...acc,
                                    {
                                        path: match.pathname,
                                        label: `No handler for ${match.pathname}`,
                                    }
                                ]
                            }, [])
                            .map((crumb, index) => (
                                <HeaderBreadcrumbRoute
                                    key={`header-breadcrumb-${index}`}
                                    {...crumb}
                                />
                            ))
                    }
                </HeaderBreadcrumbs>
            }
        </Stack>
    )
};

export default Header;
