import React from "react"
import { Review, useGetEventHostQuery, useGetEventQuery, useGetUserQuery } from "../../../../store/api"
import { Card, CardContent, Rating, Skeleton, Stack, styled, Typography } from "@mui/material"
import { Link } from "react-router-dom"
import { EventOutlined, LocationOnOutlined } from "@mui/icons-material"
import { formatDate, getAddressString } from "../eventUtils"


const EventReviewCardTitleTypography = styled(Typography)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 600;
  max-width: 100%;
`;

export const EventReviewCardPlaceholder: React.FC = () => {
    return (
        <></>
    )
}

export const EventReviewCard: React.FC<{
    review: Review
}> = ({
    review
}) => {
    const getEvent = useGetEventQuery({
        id: review.eventId
    })

    const getEventHost = useGetEventHostQuery({
        id: review.eventHostId
    })

    const getUser = useGetUserQuery({
        id: review.userId
    })

    const event = getEvent.data?.data
    const eventHost = getEventHost.data?.data
    const user = getUser.data?.data

    return (
        <Card variant={"outlined"}>
            <CardContent>
                <Stack direction={"column"}>
                    {
                        event &&
                        <EventReviewCardTitleTypography variant="h5">{event.name}</EventReviewCardTitleTypography>
                    }
                    {
                        !event &&
                        <Skeleton variant="text" width={"100%"} height={30} />
                    }
                </Stack>
                <Stack direction={"column"}>
                    {
                        eventHost &&
                        <Typography variant={"subtitle1"} color={"#"}>
                            by <Link to={`/host/${eventHost?.id}`} style={{ color: '#000', textDecoration: "none" }}>{eventHost?.name}</Link>
                        </Typography>
                    }
                    {
                        !eventHost &&
                        <Skeleton variant="text" height={28} width={"100%"} />
                    }
                </Stack>
                {
                    event &&
                    <Stack direction={"row"} spacing={1} marginTop={2}>
                        <EventOutlined fontSize="small" />
                        <Typography variant="body2" component="span">{formatDate(event.startDate)}</Typography>
                    </Stack>
                }
                {
                    !event &&
                    <Skeleton variant="text" height={20} width={"100%"} />
                }
                {
                    event &&
                    <Stack direction={"row"} spacing={1} marginTop={1}>
                        <LocationOnOutlined fontSize="small" />
                        <Typography variant="body2" component="span">{getAddressString(event.address)}</Typography>
                    </Stack>
                }
                {
                    !event &&
                    <Skeleton variant="text" height={20} width={"100%"} />
                }
                <Stack direction={"column"} marginTop={2}>
                    <Typography variant="body2" component="span">{review.comment}</Typography>
                </Stack>
                {
                    review.createdDate &&
                    <Stack direction={"column"} marginTop={2}>
                        <Typography variant="body2" component="span">{formatDate(review.createdDate)}</Typography>
                    </Stack>
                }
                <Stack direction={"column"} marginTop={1}>
                    <Rating value={review.rating} readOnly size={"medium"} />
                </Stack>
            </CardContent>
        </Card>
    )
}
