import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import { apiBaseUrl } from "../common/constants"

export const emptySplitApi = createApi({
    tagTypes: ['USER', 'SELF', 'EVENT'],
    baseQuery: fetchBaseQuery({
        baseUrl: apiBaseUrl,
        prepareHeaders: (headers) => {
            const token = localStorage.getItem('token');
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }

            return headers;
        }
    }),
    endpoints: () => ({}),
});
