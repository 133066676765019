import { Backdrop, CircularProgress, styled } from "@mui/material"
import React from "react"


const StyledLoadingBackdrop = styled(Backdrop)`
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
`;


export const LoadingBackdrop: React.FC<{ open: boolean }> = ({ open }) => {
    return (
        <StyledLoadingBackdrop open={open} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <CircularProgress color="primary" />
        </StyledLoadingBackdrop>
    );
}
