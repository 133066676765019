import 'react-phone-number-input/style.css'
import Header from "../../common/components/header/Header";
import { Avatar, Button, Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
    useGetUserSelfQuery,
    useMutateUserSelfMutation, UserPhone
} from "../../store/api";
import {Link, useNavigate} from "react-router-dom";
import { LoadingBackdrop } from "../../common/components/LoadingBackdrop"
import { ErrorBackdrop } from "../../common/components/ErrorBackdrop"
import PhoneInput from 'react-phone-number-input'
import LabeledFormSection from "../../common/components/form/LabeledFormSection"
import { FormPhoneTextFieldRef } from "../../common/components/form/FormPhoneTextField"
import { useGetImageQuery, useSaveImageMutation } from "../../store/fileApi"
import { accountManagementUrl } from "../../common/constants"
import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input'
import { ConfirmButton } from "../../host/hostStyles"
import { PersonOutlined } from "@mui/icons-material"
import { skipToken } from "@reduxjs/toolkit/query"

const EditProfile = () => {
    const navigate = useNavigate();

    const getUser = useGetUserSelfQuery()

    const avatar = useGetImageQuery(
        getUser.data?.data?.avatar?.id
            ? {
                id: getUser.data?.data?.avatar?.id,
                quality: 0.8,
                maxWidth: 200,
                maxHeight: 200
            }
            : skipToken
    )

    const user = getUser.data?.data

    const [mutateUserSelf, mutateUserSelfState] = useMutateUserSelfMutation()
    const [uploadSaveImage, uploadSaveImageState] = useSaveImageMutation()

    const [phone, setPhone] = useState<string | undefined>(undefined)

    useEffect(() => {
        if (user?.phone) {
            setPhone(`+${user.phone.countryCode}${user.phone.number}`)
        }
    }, [user])

    const isPhoneValid = (phone: string | undefined) => {
        return phone && isValidPhoneNumber(phone)
    }

    const parsePhone = (phone: string): UserPhone => {
        const parsedPhone = parsePhoneNumber(phone)
        return {
            countryCode: parsedPhone?.countryCallingCode ?? "",
            number: parsedPhone?.nationalNumber ?? "",
            verified: false
        }
    }

    return (
        <>
            <LoadingBackdrop
                open={
                    getUser.isLoading
                    || mutateUserSelfState.isLoading
                    || uploadSaveImageState.isLoading
                }
            />

            <ErrorBackdrop
                errors={[
                    getUser.error
                    || mutateUserSelfState.error
                    || uploadSaveImageState.error
                ]}
            />

            <Header logoStyle={"dark"} hasSearch={false} hasAvatar={false} />
            {
                user &&
                <>
                    <Grid container>
                        <Grid item xs={12} md={4} />
                        <Grid item container spacing={6} xs={12} md={4}>
                            <Grid item>
                                <IconButton
                                    style={{ padding: 0 }}
                                    onClick={() => {
                                        const fileInput = document.createElement('input');
                                        fileInput.type = 'file';
                                        fileInput.accept = 'image/*';
                                        fileInput.onchange = (event) => {
                                            const file = (event.target as HTMLInputElement).files?.[0];
                                            const nowMillis = Number(new Date());

                                            if (!file) {
                                                return;
                                            }

                                            uploadSaveImage({
                                                id: `avatar-${nowMillis}`,
                                                file: file
                                            })
                                                .unwrap()
                                                .then((response) => mutateUserSelf({
                                                    mutateUserSelfRequest: {
                                                        avatar: response.data
                                                    }
                                                }))
                                        }

                                        fileInput.click();
                                    }}
                                >
                                    <Avatar
                                        sx={{ width: 96, height: 96 }}
                                        src={
                                            avatar.data
                                                ? avatar.data
                                                : undefined
                                        }
                                    />
                                </IconButton>

                            </Grid>
                            <Grid item alignSelf={"center"}>
                                <Typography
                                    variant="h4"
                                    fontWeight={700}
                                    marginBottom={1}
                                >
                                    {user.firstName} {user.lastName}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={4} />
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} md={2} />
                        <Grid item xs={12} md={8}>
                            <LabeledFormSection
                                title={"Phone number"}
                                child={
                                    <PhoneInput
                                        style={{ marginTop: "1em" }}
                                        placeholder="Enter phone number"
                                        inputComponent={FormPhoneTextFieldRef}
                                        value={phone}
                                        onChange={setPhone}
                                    />
                                }
                            />
                            <LabeledFormSection
                                title={"Account management"}
                                child={
                                    <Link to={accountManagementUrl}>
                                        <Button
                                            style={{
                                                marginTop: "1em",
                                                backgroundColor: "white",
                                                borderRadius: "4px",
                                                padding: "1em"
                                            }}
                                            fullWidth
                                            variant={"outlined"}
                                            color="primary"
                                            startIcon={<PersonOutlined />}
                                        >
                                            Go to account management console
                                        </Button>
                                    </Link>
                                }
                            />
                            <ConfirmButton
                                disabled={!isPhoneValid(phone)}
                                onClick={() => {
                                    mutateUserSelf({
                                        mutateUserSelfRequest: {
                                            phone: parsePhone(phone ?? "")
                                        }
                                    })
                                }}
                            >
                                Update profile
                            </ConfirmButton>
                        </Grid>
                        <Grid item xs={12} md={2} />
                    </Grid>
                </>
            }
        </>
    );
}

export default EditProfile;
