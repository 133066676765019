import React from 'react';
import EventSection from './EventSection';
import Header from "../../../common/components/header/Header";
import { useGetAttributeGroupByIdQuery } from "../../../store/api";
import EventListFilters from "./EventListFilters";
import { useDefaultBackground } from "../../../common/hooks/backgroundHooks"
import { LoadingBackdrop } from "../../../common/components/LoadingBackdrop"
import { ErrorBackdrop } from "../../../common/components/ErrorBackdrop"
import { useSelector } from "react-redux"
import { RootState } from "../../../store/store"
import { categoriesAttributeGroupId } from "../../../common/constants"

const EventsList: React.FC = () => {
    useDefaultBackground()
    const categoriesAttributesFetch = useGetAttributeGroupByIdQuery({ id: categoriesAttributeGroupId });

    const categoriesAttributes = categoriesAttributesFetch.data?.data.attributes;

    const selectedAttributeIds = useSelector((state: RootState) => state.eventListFilters.attributeIds);

    const selectedCategoryAttributeId = selectedAttributeIds.find(id => id.startsWith("category-"));
    const selectedCategoryAttribute = categoriesAttributes?.find(category => category.id === selectedCategoryAttributeId);

    return (
        <>
            <LoadingBackdrop open={categoriesAttributesFetch.isLoading}/>
            <ErrorBackdrop errors={[categoriesAttributesFetch.error]}/>

            <Header logoStyle={"dark"} hasSearch={true} hasAvatar={true}/>
            <EventListFilters/>
            {
                selectedCategoryAttribute &&
                <EventSection
                    isExpanded={true}
                    key={selectedCategoryAttribute.id}
                    title={selectedCategoryAttribute.name}
                    tags={selectedCategoryAttribute.tags}
                    categoryId={selectedCategoryAttribute.id!}
                />
            }
            {
                !selectedCategoryAttribute &&
                categoriesAttributes
                    ?.map(category => (
                        <EventSection
                            isExpanded={false}
                            key={category.id}
                            title={category.name}
                            tags={category.tags}
                            categoryId={category.id!}
                        />
                    ))
            }
        </>
    );
}

export default EventsList;
