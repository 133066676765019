import React from "react"
import { EventWithEventTicket, useListUpcomingEventsByUserQuery, User } from "../../../store/api"
import { Card, Grid, Pagination, Stack, Typography } from "@mui/material"
import { EventWithTicket, EventWithTicketPlaceholder } from "./EventWithTicket"

export const EventWithTicketList: React.FC<{
    user: User,
    title: string,
    eventsWithTicket: EventWithEventTicket[],
    isLoading: boolean
}> = ({
    user,
    title,
    eventsWithTicket,
    isLoading
}) => {

    return (
        <Card variant="outlined">
            <Stack padding={4}>
                <Grid item container>
                    <Typography variant="h5" fontWeight={700}>{title}</Typography>
                </Grid>
                <Grid item marginTop={1} container spacing={2}>
                    {
                        isLoading
                        && [1, 2, 3]
                            .map(i => (
                                <Grid item xs={12} lg={6} key={`event-${i}`} marginBottom={1}>
                                    <EventWithTicketPlaceholder/>
                                </Grid>
                            ))
                    }
                    {
                        !isLoading
                        && eventsWithTicket
                            .map((eventWithTicket, i) => (
                                <Grid item xs={12} lg={6} key={`event-with-ticket-${eventWithTicket.event.id}`} marginBottom={1}>
                                    <EventWithTicket eventWithTicket={eventWithTicket} user={user}/>
                                </Grid>
                            ))
                    }
                </Grid>
                {
                    !isLoading
                    && eventsWithTicket.length === 0
                    && <Typography variant="body1">No events to show</Typography>
                }
            </Stack>
        </Card>
    )
}
