import React from "react"
import { EventHost, useGetUserQuery } from "../../../store/api"
import { Card, Grid, Stack, Typography } from "@mui/material"
import { PhoneChip } from "../contact/PhoneChip"
import { EmailChip } from "../contact/EmailChip"
import { PersonChip } from "../contact/PersonChip"
import { LinkChip } from "../contact/LinkChip"

export const HostContact: React.FC<{ eventHost: EventHost }> = ({ eventHost }) => {
    const getUser = useGetUserQuery({
        id: eventHost.userId!
    })

    return (
        <Card variant="outlined">
            <Stack padding={4}>
                <Typography variant="h5" fontWeight={700}>Contact</Typography>
                <Grid item container marginTop={1}>
                    {
                        getUser.data &&
                        <PhoneChip phone={getUser.data.data.phone} />
                    }
                    {
                        getUser.data &&
                        <EmailChip email={getUser.data.data.email} />
                    }
                    {
                        getUser.data &&
                        <PersonChip
                            firstName={getUser.data.data.firstName}
                            lastName={getUser.data.data.lastName}
                        />
                    }
                    {
                        eventHost.contact.links
                            ?.map((link, i) => (
                                <LinkChip
                                    key={i}
                                    title={link.title}
                                    value={link.value}
                                />
                            ))
                    }
                </Grid>
            </Stack>
        </Card>
    )
}
