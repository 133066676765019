import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface HeaderSearchState {
    value: string;
}

const initialState: HeaderSearchState = {
    value: ''
};

const headerSearchSlice = createSlice({
    name: 'headerSearch',
    initialState,
    reducers: {
        setHeaderSearch: (state, action: PayloadAction<string>) => {
            state.value = action.payload;
        }
    }
});

export const { setHeaderSearch } = headerSearchSlice.actions;
export default headerSearchSlice.reducer;
