import {
    LiveLocalLifeJwtUser,
    LiveLocalLifeJwtUserRole
} from "./userTypes"
import { User } from "oidc-client-ts"
import { jwtDecode } from "jwt-decode"

export const findRoles = (user: LiveLocalLifeJwtUser): LiveLocalLifeJwtUserRole[]  => {
    return user
        .resource_access["livelocallife-backend"]
        .roles
}

export const findRolesByJwt = (user: User): LiveLocalLifeJwtUserRole[] => {
    const liveLocalLifeUser = jwtDecode<LiveLocalLifeJwtUser>(user.access_token)
    return findRoles(liveLocalLifeUser)
}
