import React from "react";
import MainPage from "./main/MainPage";
import {Provider} from "react-redux";
import {AuthProvider} from "react-oidc-context";
import oidcConfig from "./oidcConfig";
import {User} from "oidc-client-ts";
import store from "./store/store";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { APIProvider } from "@vis.gl/react-google-maps"
import { googleMapsApiKey } from "./common/constants"
import 'dayjs/locale/pl';

const onSigninCallback = (_user: User | void): void => {
    window.history.replaceState({}, document.title, window.location.pathname);
};


const App: React.FC = () => {
    return (
        <AuthProvider {...oidcConfig} onSigninCallback={onSigninCallback}>
            <Provider store={store}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pl">
                    <APIProvider apiKey={googleMapsApiKey || ''}>
                        <MainPage/>
                    </APIProvider>
                </LocalizationProvider>
            </Provider>
        </AuthProvider>
    );
};

export default App;
