import 'react-phone-number-input/style.css'
import Header from "../../common/components/header/Header";
import { Avatar, Grid, Snackbar, Stack, Typography } from "@mui/material";
import React, {useState} from "react";
import {useMutateUserSelfMutation} from "../../store/api";
import { useNavigate } from "react-router-dom";
import LabeledFormSection from "../../common/components/form/LabeledFormSection"
import { FormTextField } from "../../common/components/form/formStyles"
import { ConfirmButton } from "../../host/hostStyles"
import { FormPhoneTextFieldRef } from "../../common/components/form/FormPhoneTextField"
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input"
import { FormFieldError } from "../../common/components/form/FormFieldError"
import { useAuth } from "react-oidc-context"

const CompleteProfile: React.FC = () => {
    const auth = useAuth()

    const name = auth.user?.profile.name ?? "";
    const email = auth.user?.profile.email ?? "";

    const [mobileNumber, setMobileNumber] = useState<string>("");
    const [mobileError, setMobileError] = useState<string>("");

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");

    const [formDisabled, setFormDisabled] = useState(false);

    const [updateUserSelf, result] = useMutateUserSelfMutation();

    const navigate = useNavigate();

    const handleMobileChange = (phone: string) => {
        setMobileNumber(phone);
        if (mobileError) setMobileError("");
    };

    const validateForm = () => {
        let isValid = true;
        if (!mobileNumber || !isValidPhoneNumber(mobileNumber)) {
            setMobileError("Please enter a valid mobile number.");
            isValid = false;
        }
        return isValid;
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        console.log('Form submitted:', { mobileNumber });

        if (validateForm()) {
            setFormDisabled(true);
            const parsedMobileNumber = parsePhoneNumber(mobileNumber);
            updateUserSelf({
                mutateUserSelfRequest: {
                    phone: {
                        countryCode: parsedMobileNumber?.countryCallingCode ?? "",
                        number: parsedMobileNumber?.nationalNumber ?? ""
                    }
                }
            }).unwrap()
                .then(response => {
                    setSnackbarMessage("Profile updated successfully!");
                    console.log(response)
                    setSnackbarOpen(true);
                    setFormDisabled(true);
                    setTimeout(
                        () => navigate("/events"),
                        1000
                    );
                })
                .catch(error => {
                    setSnackbarMessage("An error occurred while updating the profile.");
                    console.log(error)
                    setSnackbarOpen(true);
                    setFormDisabled(false);
                })
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            <Header logoStyle={"dark"} hasSearch={false} hasAvatar={false} />
            <form onSubmit={handleSubmit}>
                <Stack
                    direction="column"
                    alignItems="center"
                    sx={{ flexGrow: 1 }}
                    spacing={4}
                >
                    <Grid container>
                        <Grid item xs={12} md={4} />
                        <Grid item container spacing={6} xs={12} md={4}>
                            <Grid item>
                                <Avatar
                                    sx={{ width: 96, height: 96 }}
                                />
                            </Grid>
                            <Grid item alignSelf={"center"}>
                                <Typography
                                    variant="h4"
                                    fontWeight={700}
                                    marginBottom={1}
                                >
                                    {name}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} md={2} />
                        <Grid item xs={12} md={8}>
                            <LabeledFormSection
                                title={"Email address"}
                                child={
                                    <FormTextField value={email} disabled={true} />
                                }
                            />

                            <LabeledFormSection
                                title={"Phone number"}
                                child={
                                    <>
                                        <PhoneInput
                                            placeholder="Enter phone number"
                                            inputComponent={FormPhoneTextFieldRef}
                                            value={mobileNumber}
                                            onChange={handleMobileChange}
                                            disabled={formDisabled}
                                        />
                                        {
                                            mobileError
                                                ? FormFieldError({ message: mobileError })
                                                : FormFieldError({})
                                        }
                                    </>

                                }
                            />

                            <ConfirmButton
                                variant={"contained"}
                                type={"submit"}
                                disabled={formDisabled}
                            >
                                Complete Your Profile
                            </ConfirmButton>
                        </Grid>
                        <Grid item xs={12} md={2} />
                    </Grid>
                </Stack>
            </form>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                message={snackbarMessage}
            />
        </div>
    );
}

export default CompleteProfile;
