import React from "react"
import {Attribute} from "../../../store/api"
import {Card, Stack, Typography} from "@mui/material"
import {AttributesGrid} from "./AttributesGrid";

export const AttributesCard: React.FC<{ attributes: Attribute[] }> = ({ attributes }) => {
    return (
        <Card variant="outlined">
            <Stack padding={4}>
                <Typography variant="h5" fontWeight={700}>Important to know</Typography>
                <AttributesGrid attributes={attributes} />
            </Stack>
        </Card>
    )
}
