import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {
    Event as ApiEvent,
    useGetEventQuery,
    useGetUserSelfQuery,
    useInitiateEventTicketPurchaseMutation
} from "../../../store/api";
import EventCard from "../../../common/components/event/EventCard";
import {useDefaultBackground} from "../../../common/hooks/backgroundHooks";
import {Container, Stack} from "@mui/material";
import Header from "../../../common/components/header/Header";
import CheckoutTicketsSection, {TicketsCheckoutState} from "./CheckoutTicketsSection";
import CheckoutBillingAddressSection, {BillingAddressCheckoutState} from "./CheckoutBillingAddressSection";
import CheckoutPaymentSection, {PaymentCheckoutState} from "./CheckoutPaymentSection";
import CheckoutPoliciesSection, {PoliciesCheckoutState} from "./CheckoutPoliciesSection";
import {EventCheckoutSectionError, EventCheckoutSectionTitle, PurchaseButton} from "../eventStyles";
import store from "../../../store/store";
import {setSnackbar} from "../../../store/snackbarSlice";

const EventCheckoutRenderer: React.FC<{event: ApiEvent}> = ({event}) => {
    useDefaultBackground()
    const navigate = useNavigate()

    const [initiateEventTicketPurchase, initiateEventTicketPurchaseStatus] = useInitiateEventTicketPurchaseMutation()
    const userSelfFetch = useGetUserSelfQuery()

    const [validating, setValidating] = useState(false);
    const [formDisabled, setFormDisabled] = useState(false);

    const [ticketsCheckoutState, setTicketsCheckoutState] = useState<TicketsCheckoutState>({
        tickets: [],
        error: 'choose a ticket!'
    })

    const [billingAddressCheckoutState, setBillingAddressCheckoutState] = useState<BillingAddressCheckoutState>({
        billingAddress: {
            country: '',
            city: '',
            apartment: '',
            street: '',
            house: '',
            postalCode: '',
        },
        error: 'fill in the billing address!'
    });

    const [paymentCheckoutState, setPaymentCheckoutState] = useState<PaymentCheckoutState>({
        paymentMethod: '',
        error: 'choose a payment method!'
    });

    const [policiesCheckoutState, setPoliciesCheckoutState] = useState<PoliciesCheckoutState>({
        termsAndConditions: false,
        privacyPolicy: false,
        newsletter: false,
        error: 'all required policies must be accepted!'
    });

    useEffect(() => {
        if (ticketsCheckoutState.error || billingAddressCheckoutState.error || paymentCheckoutState.error || policiesCheckoutState.error)
            return
        setFormDisabled(false);
    }, [ticketsCheckoutState, billingAddressCheckoutState, paymentCheckoutState, policiesCheckoutState]);



    const submitCheckout = () => {
        setFormDisabled(true);
        setValidating(true);
        const userId = userSelfFetch.data ? userSelfFetch.data.data.id : undefined
        if (
            ticketsCheckoutState.error || billingAddressCheckoutState.error || paymentCheckoutState.error ||
            policiesCheckoutState.error || !event.id || !userId
        ) {
            const message = "Form validation failed."
            store.dispatch(setSnackbar({isOpen: true, message: message, severity: "error"}));
            setFormDisabled(false);
            return
        }

        initiateEventTicketPurchase({
            initiateEventTicketPurchaseRequest: {
                eventId: event.id,
                tickets: ticketsCheckoutState.tickets,
                billingAddress: billingAddressCheckoutState.billingAddress,
            }
        })
            .unwrap()
            .then(response => {
                const message = "Tickets purchased successfully!"
                store.dispatch(setSnackbar({isOpen: true, message: message, severity: "success"}));
                setTimeout(() => {
                    navigate(0);
                }, 3000);
            })
            .catch(error => {
                let message = error.toString()
                if (error.data && error.data.message) {
                    message = error.data.message
                }
                store.dispatch(setSnackbar({isOpen: true, message: message, severity: "error"}));
                setFormDisabled(false);
            })
    }

    return (
        <>
            <Header logoStyle={"dark"} hasAvatar={true} hasSearch={false} />
            <Container maxWidth={"sm"}>
                <Stack>
                    <EventCard event={event} mode={"hostView"} />

                    <EventCheckoutSectionTitle>
                        1. Tickets, total available: {event.totalTicketCount}
                    </EventCheckoutSectionTitle>
                    {validating && ticketsCheckoutState.error &&
                        <EventCheckoutSectionError>
                            {ticketsCheckoutState.error}
                        </EventCheckoutSectionError>
                    }
                    <CheckoutTicketsSection
                        tickets={event.tickets}
                        totalTickets={event.totalTicketCount}
                        setTicketCheckoutState={setTicketsCheckoutState}
                        disabled={formDisabled}
                    />

                    <EventCheckoutSectionTitle>
                        2. Billing Address
                    </EventCheckoutSectionTitle>
                    {validating && billingAddressCheckoutState.error &&
                        <EventCheckoutSectionError>
                            {billingAddressCheckoutState.error}
                        </EventCheckoutSectionError>
                    }
                    <CheckoutBillingAddressSection
                        setBillingAddressCheckoutState={setBillingAddressCheckoutState}
                        disabled={formDisabled}
                    />

                    <EventCheckoutSectionTitle>
                        3. Payment
                    </EventCheckoutSectionTitle>
                    {validating && paymentCheckoutState.error &&
                        <EventCheckoutSectionError>
                            {paymentCheckoutState.error}
                        </EventCheckoutSectionError>
                    }
                    <CheckoutPaymentSection
                        setPaymentCheckoutState={setPaymentCheckoutState}
                        disabled={formDisabled}
                    />

                    <EventCheckoutSectionTitle />
                    {validating && policiesCheckoutState.error &&
                        <EventCheckoutSectionError>
                            {policiesCheckoutState.error}
                        </EventCheckoutSectionError>
                    }
                    <CheckoutPoliciesSection
                        setPoliciesCheckoutState={setPoliciesCheckoutState}
                        disabled={formDisabled}
                    />

                    <PurchaseButton
                        onClick={() => submitCheckout()}
                        disabled={formDisabled}
                    >
                        {ticketsCheckoutState.price ? `Proceed to payment (${ticketsCheckoutState.price})` : 'Proceed'}
                    </PurchaseButton>
                </Stack>
            </Container>
        </>
    )
}

const EventCheckout: React.FC = () => {
    const { eventId } = useParams()
    if (!eventId)
        return <div>no id provided</div>
    return <EventFetcher eventId={eventId} />
}

const EventFetcher: React.FC<{eventId: string}> = ({ eventId }) => {
    const eventFetch = useGetEventQuery({id: eventId});
    const event = eventFetch.data?.data
    return (
        <div>
            {event && <EventCheckoutRenderer event={event} />}
        </div>
    )
}

export default EventCheckout
