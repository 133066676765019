import { Button, styled, Typography } from "@mui/material"

export const MoreInfoButton = styled(Button)`
    border-radius: 8px;
    border: 2px solid rgba(160, 183, 205, 0.80);
    color: #778797;
    text-transform: none;
    font-weight: 600;
`;

export const BuyNowButton = styled(Button)`
    border-radius: 8px;
    border: 2px solid rgba(160, 183, 205, 0.80);
    background: linear-gradient(92deg, rgba(201, 214, 255, 0.75) 0%, rgba(226, 226, 226, 0.75) 100.4%);
    color: #778797;
    text-transform: none;
    font-weight: 600;
    box-shadow: none;
`;

export const CardTitleTypography = styled(Typography)`
    margin-top: -40px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 600;
    max-width: 100%;
`;
